import React from "react";
import { Text, Font, View, StyleSheet } from "@react-pdf/renderer";
import toRibuan from "../../utils/toRibuan";

Font.register({
    family: "Open-Sans",
    src: "https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf",
});

interface IPrintStrukA5CustomAmountProps {
    hargaCustomAmount?: string | null;
}

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
        fontFamily: "Open-Sans",
        borderLeftColor: "#fff",
        borderLeftWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
        fontSize: 8,
    },
    description: {
        width: "70%",
        textAlign: "right",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 18,
    },
    subtotalText: {
        width: "15%",
        textAlign: "right",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 18,
    },
    subtotal: {
        width: "15%",
        textAlign: "right",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        paddingHorizontal: 8,
        height: 18,
    },
});

const PrintStrukA5CustomAmount = ({
    hargaCustomAmount,
}: IPrintStrukA5CustomAmountProps) => {
    return (
        <View style={styles.row}>
            <Text style={styles.description}> </Text>
            <Text style={styles.subtotalText}>Custom Amount</Text>
            <Text style={styles.subtotal}>
                {toRibuan(Number(hargaCustomAmount))}
            </Text>
        </View>
    );
};

export default PrintStrukA5CustomAmount;
