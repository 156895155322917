import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTransaksi } from "../../context/transaksiContext";
// import InfoTagihan from "../InfoTagihan/InfoTagihan";
// import Kalkulator from "../Kalkulator/Kalkulator";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ButtonNumKalkulator from "../ButtonNumKalkulator/ButtonNumKalkulator";
import ButtonKalkulator from "../ButtonKalkulator/ButtonKalkulator";
import "../Kalkulator/Kalkulator.css";
import NumberFormat from "react-number-format";
import toRibuan from "../../utils/toRibuan";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { IOptions, ParamsPostUsersByUkm } from "../../constants/types";
import useUsersByUkm from "../../services/queries/useUsersByUkm";
import { useAuth } from "../../context/authContext";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import useUser from "../../services/queries/useUser";
import useUkm from "../../services/queries/useUkm";
import ListSelectedBarang from "./ListSelectedBarang";
import Swal from "sweetalert2";
import usePinDiskon from "../../services/queries/usePinDiskon";
import formatHarga from "../../utils/formatHarga";

interface IModalBayarProps {
    handlingRincianPembayaran: (
        persentaseDiskon: number | null,
        diskon: number | null,
        tagihan: number,
        uangYangDibayar: number,
        kembalian: number,
        sales: IOptions | null,
        subTotal: number,
        isNonTunai: boolean,
        dpp: number | null,
        ppn: number | null,
    ) => void;
}

type ToggleDiskon = "nominal" | "persentase";

const ModalBayar = (props: IModalBayarProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { ukmIdUser } = useAuth();
    const initialParamsItem = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 100,
        page: 1,
        search: "",
    };
    const [paramsUsersByUkm, setParamsUsersByUkm] =
        useState<ParamsPostUsersByUkm>(initialParamsItem);
    const {
        data: usersByUkm,
        refetch: refetchUsersByUkm,
        isLoading: isLoadingUsersByUkm,
    } = useUsersByUkm(paramsUsersByUkm);
    const { data: pinDiskon } = usePinDiskon();
    const [sales, setSales] = useState<IOptions | null>(null);
    const [pinValue, setPinValue] = useState<any | null>("");
    // const [checked, setChecked] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleChangeCustomAmount = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setCheckedCustomAmount(event.target.checked);
        resetFieldCustomAmount();
    };
    const handleChangeOngkir = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedOngkir(event.target.checked);
        resetFieldOngkir();
    };

    const handleChangePin = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPinValue(event.target.value);
    };

    const mappedUsersByUkm = usersByUkm?.content.map((li) => ({
        id: li.userId,
        nama: li.namaLengkap,
    }));

    const defaultMaskOptions = {
        prefix: "",
        suffix: "",
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ".",
        allowDecimal: true,
        decimalSymbol: ",",
        decimalLimit: 2, // how many digits allowed after the decimal
        integerLimit: 7, // limit length of integer numbers
        allowNegative: false,
        allowLeadingZeroes: false,
    };

    const {
        cart,
        isOpenModalBayar,
        closeModalBayar,
        totalHarga,
        // totalDpp,
        openModalKonfirmasiBayar,
        isDonePembayaran,
        handlingResetPembayaran,
        resetCart,
        checked,
        setChecked,
        checkedCustomAmount,
        setCheckedCustomAmount,
        checkedOngkir,
        setCheckedOngkir,
        keteranganCustomAmount,
        hargaCustomAmount,
        keteranganOngkir,
        hargaOngkir,
        handleChangeKeteranganCustomAmount,
        handleChangeHargaCustomAmount,
        handleChangeKeteranganOngkir,
        handleChangeHargaOngkir,
        resetCustomAmountOngkir,
        resetFieldCustomAmount,
        resetFieldOngkir,
    } = useTransaksi();

    const [inputBayar, setInputBayar] = useState<any>("");
    const [inputDiskon, setInputDiskon] = useState<any | null>("");
    const [toggleDiskon, setToggleDiskon] = useState<ToggleDiskon>("nominal");
    const [displayDiskon, setDisplayDiskon] = useState<number>(0);
    const [totalTagihan, setTotalTagihan] = useState<number>(totalHarga);
    const [kembalian, setKembalian] = useState<number>(0);
    const [isDiskonFocused, setIsDiskonFocused] = useState(false);
    const [isPinFocused, setIsPinFocused] = useState(false);

    const focusDiskon = () => setIsDiskonFocused(true);
    const unfocusDiskon = () => setIsDiskonFocused(false);
    const focusPin = () => setIsPinFocused(true);
    const unfocusPin = () => setIsPinFocused(false);

    // console.log(inputDiskon, displayDiskon);

    const { data: user } = useUser();
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const [pajak, setPajak] = useState(0);
    const [ppn, setPpn] = useState(0);
    const [dpp, setDpp] = useState(0);

    useEffect(() => {
        if (ukmData && ukmData.pajak) {
            setPajak(ukmData.pajak / 100);
        }
    }, [ukmData]);

    useEffect(() => {
        if (pajak > 0) {
            setPpn(Number(Number(dpp * pajak).toFixed(2)));
        }
    }, [dpp, pajak]);

    useEffect(() => console.log("dpp", dpp), [dpp]);
    useEffect(() => console.log("ppn", ppn), [ppn]);

    const onQuickActionClick = (digit: number) => {
        let newDisplay = Number(inputBayar) + digit;
        setInputBayar(newDisplay);
    };

    const onClearButtonClick = () => {
        if (isDiskonFocused) {
            setInputDiskon("");
        } else if (isPinFocused) {
            setPinValue("");
        } else {
            setInputBayar("");
        }
    };

    const pilihNominal = () => {
        setToggleDiskon("nominal");
        setInputDiskon("");
    };

    const pilihPersentase = () => {
        setToggleDiskon("persentase");
        setInputDiskon("");
    };

    const onDelButtonClick = () => {
        if (isDiskonFocused) {
            let value = inputDiskon ?? 0;
            if (value === 0) {
                setInputDiskon("");
            } else {
                let newDisplay = Math.floor(value / 10);
                setInputDiskon(newDisplay);
            }
        } else if (isPinFocused) {
            let value = pinValue ?? 0;
            if (value === 0) {
                setPinValue("");
            } else {
                let newDisplay = Math.floor(value / 10);
                setPinValue(newDisplay);
            }
        } else {
            let value = inputBayar ?? 0;
            if (value === 0) {
                setInputBayar("");
            } else {
                let newDisplay = Math.floor(value / 10);
                setInputBayar(newDisplay);
            }
        }
    };

    const onDigitButtonClick = (digit: number) => {
        if (isDiskonFocused) {
            let newDisplay = String(inputDiskon);

            if (
                (inputDiskon === 0 && digit === 0) ||
                String(inputDiskon).length > 12
            ) {
                return;
            }

            if (inputDiskon !== 0) {
                newDisplay = newDisplay + digit.toString();
            } else {
                newDisplay = digit.toString();
            }
            setInputDiskon(newDisplay);
        } else if (isPinFocused) {
            let newDisplay = String(pinValue);

            if (
                (pinValue === 0 && digit === 0) ||
                String(pinValue).length > 12
            ) {
                return;
            }

            if (pinValue !== 0) {
                newDisplay = newDisplay + digit.toString();
            } else {
                newDisplay = digit.toString();
            }
            setPinValue(newDisplay);
        } else {
            let newDisplay = String(inputBayar);

            if (
                (inputBayar === 0 && digit === 0) ||
                String(inputBayar).length > 12
            ) {
                return;
            }

            if (inputBayar !== 0) {
                newDisplay = newDisplay + digit.toString();
            } else {
                newDisplay = digit.toString();
            }
            setInputBayar(newDisplay);
        }
    };

    const onEnterButtonClick = () => {
        // hitungBayar(Number(display));
        // setDisplay("");
    };

    const onZeroButtonClick = () => {
        if (isDiskonFocused) {
            let value = inputDiskon ?? 0;
            let newDisplay = value * 10;
            setInputDiskon(newDisplay);
        } else if (isPinFocused) {
            let value = pinValue ?? 0;
            let newDisplay = value * 10;
            setPinValue(newDisplay);
        } else {
            let value = inputBayar ?? 0;
            let newDisplay = value * 10;
            setInputBayar(newDisplay);
        }
    };

    const onTripleZeroButtonClick = () => {
        if (isDiskonFocused) {
            let value = inputDiskon ?? 0;
            let newDisplay = value * 1000;
            setInputDiskon(newDisplay);
        } else if (isPinFocused) {
            let value = pinValue ?? 0;
            let newDisplay = value * 1000;
            setPinValue(newDisplay);
        } else {
            let value = inputBayar ?? 0;
            let newDisplay = value * 1000;
            setInputBayar(newDisplay);
        }
    };

    const handleResetRincianPembayaran = () => {
        setInputBayar("");
        setInputDiskon("");
        setDisplayDiskon(0);
        setTotalTagihan(0);
        setKembalian(0);
        setPinValue("");
    };

    const handleRincianPembayaran = useCallback(
        (
            inputPembayaran: number,
            toggleDiskon: "nominal" | "persentase",
            inputDiskon: number | null,
            hargaCustomAmount: number,
            hargaOngkir: number,
        ) => {
            if (toggleDiskon === "nominal") {
                const hasilTotalTagihan =
                    totalHarga +
                    hargaCustomAmount +
                    hargaOngkir -
                    (inputDiskon ?? 0);
                setDisplayDiskon(inputDiskon ?? 0);
                setTotalTagihan(
                    totalHarga +
                        hargaCustomAmount +
                        hargaOngkir -
                        (inputDiskon ?? 0),
                );
                setKembalian(inputPembayaran - hasilTotalTagihan);
            } else if (toggleDiskon === "persentase") {
                const potongan = (inputDiskon ?? 0) / 100;
                const totalDiskon = totalHarga - totalHarga * potongan;
                const totalAkhir = totalHarga - totalDiskon;
                const hasilTotalTagihan =
                    totalHarga - totalAkhir + hargaCustomAmount + hargaOngkir;
                setDisplayDiskon(totalAkhir);
                setTotalTagihan(hasilTotalTagihan);
                setKembalian(inputPembayaran - hasilTotalTagihan);
            }
            if (inputPembayaran <= 0) {
                setKembalian(0);
            }
        },
        [totalHarga],
    );

    useEffect(() => {
        if (ukmData && (inputBayar !== null || inputDiskon !== null)) {
            handleRincianPembayaran(
                inputBayar,
                toggleDiskon,
                inputDiskon.toString().replace(",", "."),
                Number(formatHarga(String(hargaCustomAmount))),
                Number(formatHarga(String(hargaOngkir))),
            );
        }
    }, [
        handleRincianPembayaran,
        hargaCustomAmount,
        hargaOngkir,
        inputBayar,
        inputDiskon,
        toggleDiskon,
        ukmData,
    ]);

    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
    });

    useEffect(() => {
        if (isDonePembayaran === true) {
            handleResetRincianPembayaran();
            handlingResetPembayaran();
            resetCart();
            resetCustomAmountOngkir();
        }
    }, [
        handlingResetPembayaran,
        isDonePembayaran,
        resetCart,
        resetCustomAmountOngkir,
    ]);

    useEffect(() => {
        setInputDiskon("");
    }, [pinValue]);

    return (
        <React.Fragment>
            <Dialog
                maxWidth="lg"
                fullWidth
                fullScreen={fullScreen || isTabletScreen}
                open={isOpenModalBayar}
                onClose={() => {
                    closeModalBayar();
                    setChecked(false);
                    handleResetRincianPembayaran();
                    resetCustomAmountOngkir();
                }}
                PaperProps={{
                    sx: {
                        minHeight: { xs: "100vh", md: "90vh" },
                        maxHeight: {
                            xs: "100vh",
                            md: isTabletScreen ? "100vh" : "90vh",
                        },
                        borderRadius: 1,
                        // paddingTop: { xs: 5, md: 0 },
                    },
                }}
            >
                <DialogTitle sx={{ display: { sm: "block", md: "none" } }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton
                            onClick={() => {
                                closeModalBayar();
                                setChecked(false);
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" fontWeight="bold">
                            Masukkan Pembayaran
                        </Typography>
                    </Stack>
                </DialogTitle>
                <Stack direction={{ xs: "column", md: "row-reverse" }}>
                    <Grid item xs={12} lg={isTabletScreen ? 5 : 6}>
                        {/* <Kalkulator /> */}
                        <Box
                            // display="grid"
                            gridTemplateRows="1fr 1fr 4fr"
                            sx={{
                                display: { xs: "normal", md: "grid" },
                                height: {
                                    md: isTabletScreen ? "100vh" : "90vh",
                                },
                                rowGap: 1,
                            }}
                        >
                            <Box
                                display="flex"
                                marginLeft={4}
                                marginRight={3}
                                sx={{ marginTop: { xs: 0, md: 4 } }}
                            >
                                <NumberFormat
                                    value={inputBayar}
                                    inputMode="numeric"
                                    customInput={TextField}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    autoFocus
                                    fullWidth
                                    placeholder="0"
                                    allowNegative={false}
                                    onFocus={() => {
                                        unfocusDiskon();
                                        unfocusPin();
                                    }}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        setInputBayar(
                                            Number(
                                                event.target.value.replace(
                                                    /\./g,
                                                    "",
                                                ),
                                            ),
                                        );
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography
                                                    variant="h4"
                                                    fontWeight="bold"
                                                >
                                                    Rp.
                                                </Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        inputMode: "numeric",
                                        style: {
                                            fontSize: 40,
                                            fontWeight: "bold",
                                            textAlign: "right",
                                        },
                                    }}
                                />
                            </Box>
                            <Box
                                display="grid"
                                gridTemplateColumns="1fr 1fr 1fr 1fr"
                                marginTop={2}
                                marginLeft={4}
                                marginRight={3}
                                sx={{ gap: { xs: 1, lg: 2 } }}
                            >
                                <Button
                                    disabled={isDiskonFocused || isPinFocused}
                                    variant="outlined"
                                    onClick={() => setInputBayar(totalTagihan)}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: 14,
                                                md: "subtitle1.fontSize",
                                            },
                                        }}
                                        fontSize={16}
                                    >
                                        {isTabletScreen ? "Pas" : "Uang Pas"}
                                    </Typography>
                                </Button>
                                <ButtonKalkulator
                                    disabled={isDiskonFocused || isPinFocused}
                                    buttonVariant="contained"
                                    onClick={() => onQuickActionClick(100000)}
                                >
                                    100.000
                                </ButtonKalkulator>
                                <ButtonKalkulator
                                    disabled={isDiskonFocused || isPinFocused}
                                    buttonVariant="contained"
                                    onClick={() => onQuickActionClick(50000)}
                                >
                                    50.000
                                </ButtonKalkulator>
                                <ButtonKalkulator
                                    disabled={isDiskonFocused || isPinFocused}
                                    buttonVariant="contained"
                                    onClick={() => onQuickActionClick(20000)}
                                >
                                    20.000
                                </ButtonKalkulator>
                                <ButtonKalkulator
                                    disabled={isDiskonFocused || isPinFocused}
                                    buttonVariant="contained"
                                    onClick={() => onQuickActionClick(10000)}
                                >
                                    10.000
                                </ButtonKalkulator>
                                <ButtonKalkulator
                                    disabled={isDiskonFocused || isPinFocused}
                                    buttonVariant="contained"
                                    onClick={() => onQuickActionClick(5000)}
                                >
                                    5.000
                                </ButtonKalkulator>
                                <ButtonKalkulator
                                    disabled={isDiskonFocused || isPinFocused}
                                    buttonVariant="contained"
                                    onClick={() => onQuickActionClick(2000)}
                                >
                                    2.000
                                </ButtonKalkulator>
                                <ButtonKalkulator
                                    disabled={isDiskonFocused || isPinFocused}
                                    buttonVariant="contained"
                                    onClick={() => onQuickActionClick(1000)}
                                >
                                    1.000
                                </ButtonKalkulator>
                            </Box>

                            {isLaptopScreen ? (
                                <Box
                                    display="grid"
                                    gridTemplateColumns="1fr 1fr 1fr 1fr"
                                    gap={{ xs: 1, lg: 2 }}
                                    marginTop={2}
                                    marginLeft={4}
                                    marginRight={3}
                                    marginBottom={4}
                                >
                                    <ButtonNumKalkulator
                                        buttonVariant="outlined"
                                        onClick={() => onDigitButtonClick(1)}
                                    >
                                        1
                                    </ButtonNumKalkulator>
                                    <ButtonNumKalkulator
                                        buttonVariant="outlined"
                                        onClick={() => onDigitButtonClick(2)}
                                    >
                                        2
                                    </ButtonNumKalkulator>
                                    <ButtonNumKalkulator
                                        buttonVariant="outlined"
                                        onClick={() => onDigitButtonClick(3)}
                                    >
                                        3
                                    </ButtonNumKalkulator>
                                    <ButtonKalkulator
                                        disabled={false}
                                        buttonVariant="contained"
                                        onClick={onDelButtonClick}
                                    >
                                        Del
                                    </ButtonKalkulator>
                                    <ButtonNumKalkulator
                                        buttonVariant="outlined"
                                        onClick={() => onDigitButtonClick(4)}
                                    >
                                        4
                                    </ButtonNumKalkulator>
                                    <ButtonNumKalkulator
                                        buttonVariant="outlined"
                                        onClick={() => onDigitButtonClick(5)}
                                    >
                                        5
                                    </ButtonNumKalkulator>
                                    <ButtonNumKalkulator
                                        buttonVariant="outlined"
                                        onClick={() => onDigitButtonClick(6)}
                                    >
                                        6
                                    </ButtonNumKalkulator>
                                    <ButtonKalkulator
                                        disabled={false}
                                        buttonVariant="contained"
                                        onClick={onClearButtonClick}
                                    >
                                        C
                                    </ButtonKalkulator>
                                    <ButtonNumKalkulator
                                        buttonVariant="outlined"
                                        onClick={() => onDigitButtonClick(7)}
                                    >
                                        7
                                    </ButtonNumKalkulator>
                                    <ButtonNumKalkulator
                                        buttonVariant="outlined"
                                        onClick={() => onDigitButtonClick(8)}
                                    >
                                        8
                                    </ButtonNumKalkulator>
                                    <ButtonNumKalkulator
                                        buttonVariant="outlined"
                                        onClick={() => onDigitButtonClick(9)}
                                    >
                                        9
                                    </ButtonNumKalkulator>
                                    <Button
                                        variant="contained"
                                        className="is-enter"
                                        onClick={onEnterButtonClick}
                                        sx={{
                                            backgroundColor: "primary",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    xs: "subtitle1.fontSize",
                                                    md: "h6.fontSize",
                                                },
                                            }}
                                        >
                                            Enter
                                        </Typography>
                                    </Button>
                                    <ButtonNumKalkulator
                                        buttonVariant="outlined"
                                        onClick={onZeroButtonClick}
                                    >
                                        0
                                    </ButtonNumKalkulator>
                                    <Button
                                        variant="outlined"
                                        className="is-000"
                                        onClick={onTripleZeroButtonClick}
                                    >
                                        <Typography variant="h6">
                                            000
                                        </Typography>
                                    </Button>
                                </Box>
                            ) : undefined}
                        </Box>
                    </Grid>
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                            marginRight: "-1px",
                            display: { xs: "none", sm: "block" },
                        }}
                    />

                    {/* INFO TAGIHAN */}
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={isTabletScreen ? 7 : 6}
                    >
                        {/* <InfoTagihan /> */}
                        <Box
                            gridTemplateRows="auto auto auto auto auto 1fr auto"
                            sx={{
                                display: { xs: "normal", md: "grid" },
                                rowGap: 1,
                                overflowY: fullScreen ? undefined : "auto",
                            }}
                            height={
                                fullScreen
                                    ? undefined
                                    : isTabletScreen
                                    ? "100vh"
                                    : "90vh"
                            }
                        >
                            <Box marginLeft={3} marginRight={4} marginTop={4}>
                                <Typography variant="h6" fontWeight="bold">
                                    Set Diskon Harga
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="#545E6A"
                                    marginBottom={2}
                                >
                                    Atur potongan harga berdasarkan nilai
                                    nominal langsung atau nilai persen.
                                </Typography>
                                <Grid
                                    container
                                    alignItems="flex-start"
                                    spacing={2}
                                    mb={2}
                                >
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="nomorPin"
                                            size="small"
                                            type="number"
                                            label="Masukkan pin untuk atur diskon"
                                            placeholder="6-digit"
                                            fullWidth
                                            sx={{ maxWidth: "500px" }}
                                            value={pinValue}
                                            onChange={handleChangePin}
                                            onFocus={() => {
                                                focusPin();
                                                unfocusDiskon();
                                                setDisplayDiskon(0);
                                                setInputDiskon("");
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={6}></Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{
                                            display:
                                                pinDiskon?.content.pin !==
                                                pinValue
                                                    ? "none"
                                                    : undefined,
                                        }}
                                    >
                                        <Stack
                                            display="flex"
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                                justifyContent: {
                                                    xs: "space-between",
                                                    sm: "flex-start",
                                                },
                                            }}
                                        >
                                            <Button
                                                disabled={
                                                    pinDiskon?.content.pin !==
                                                    pinValue
                                                }
                                                size="medium"
                                                fullWidth
                                                variant={
                                                    toggleDiskon === "nominal"
                                                        ? "contained"
                                                        : "outlined"
                                                }
                                                onClick={() => pilihNominal()}
                                            >
                                                <Typography>Nominal</Typography>
                                            </Button>
                                            <Button
                                                disabled={
                                                    pinDiskon?.content.pin !==
                                                    pinValue
                                                }
                                                size="medium"
                                                fullWidth
                                                variant={
                                                    toggleDiskon ===
                                                    "persentase"
                                                        ? "contained"
                                                        : "outlined"
                                                }
                                                onClick={() =>
                                                    pilihPersentase()
                                                }
                                            >
                                                <Typography>
                                                    Persentase
                                                </Typography>
                                            </Button>
                                        </Stack>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{
                                            display:
                                                pinDiskon?.content.pin !==
                                                pinValue
                                                    ? "none"
                                                    : undefined,
                                        }}
                                    >
                                        <MaskedInput
                                            disabled={
                                                pinDiskon?.content.pin !==
                                                pinValue
                                            }
                                            value={inputDiskon}
                                            mask={currencyMask}
                                            placeholder="0"
                                            id="my-input-id"
                                            onFocus={() => {
                                                focusDiskon();
                                                unfocusPin();
                                            }}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                // setDisplay(event.target.value);
                                                setInputDiskon(
                                                    event.target.value.replace(
                                                        /\./g,
                                                        "",
                                                    ) ?? "",
                                                    // .replace(",", ".") ?? "",
                                                );
                                            }}
                                            render={(ref, props) => (
                                                <TextField
                                                    inputRef={ref}
                                                    {...props}
                                                    error={
                                                        toggleDiskon ===
                                                            "persentase" &&
                                                        inputDiskon > 100
                                                    }
                                                    helperText={
                                                        toggleDiskon ===
                                                            "persentase" &&
                                                        inputDiskon > 100
                                                            ? "Diskon harus kurang dari 100 persen"
                                                            : ""
                                                    }
                                                    fullWidth
                                                    size="small"
                                                    InputProps={{
                                                        startAdornment:
                                                            toggleDiskon ===
                                                            "nominal" ? (
                                                                <InputAdornment position="start">
                                                                    <Typography fontWeight="bold">
                                                                        Rp.
                                                                    </Typography>
                                                                </InputAdornment>
                                                            ) : (
                                                                ""
                                                            ),
                                                        endAdornment:
                                                            toggleDiskon ===
                                                            "persentase" ? (
                                                                <InputAdornment position="end">
                                                                    <Typography fontWeight="bold">
                                                                        %
                                                                    </Typography>
                                                                </InputAdornment>
                                                            ) : (
                                                                ""
                                                            ),
                                                    }}
                                                    inputProps={{
                                                        inputMode: "numeric",
                                                        style: {
                                                            fontWeight: "bold",
                                                            textAlign: "right",
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box marginLeft={3} marginRight={4}>
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    marginBottom={1}
                                >
                                    Rincian Transaksi
                                </Typography>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Grid item xs={6} lg={6}>
                                        <Typography
                                            color="#76747C"
                                            variant="body2"
                                            fontWeight={600}
                                        >
                                            Subtotal Harga
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} lg={6}>
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            paddingX={1}
                                        >
                                            <Typography fontWeight="bold">
                                                Rp.
                                            </Typography>
                                            <Typography fontWeight="bold">
                                                {toRibuan(totalHarga)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {ukmData && ukmData?.pajak && (
                                    <>
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                            mt={1}
                                        >
                                            <Grid item xs={6} lg={6}>
                                                <Typography
                                                    color="#76747C"
                                                    variant="body2"
                                                    fontWeight={600}
                                                >
                                                    DPP
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} lg={6}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    paddingX={1}
                                                >
                                                    <Typography fontWeight="bold">
                                                        Rp.
                                                    </Typography>
                                                    <NumberFormat
                                                        value={dpp}
                                                        inputMode="numeric"
                                                        customInput={TextField}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        fullWidth
                                                        placeholder="0"
                                                        allowNegative={false}
                                                        size="small"
                                                        variant="standard"
                                                        onChange={(
                                                            event: React.ChangeEvent<HTMLInputElement>,
                                                        ) => {
                                                            setDpp(
                                                                Number(
                                                                    formatHarga(
                                                                        event
                                                                            .target
                                                                            .value,
                                                                    ),
                                                                ),
                                                            );
                                                        }}
                                                        inputProps={{
                                                            inputMode:
                                                                "numeric",
                                                            style: {
                                                                fontWeight:
                                                                    "bold",
                                                                textAlign:
                                                                    "right",
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                            mt={1}
                                        >
                                            <Grid item xs={6} lg={6}>
                                                <Typography
                                                    color="#76747C"
                                                    variant="body2"
                                                    fontWeight={600}
                                                >
                                                    PPN ({ukmData.pajak}%)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} lg={6}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    paddingX={1}
                                                >
                                                    <Typography fontWeight="bold">
                                                        Rp.
                                                    </Typography>
                                                    <Typography fontWeight="bold">
                                                        {toRibuan(ppn)}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {checkedCustomAmount && (
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        marginTop={1}
                                    >
                                        <Grid item xs={6} lg={6}>
                                            <Typography
                                                color="#76747C"
                                                variant="body2"
                                                fontWeight={600}
                                            >
                                                Custom Amount
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} lg={6}>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                paddingX={1}
                                            >
                                                <Typography fontWeight="bold">
                                                    Rp.
                                                </Typography>
                                                <Typography fontWeight="bold">
                                                    {hargaCustomAmount}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                                {checkedOngkir && (
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        marginTop={1}
                                    >
                                        <Grid item xs={6} lg={6}>
                                            <Typography
                                                color="#76747C"
                                                variant="body2"
                                                fontWeight={600}
                                            >
                                                Ongkos Kirim
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} lg={6}>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                paddingX={1}
                                            >
                                                <Typography fontWeight="bold">
                                                    Rp.
                                                </Typography>
                                                <Typography fontWeight="bold">
                                                    {hargaOngkir}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                                {/* {ukmData && ukmData.pajak && (
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        marginTop={1}
                                    >
                                        <Grid item xs={6} lg={6}>
                                            <Typography
                                                color="#76747C"
                                                variant="body2"
                                                fontWeight={600}
                                            >
                                                {`Pajak (${ukmData.pajak}%)`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} lg={6}>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                bgcolor="#FFF5EC"
                                                paddingX={1}
                                            >
                                                <Typography
                                                    color="#FB923C"
                                                    fontWeight="bold"
                                                >
                                                    Rp.
                                                </Typography>
                                                <Typography
                                                    color="#FB923C"
                                                    fontWeight="bold"
                                                >
                                                    {toRibuan(
                                                        Number(displayPajak),
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )} */}
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                    marginTop={1}
                                >
                                    <Grid item xs={6} lg={6}>
                                        <Typography
                                            color="#76747C"
                                            variant="body2"
                                            fontWeight={600}
                                        >
                                            Potongan Diskon
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} lg={6}>
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            bgcolor="#FFF5EC"
                                            paddingX={1}
                                        >
                                            <Typography
                                                color="#FB923C"
                                                fontWeight="bold"
                                            >
                                                Rp.
                                            </Typography>
                                            <Typography
                                                color="#FB923C"
                                                fontWeight="bold"
                                            >
                                                {toRibuan(
                                                    Number(displayDiskon),
                                                )}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                    marginTop={1}
                                >
                                    <Grid item xs={6} lg={6}>
                                        <Typography
                                            color="#76747C"
                                            variant="body2"
                                            fontWeight={600}
                                        >
                                            Total Tagihan
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} lg={6}>
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            bgcolor="#E8F6ED"
                                            paddingX={1}
                                        >
                                            <Typography
                                                color="#45A779"
                                                fontWeight="bold"
                                            >
                                                Rp.
                                            </Typography>
                                            <Typography
                                                color="#45A779"
                                                fontWeight="bold"
                                            >
                                                {toRibuan(Number(totalTagihan))}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                    marginTop={1}
                                >
                                    <Grid item xs={6} lg={6}>
                                        <Typography
                                            color="#76747C"
                                            variant="body2"
                                            fontWeight={600}
                                        >
                                            Uang yang Dibayar
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} lg={6}>
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            bgcolor="#FFF5EC"
                                            paddingX={1}
                                        >
                                            <Typography
                                                color="#FB923C"
                                                fontWeight="bold"
                                            >
                                                Rp.
                                            </Typography>
                                            <Typography
                                                color="#FB923C"
                                                fontWeight="bold"
                                            >
                                                {toRibuan(
                                                    inputBayar === ""
                                                        ? 0
                                                        : inputBayar,
                                                )}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                    marginTop={1}
                                >
                                    <Grid item xs={6} lg={6}>
                                        <Typography
                                            color="#76747C"
                                            variant="body2"
                                            fontWeight={600}
                                        >
                                            Kembalian
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} lg={6}>
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            bgcolor="#FCEAEA"
                                            paddingX={1}
                                        >
                                            <Typography
                                                color="#DC2626"
                                                fontWeight="bold"
                                            >
                                                Rp.
                                            </Typography>
                                            <Typography
                                                color="#DC2626"
                                                fontWeight="bold"
                                            >
                                                {toRibuan(kembalian)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box marginLeft={3} marginRight={4} marginTop={2}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                        >
                                            Sales
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            color="#545E6A"
                                        >
                                            Atur nama sales transaksi (opsional)
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            disabled={
                                                user &&
                                                !user.akses.transaksi.includes(
                                                    13,
                                                )
                                            }
                                            loading={isLoadingUsersByUkm}
                                            disablePortal
                                            options={mappedUsersByUkm ?? []}
                                            getOptionLabel={(option) =>
                                                option.nama
                                            }
                                            isOptionEqualToValue={(
                                                option,
                                                value,
                                            ) =>
                                                String(option.id) ===
                                                String(value.id)
                                            }
                                            value={sales}
                                            onChange={(
                                                _event,
                                                newSales: IOptions | null,
                                            ) => {
                                                setSales(newSales);
                                            }}
                                            size="small"
                                            sx={{ marginTop: 2 }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Nama sales"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box marginLeft={3} marginRight={4} marginTop={2}>
                                <Stack
                                    direction="row"
                                    spacing={3}
                                    alignItems="center"
                                >
                                    <Typography variant="h6" fontWeight="bold">
                                        Custom Amount
                                    </Typography>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        checkedCustomAmount
                                                    }
                                                    onChange={
                                                        handleChangeCustomAmount
                                                    }
                                                />
                                            }
                                            label={
                                                checkedCustomAmount
                                                    ? "On"
                                                    : "Off"
                                            }
                                            componentsProps={{
                                                typography: {
                                                    variant: "body2",
                                                },
                                            }}
                                        />
                                    </FormGroup>
                                </Stack>
                                <Typography variant="body2" color="#545E6A">
                                    Atur custom amount (opsional)
                                </Typography>
                                {checkedCustomAmount && (
                                    <Grid
                                        container
                                        alignItems="flex-start"
                                        spacing={2}
                                        marginTop={1}
                                    >
                                        <Grid item xs={12} md={6}>
                                            <NumberFormat
                                                customInput={TextField}
                                                label="Harga"
                                                // margin="dense"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                fullWidth
                                                variant="outlined"
                                                value={hargaCustomAmount}
                                                onChange={
                                                    handleChangeHargaCustomAmount
                                                }
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography>
                                                                Rp.
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                size="small"
                                                label="Keterangan"
                                                fullWidth
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                                value={keteranganCustomAmount}
                                                onChange={
                                                    handleChangeKeteranganCustomAmount
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Box>
                            <Box marginLeft={3} marginRight={4} marginTop={2}>
                                <Stack
                                    direction="row"
                                    spacing={3}
                                    alignItems="center"
                                >
                                    <Typography variant="h6" fontWeight="bold">
                                        Ongkos Kirim
                                    </Typography>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={checkedOngkir}
                                                    onChange={
                                                        handleChangeOngkir
                                                    }
                                                />
                                            }
                                            label={checkedOngkir ? "On" : "Off"}
                                            componentsProps={{
                                                typography: {
                                                    variant: "body2",
                                                },
                                            }}
                                        />
                                    </FormGroup>
                                </Stack>
                                <Typography variant="body2" color="#545E6A">
                                    Atur ongkos kirim (opsional)
                                </Typography>
                                {checkedOngkir && (
                                    <Grid
                                        container
                                        alignItems="flex-start"
                                        spacing={2}
                                        marginTop={1}
                                    >
                                        <Grid item xs={12} md={6}>
                                            <NumberFormat
                                                customInput={TextField}
                                                label="Harga"
                                                // margin="dense"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                fullWidth
                                                variant="outlined"
                                                value={hargaOngkir}
                                                onChange={
                                                    handleChangeHargaOngkir
                                                }
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography>
                                                                Rp.
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                size="small"
                                                label="Keterangan"
                                                fullWidth
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                                value={keteranganOngkir}
                                                onChange={
                                                    handleChangeKeteranganOngkir
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Box>
                            <Box
                                marginLeft={3}
                                marginRight={4}
                                marginTop={2}
                                sx={{
                                    visibility:
                                        ukmData?.isPro === 0
                                            ? "hidden"
                                            : undefined,
                                }}
                            >
                                <Typography fontWeight="bold" marginBottom={2}>
                                    Transaksi dengan FIFO
                                </Typography>
                                <Stack
                                    direction="row"
                                    spacing={3}
                                    alignItems="center"
                                >
                                    <Typography>Kustom faktur?</Typography>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={checked}
                                                    onChange={handleChange}
                                                />
                                            }
                                            label={checked ? "Ya" : "Tidak"}
                                            componentsProps={{
                                                typography: {
                                                    variant: "body2",
                                                },
                                            }}
                                        />
                                    </FormGroup>
                                </Stack>
                                <Box gap={2} marginTop={2}>
                                    {checked && cart.length > 0
                                        ? cart.map((li, idx) => (
                                              <ListSelectedBarang
                                                  key={String(li.id)}
                                                  id={li.id}
                                                  idx={idx}
                                                  namaBarang={li.namaBarang}
                                                  sku={li.sku}
                                                  qty={li.qty}
                                                  hpp={li.hpp}
                                                  detailBundling={
                                                      li.detailBundling
                                                  }
                                              />
                                          ))
                                        : ""}
                                </Box>
                            </Box>
                            <Box
                                marginLeft={3}
                                marginRight={4}
                                marginBottom={4}
                                sx={{ marginTop: { xs: 15, md: 0 } }}
                            >
                                <Stack
                                    direction="row-reverse"
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Button
                                        disabled={
                                            inputBayar === 0 ||
                                            inputBayar < totalTagihan ||
                                            (toggleDiskon === "persentase" &&
                                                inputDiskon > 100)
                                        }
                                        variant="contained"
                                        onClick={() => {
                                            const cartZeroFaktur = cart.filter(
                                                (li) =>
                                                    li.detailBundling.length ===
                                                        0 &&
                                                    li.faktur.length === 0,
                                            );

                                            let countBundling = 0;
                                            cart.forEach((li) =>
                                                li.detailBundling.forEach(
                                                    (db) => {
                                                        if (db.newFaktur) {
                                                            return countBundling++;
                                                        }
                                                    },
                                                ),
                                            );
                                            let count = 0;
                                            cart.forEach((li) =>
                                                li.detailBundling.forEach(
                                                    (db) => {
                                                        return count++;
                                                    },
                                                ),
                                            );

                                            const isNotEqual =
                                                count !== countBundling;
                                            const notApproved =
                                                checked &&
                                                (cartZeroFaktur.length > 0 ||
                                                    isNotEqual);
                                            if (notApproved) {
                                                Swal.fire({
                                                    title: "Pilih faktur terlebih dahulu",
                                                    position: "top-end",
                                                    showConfirmButton: false,
                                                    icon: "error",
                                                    toast: true,
                                                    timer: 3000,
                                                    timerProgressBar: true,
                                                    showCloseButton: true,
                                                    customClass: {
                                                        container: "my-swal",
                                                    },
                                                });
                                                return;
                                            }
                                            openModalKonfirmasiBayar();
                                            props.handlingRincianPembayaran(
                                                toggleDiskon === "persentase"
                                                    ? inputDiskon
                                                    : 0,
                                                displayDiskon,
                                                totalTagihan,
                                                inputBayar,
                                                kembalian,
                                                sales,
                                                totalHarga,
                                                false,
                                                dpp,
                                                ppn,
                                            );
                                        }}
                                        size="large"
                                        fullWidth
                                    >
                                        <Typography>Bayar</Typography>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            openModalKonfirmasiBayar();
                                            props.handlingRincianPembayaran(
                                                toggleDiskon === "persentase"
                                                    ? inputDiskon
                                                    : 0,
                                                displayDiskon,
                                                totalTagihan,
                                                inputBayar,
                                                kembalian,
                                                sales,
                                                totalHarga,
                                                true,
                                                dpp,
                                                ppn,
                                            );
                                        }}
                                        fullWidth
                                    >
                                        <Typography>Non-Tunai</Typography>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            closeModalBayar();
                                            setChecked(false);
                                            handleResetRincianPembayaran();
                                            resetCustomAmountOngkir();
                                        }}
                                        fullWidth
                                    >
                                        <Typography>Kembali</Typography>
                                    </Button>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                </Stack>
            </Dialog>
        </React.Fragment>
    );
};

export default ModalBayar;
