import React, { Fragment } from "react";
import { Text, Font, View, StyleSheet } from "@react-pdf/renderer";
import toRibuan from "../../utils/toRibuan";

Font.register({
    family: "Open-Sans",
    src: "https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf",
});

interface ICart {
    id: number;
    namaBarang: string;
    sku?: string | undefined;
    qty: number;
    harga: number;
    jumlahStok?: number | undefined;
}

interface IPrintStrukA5TableRowProps {
    cart?: ICart[];
}

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        borderLeftColor: "#000",
        borderLeftWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
        alignItems: "center",
        fontFamily: "Open-Sans",
        fontSize: 9,
        whiteSpace: "initial",
        textOverflow: "ellipsis",
        flexGrow: 1,
    },
    no: {
        width: "5%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 4,
        height: 24,
    },
    description: {
        width: "55%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 24,
    },
    qty: {
        width: "10%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        textAlign: "right",
        height: 24,
    },
    rate: {
        width: "15%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        textAlign: "right",
        height: 24,
    },
    amount: {
        width: "15%",
        paddingHorizontal: 8,
        textAlign: "right",
        height: 24,
    },
});

const PrintStrukA5TableRow = ({ cart }: IPrintStrukA5TableRowProps) => {
    const rows = cart?.map((df, index: number) => (
        <View style={styles.row} key={df.id.toString()}>
            <Text style={styles.no}>{index + 1}</Text>
            <Text style={styles.description}>{df.namaBarang}</Text>
            <Text style={styles.qty}>{df.qty}</Text>
            <Text style={styles.rate}>{toRibuan(df.harga)}</Text>
            <Text style={styles.amount}>{toRibuan(df.qty * df.harga)}</Text>
        </View>
    ));
    return <Fragment>{rows}</Fragment>;
};

export default PrintStrukA5TableRow;
