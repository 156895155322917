import React, { useMemo, useState } from "react";
import * as yup from "yup";
import defaultAxios, { AxiosError } from "axios";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import { useTransaksi } from "../../context/transaksiContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DefaultResponse, ErrorFieldResponse } from "../../constants/types";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import qs from "query-string";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useAuth } from "../../context/authContext";

interface IModalImportHargaProps {
    isOpenModalImportHarga: boolean;
    closeModalImportHarga: () => void;
}

interface IValuesImport {
    berkas: File | null;
}

const schema = yup.object({
    berkas: yup.mixed(),
});

const ModalImportHarga = ({
    isOpenModalImportHarga,
    closeModalImportHarga,
}: IModalImportHargaProps) => {
    const theme = useTheme();
    const { setFetchingItems } = useTransaksi();
    const { ukmIdUser } = useAuth();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isButtonTemplateLoading, setIsButtonTemplateLoading] =
        useState(false);

    const initialValues: IValuesImport = useMemo(
        () => ({
            berkas: null,
        }),
        [],
    );

    const apiUnduhTemplate = async () => {
        setIsButtonTemplateLoading(true);
        try {
            const apiUrl = qs.stringifyUrl({
                url: "api/excelitemharga",
                query: {
                    ukmId: ukmIdUser && ukmIdUser[0],
                },
            });
            const res = await axios.get(apiUrl, {
                responseType: "blob",
            });
            if (res.status === 200) {
                const file = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                });
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = fileURL;
                link.setAttribute(
                    "download",
                    `Template_Koreksi_Harga_Kiko Mart`,
                ); //or any other extension
                document.body.appendChild(link);
                link.click();
                setIsButtonTemplateLoading(false);
            }
        } catch (error) {
            console.error(error);
            setIsButtonTemplateLoading(false);
        }
    };

    const {
        handleSubmit,
        control,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<IValuesImport>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: IValuesImport) => {
        console.log(values);
        setIsButtonLoading(true);
        try {
            const formData = new FormData();
            formData.append("ukmId", String(ukmIdUser && ukmIdUser[0]));
            formData.append(
                "file",
                values.berkas ? (values.berkas as any) : "",
            );
            const { data } = await axios.post<DefaultResponse>(
                `/api/excelitemharga`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            if (data.code === 200) {
                Swal.fire({
                    title: data.message ?? "Harga berhasil dikoreksi",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
            // openAlert();
            setIsButtonLoading(false);
            setFetchingItems();
            closeModalImportHarga();
        } catch (error) {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    console.log(
                        "Ini message",
                        serverError.response.data?.message,
                    );
                    Swal.fire({
                        title: `${serverError.response.data?.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            }
            closeModalImportHarga();
            setIsButtonLoading(false);
            console.error(error);
        }
    };

    return (
        <Dialog
            maxWidth="sm"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModalImportHarga}
            onClose={() => {}}
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalImportHarga}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Koreksi Harga
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Typography variant="h6" fontWeight="bold">
                    Template Pengisian
                </Typography>
                <Typography
                    variant="body1"
                    color="#545E6A"
                    sx={{ maxWidth: "360px" }}
                >
                    Unduh template terlebih dahulu sebelum mengunggah berkas
                </Typography>
                <LoadingButton
                    loading={isButtonTemplateLoading}
                    loadingPosition="center"
                    variant="outlined"
                    onClick={() => {
                        apiUnduhTemplate();
                    }}
                    size="large"
                    sx={{ marginY: 3 }}
                >
                    Unduh Template
                </LoadingButton>
                <Typography variant="h6" fontWeight="bold">
                    Unggah Berkas
                </Typography>
                <Grid container alignItems="center" marginY={2}>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="#808080">
                            Berkas Stok
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="berkas"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    id="berkas"
                                    fullWidth
                                    type="file"
                                    variant="outlined"
                                    inputProps={{
                                        accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                                    }}
                                    error={Boolean(errors.berkas)}
                                    helperText={
                                        errors.berkas
                                            ? errors.berkas.message
                                            : " "
                                    }
                                    {...field}
                                    onChange={(event: any) => {
                                        if (
                                            event.target.files[0].size < 512000
                                        ) {
                                            console.log(event.target.files[0]);
                                            setValue(
                                                "berkas",
                                                event.target.files[0],
                                            );
                                            clearErrors("berkas");
                                        } else {
                                            event.target.value = "";
                                            setError("berkas", {
                                                type: "manual",
                                                message:
                                                    "Ukuran berkas harus kurang dari 500KB",
                                            });
                                        }
                                    }}
                                    value={undefined}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Stack
                    direction="row"
                    spacing={isDesktopScreen ? 0 : 2}
                    alignItems="center"
                >
                    <Button
                        variant="outlined"
                        onClick={() => closeModalImportHarga()}
                        fullWidth
                        sx={{ display: { xs: "block", md: "none" } }}
                    >
                        <Typography>Kembali</Typography>
                    </Button>
                    <LoadingButton
                        loading={isButtonLoading}
                        loadingPosition="center"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                        type="submit"
                        fullWidth={isDesktopScreen ? false : true}
                    >
                        Unggah
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalImportHarga;
