/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
    Image,
} from "@react-pdf/renderer";
import {
    DetailFakturKonsinyasi,
    DataPrintFakturKonsinyasi,
    ContentUkm,
    ContentGetKonsinyiResponse,
} from "../../constants/types";
import Terbilang from "terbilang-ts";
import moment from "moment";
import toRibuan from "../../utils/toRibuan";

Font.register({
    family: "Open-Sans",
    src: "https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf",
});

Font.register({
    family: "Open-Sans-Bold",
    src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#FFF",
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    infoToko: {
        fontSize: 11,
        fontFamily: "Open-Sans",
    },
    infoTokoBold: {
        fontSize: 11,
        fontFamily: "Open-Sans-Bold",
    },
    container: {
        flexDirection: "row",
        borderBottomColor: "#000",
        backgroundColor: "#fff",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        textAlign: "center",
        fontSize: 11,
        fontFamily: "Open-Sans-Bold",
        flexGrow: 1,
    },
    no: {
        width: "10%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    namaBarang: {
        width: "50%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    qty: {
        width: "10%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    hargaSatuan: {
        width: "15%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    jumlah: {
        width: "15%",
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    },
    logoCompany: {
        display: "flex",
        flexDirection: "row",
        gap: "12px",
    },
    logo: {
        flexBasis: "50%",
    },
    image: {
        width: 70,
        height: 70,
    },
    company: {
        flexBasis: "50%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    },
    title: {
        fontWeight: "bold",
        fontSize: 11,
    },
    subTitle: {
        fontWeight: "normal",
        fontSize: 11,
    },
    toInfo: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    to: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        flexBasis: "50%",
    },
    toDear: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: 12,
    },
    toWrapper: {
        border: "2px solid black",
        width: "100%",
        padding: "4px",
        display: "flex",
        flexDirection: "column",
    },
    info: {
        border: "2px solid black",
        padding: "4px",
        width: "240px",
        marginTop: "13px",
    },
    infoWrapper: {
        display: "flex",
        flexDirection: "row",
        gap: "24px",
        textTransform: "uppercase",
    },
    textWidth: {
        width: "80px",
        fontWeight: "normal",
        fontSize: 11,
    },
    bold: {
        fontWeight: "bold",
        fontSize: 11,
    },
    note: {
        border: "2px solid black",
        padding: "4px",
        display: "flex",
        flexDirection: "column",
    },
    noteWrapper: {
        display: "flex",
        flexDirection: "column",
        marginTop: "20px",
    },
    bank: {
        fontSize: 11,
        fontWeight: "bold",
        textDecoration: "underline",
    },
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 24,
    },
    tableHeaderContainer: {
        flexDirection: "row",
        borderColor: "#000",
        borderWidth: 1,
        backgroundColor: "#fff",
        alignItems: "center",
        fontFamily: "Open-Sans-Bold",
        fontSize: 8,
        whiteSpace: "initial",
        textOverflow: "ellipsis",
        flexGrow: 1,
    },
    tableHeaderNo: {
        width: "5%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 4,
        height: 24,
    },
    tableheaderProduct: {
        width: "45%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 24,
    },
    tableHeaderQty: {
        width: "10%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        textAlign: "right",
        height: 24,
    },
    tableHeaderPrice: {
        width: "25%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        textAlign: "right",
        height: 24,
    },
    tableHeaderDiscount: {
        width: "10%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        textAlign: "right",
        height: 24,
    },
    tableHeaderTotalPrice: {
        width: "15%",
        paddingHorizontal: 8,
        textAlign: "right",
        height: 24,
    },
    row: {
        flexDirection: "row",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        borderLeftColor: "#000",
        borderLeftWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
        alignItems: "center",
        fontFamily: "Open-Sans",
        fontSize: 8,
    },
    subtotalText: {
        width: "15%",
        textAlign: "right",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        paddingTop: 3,
        height: 18,
    },
    subtotal: {
        width: "15%",
        textAlign: "right",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        paddingHorizontal: 8,
        paddingTop: 3,
        height: 18,
    },
    totalText: {
        width: "15%",
        textAlign: "right",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        paddingTop: 3,
        height: 18,
    },
    totalAmount: {
        width: "15%",
        textAlign: "right",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        paddingHorizontal: 8,
        paddingTop: 3,
        height: 18,
        backgroundColor: "#d2d9ec",
    },
    signContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 24,
    },
    signWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 4,
    },
    signTitle: {
        fontSize: 11,
        fontFamily: "Open-Sans",
    },
    signName: {
        fontSize: 11,
        fontFamily: "Open-Sans",
        marginTop: 48,
    },
    textCalculated: {
        marginTop: "20px",
    },
});

interface IPrintProps {
    dataFaktur: DataPrintFakturKonsinyasi;
    dataUkm?: ContentUkm | undefined;
    dataKonsinyi?: ContentGetKonsinyiResponse;
}

const PrintFakturKonsinyasi = ({
    dataFaktur,
    dataUkm,
    dataKonsinyi,
}: IPrintProps) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.wrapper}>
                    <View style={styles.logoCompany}>
                        <View style={styles.logo}>
                            <Image
                                src="/images/kiko-white.png"
                                style={{
                                    height: 70,
                                    width: 70,
                                }}
                            />
                        </View>
                        <View style={styles.company}>
                            <Text style={styles.title}>
                                PT. Kiko Mart Indonesia
                            </Text>
                            <Text style={styles.subTitle}>
                                {dataUkm?.alamatToko}
                            </Text>
                        </View>
                    </View>

                    <View style={styles.toInfo}>
                        <View style={styles.to}>
                            <Text style={styles.toDear}>Kepada Yth.</Text>
                            <View style={styles.toWrapper}>
                                <Text style={styles.subTitle}>
                                    {dataKonsinyi?.nama}
                                </Text>
                                <Text style={styles.subTitle}>
                                    {dataKonsinyi?.alamat}
                                </Text>
                                <Text style={styles.subTitle}>
                                    {dataKonsinyi?.noTelepon}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.info}>
                            <View style={styles.infoWrapper}>
                                <Text style={styles.textWidth}>tanggal</Text>
                                <Text style={styles.subTitle}>
                                    : {moment(dataFaktur.tanggal).format("LL")}
                                </Text>
                            </View>
                            <View style={styles.infoWrapper}>
                                <Text style={styles.textWidth}>no. sj</Text>
                                <Text style={styles.subTitle}>
                                    : {dataFaktur.faktur}
                                </Text>
                            </View>
                            <View style={styles.infoWrapper}>
                                <Text style={styles.textWidth}>status</Text>
                                <Text style={styles.bold}>: konsiyasi</Text>
                            </View>
                            <View style={styles.infoWrapper}>
                                <Text style={styles.textWidth}>sales</Text>
                                <Text style={styles.subTitle}>-</Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.tableContainer}>
                        <View style={styles.tableHeaderContainer}>
                            <Text style={styles.tableHeaderNo}>NO</Text>
                            <Text style={styles.tableheaderProduct}>
                                NAMA PRODUCT
                            </Text>
                            <Text style={styles.tableHeaderQty}>QTY</Text>
                            <Text style={styles.tableHeaderPrice}>HARGA</Text>
                            <Text style={styles.tableHeaderDiscount}>
                                DISKON
                            </Text>
                            <Text style={styles.tableHeaderTotalPrice}>
                                TOTAL
                            </Text>
                        </View>

                        {dataFaktur?.detail?.map(
                            (list: DetailFakturKonsinyasi, index: number) => {
                                return (
                                    <View
                                        style={styles.row}
                                        key={`row-data-${index}`}
                                    >
                                        <Text style={styles.tableHeaderNo}>
                                            {index + 1}
                                        </Text>
                                        <Text style={styles.tableheaderProduct}>
                                            {list.stok.nama}
                                        </Text>
                                        <Text style={styles.tableHeaderQty}>
                                            {list.jumlah}
                                        </Text>
                                        <Text style={styles.tableHeaderPrice}>
                                            {toRibuan(list.hargaSatuan)}
                                        </Text>
                                        <Text
                                            style={styles.tableHeaderDiscount}
                                        >
                                            {"-"}
                                        </Text>
                                        <Text
                                            style={styles.tableHeaderTotalPrice}
                                        >
                                            {toRibuan(
                                                list.jumlah *
                                                    Number(list.hargaSatuan),
                                            )}
                                        </Text>
                                    </View>
                                );
                            },
                        )}

                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                fontSize: 11,
                                textAlign: "right",
                                marginTop: "20px",
                                fontWeight: "bold",
                            }}
                        >
                            <Text
                                style={{
                                    flexBasis: "50%",
                                    width: "100%",
                                    fontWeight: "bold",
                                }}
                            >
                                SUBTOTAL
                            </Text>
                            <Text
                                style={{ flexBasis: "50%", fontWeight: "bold" }}
                            >
                                {"Rp "} {toRibuan(dataFaktur.total)}
                            </Text>
                        </View>

                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                fontSize: 11,
                                textAlign: "right",
                                marginTop: "20px",
                            }}
                        >
                            <Text
                                style={{
                                    flexBasis: "50%",
                                    width: "100%",
                                    fontWeight: "bold",
                                }}
                            >
                                TOTAL YANG HARUS DIBAYAR
                            </Text>
                            <Text
                                style={{ flexBasis: "50%", fontWeight: "bold" }}
                            >
                                {"Rp "} {toRibuan(dataFaktur.total)}
                            </Text>
                        </View>
                    </View>

                    <View style={styles.textCalculated}>
                        <Text style={styles.subTitle}>
                            TOTAL TERBILANG:{" "}
                            {Terbilang(dataFaktur.total).toUpperCase()} RUPIAH
                        </Text>
                    </View>

                    <View style={styles.noteWrapper}>
                        <Text style={styles.bold}>NOTE :</Text>
                        <View style={styles.note}>
                            <Text style={styles.subTitle}>
                                - DISARANKAN UNTUK TIDAK MENJUAL PRODUK KOKIKO
                                DI BAWAH HARGA RETAIL KOKIKO
                            </Text>
                            <Text style={styles.subTitle}>
                                - PENGECEKAN PRODUK SETIAP DUA MINGGU SEKALI
                            </Text>
                        </View>
                    </View>

                    <View style={styles.noteWrapper}>
                        <View style={styles.note}>
                            <Text style={styles.subTitle}>
                                - Pembayaran dianggap lunas apabila sudah
                                ditransfer ke rekening{" "}
                                <Text style={styles.bank}>
                                    PT Kikomart Indonesia, BCA 816-581-8080
                                </Text>
                            </Text>
                            <Text style={styles.subTitle}>
                                - PT Kiko Mart Indonesia tidak bertanggung jawab
                                apabila terjadi pembayaran di luar rekenening
                                tersebut
                            </Text>
                        </View>
                    </View>

                    <View style={styles.signContainer}>
                        <View style={styles.signWrapper}>
                            <Text style={styles.signTitle}>Hormat Kami,</Text>
                            <Text style={styles.signName}>
                                (......................................)
                            </Text>
                        </View>

                        <View style={styles.signWrapper}>
                            <Text style={styles.signTitle}>Mengetahui,</Text>
                            <Text style={styles.signName}>
                                (......................................)
                            </Text>
                        </View>

                        <View style={styles.signWrapper}>
                            <Text style={styles.signTitle}>Penerima,</Text>
                            <Text style={styles.signName}>
                                (......................................)
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default PrintFakturKonsinyasi;
