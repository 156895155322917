import { useQuery } from "react-query";
import { ParamsGetKonsinyi, GetKonsinyiResponse } from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetKonsinyi) => {
    const apiGetKonsinyi = queryString.stringifyUrl({
        url: "api/konsinyi",
        query: {
            size: params.size,
            page: params.page,
            search: `${params.search}`,
        },
    });
    const { data } = await axios.get<GetKonsinyiResponse>(apiGetKonsinyi);
    return data.data;
};

export default function useKonsinyi(params: ParamsGetKonsinyi) {
    return useQuery<GetKonsinyiResponse["data"]>(["konsinyi", params], () =>
        handleRequest(params),
    );
}
