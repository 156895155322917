import React, { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import {
    FakturKonsinyiValues,
    IOptions,
    ParamsGetSupplier,
    SelectedProductFakturKonsinyasi,
} from "../../../constants/types";
import axios from "../../../services/axios";
import Swal from "sweetalert2";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import BarangTerpilih from "../BarangTerpilih/BarangTerpilih";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import moment from "moment";
import { addDays } from "../../../utils/addDate";
import NumberFormat from "react-number-format";
import InputAdornment from "@mui/material/InputAdornment";
import useKonsinyi from "../../../services/queries/useKonsinyi";
import Autocomplete from "@mui/material/Autocomplete";
import formatHarga from "../../../utils/formatHarga";
import Divider from "@mui/material/Divider";
import toRupiahDesimal from "../../../utils/toRupiahDesimal";
import { useTransaksi } from "../../../context/transaksiContext";

registerLocale("id", id);
setDefaultLocale("id");

interface IDetailBarangStepProps {
    products: SelectedProductFakturKonsinyasi[];
    onBack: () => void;
    isOpenModalFakturKonsinyasi: boolean;
    closeModalFakturKonsinyasi: () => void;
    detailId: number;
    isPO?: boolean;
}

const DetailBarangStep = ({
    products,
    onBack,
    isOpenModalFakturKonsinyasi,
    closeModalFakturKonsinyasi,
    detailId,
    isPO,
}: IDetailBarangStepProps) => {
    const today = new Date();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { setFetchingItems } = useTransaksi();
    const initialParamsKonsinyi = {
        size: 300,
        page: 1,
        search: "",
        kategori: "",
    };
    const [paramsKonsinyi, setParamsKonsinyi] = useState<ParamsGetSupplier>(
        initialParamsKonsinyi,
    );
    const { data: konsinyi, isLoading: isLoadingKonsinyi } =
        useKonsinyi(paramsKonsinyi);
    const mappedKonsinyi = konsinyi?.content.map((li) => ({
        id: li.id,
        nama: li.nama,
    }));

    const initialValues: FakturKonsinyiValues = useMemo(
        () => ({
            ukmId: detailId,
            isKodeFaktur: false,
            kodeFaktur: "",
            tanggal: new Date(),
            jatuhTempo: new Date(),
            dp: 0,
            konsinyiId: undefined,
            details: products.map((li) => ({
                itemId: li.id,
                jumlahStok: 1,
                hargaBeli: Number(String(li.harga).replaceAll(",", ".")),
                keterangan: "",
                faktur: li.hpp[0],
                isTanggalExp: false,
                tanggalExp: addDays(7),
                total: Number(String(li.harga).replaceAll(",", ".")),
            })) ?? [
                {
                    itemId: 1,
                    jumlahStok: 1,
                    hargaBeli: 0,
                    keterangan: "",
                    faktur: {
                        id: 0,
                        nama: "Faktur",
                    },
                    isTanggalExp: false,
                    tanggalExp: null,
                    total: 0,
                },
            ],
        }),
        [detailId, products],
    );

    useEffect(
        () => console.log("initialValues", initialValues),
        [initialValues],
    );

    const methods = useForm<FakturKonsinyiValues>({
        // resolver: yupResolver(isPO ? schema2 : schema),
        defaultValues: initialValues,
    });

    const isKodeFaktur = methods.watch("isKodeFaktur");
    const watchDetail = methods.watch("details");
    const totalFaktur = watchDetail
        .map((li) => Number(String(li.total)))
        .reduce(
            (accumulator, currentValue) => accumulator + Number(currentValue),
            0,
        );

    useEffect(() => console.log("totalFaktur", totalFaktur), [totalFaktur]);

    const onSubmit = async (values: FakturKonsinyiValues) => {
        setIsButtonLoading(true);
        try {
            const body = {
                ukmId: values.ukmId,
                kodeFaktur: values.isKodeFaktur ? values.kodeFaktur : null,
                tanggal: values.tanggal
                    ? moment(values.tanggal).format("DD/MM/YYYY")
                    : null,
                jatuhTempo: values.jatuhTempo
                    ? moment(values.jatuhTempo).format("DD/MM/YYYY")
                    : null,
                dp: isPO ? Number(formatHarga(String(values.dp))) : undefined,
                konsinyiId: values.konsinyi
                    ? Number(values.konsinyi.id)
                    : undefined,
                isPO: isPO ? 1 : undefined,
                details: values.details.map((li) => ({
                    itemId: li.itemId,
                    jumlah: Number(li.jumlahStok),
                    hargaSatuan: Number(
                        String(li.hargaBeli).split(".").join(""),
                    ),
                    keterangan: li.keterangan,
                    tanggalExp: li.isTanggalExp
                        ? moment(li.tanggalExp).format("DD/MM/YYYY")
                        : undefined,
                    total: li.total,
                    fakturId: li.faktur?.id,
                })),
            };

            const { data } = await axios.post(`/api/faktur-konsinyasi`, body);
            if (data.code === 200) {
                Swal.fire({
                    title: isPO
                        ? "Pemesanan Konsinyasi dibuat"
                        : "Faktur konsinyasi dibuat",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                closeModalFakturKonsinyasi();
                setFetchingItems();
            }
            setIsButtonLoading(false);
        } catch (error) {
            setIsButtonLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        if (isOpenModalFakturKonsinyasi) {
            methods.reset(initialValues);
        }
    }, [initialValues, isOpenModalFakturKonsinyasi, methods]);

    return (
        <React.Fragment>
            <Grid container alignItems="start" spacing={1} marginBottom={1}>
                <Grid item xs={12} sm={6} md={5}>
                    <Typography fontWeight={500} component="div">
                        Tanggal Transaksi:
                    </Typography>
                    <Controller
                        name="tanggal"
                        control={methods.control}
                        render={({ field }) => (
                            <DatePicker
                                className="range-filter"
                                dateFormat="dd/MM/yyyy"
                                disabledKeyboardNavigation
                                onFocus={(e) => e.target.blur()}
                                startDate={field.value}
                                onChange={(update) => {
                                    field.onChange(update);
                                }}
                                minDate={today}
                                monthsShown={1}
                                customInput={
                                    <Stack
                                        display="flex"
                                        justifyContent="space-between"
                                        direction="row"
                                        width="100%"
                                        bgcolor="#FFFF"
                                        height="40px"
                                        sx={{
                                            border: "1px solid #cbcbcb",
                                            borderRadius: "4px",
                                            alignItems: "center",
                                            padding: 2,
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Typography>
                                            {field.value?.toLocaleString("id", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                            })}
                                        </Typography>
                                        <ArrowDropDown
                                            sx={{
                                                marginRight: "-9px",
                                            }}
                                        />
                                    </Stack>
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                    <Typography fontWeight={500} component="div">
                        Tanggal Jatuh Tempo:
                    </Typography>
                    <Controller
                        name="jatuhTempo"
                        control={methods.control}
                        render={({ field }) => (
                            <DatePicker
                                className="range-filter"
                                dateFormat="dd/MM/yyyy"
                                disabledKeyboardNavigation
                                onFocus={(e) => e.target.blur()}
                                startDate={field.value}
                                onChange={(update) => {
                                    field.onChange(update);
                                }}
                                minDate={today}
                                monthsShown={1}
                                customInput={
                                    <Stack
                                        display="flex"
                                        justifyContent="space-between"
                                        direction="row"
                                        width="100%"
                                        bgcolor="#FFFF"
                                        height="40px"
                                        sx={{
                                            border: "1px solid #cbcbcb",
                                            borderRadius: "4px",
                                            alignItems: "center",
                                            padding: 2,
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Typography>
                                            {field.value?.toLocaleString("id", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                            })}
                                        </Typography>
                                        <ArrowDropDown
                                            sx={{
                                                marginRight: "-9px",
                                            }}
                                        />
                                    </Stack>
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                    <Typography
                        fontWeight={500}
                        component="div"
                        data-testid="supplier-konsinyi"
                    >
                        Konsinyi:
                    </Typography>
                    <Controller
                        name="konsinyi"
                        control={methods.control}
                        rules={{
                            required: "Kolom wajib diisi",
                        }}
                        render={({ field, fieldState }) => (
                            <Autocomplete
                                disablePortal
                                loading={isLoadingKonsinyi}
                                options={mappedKonsinyi ?? []}
                                getOptionLabel={(option) => option.nama}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                size="small"
                                value={field.value}
                                onInputChange={(e) => {
                                    if (e) {
                                        const element =
                                            e.target as HTMLInputElement;
                                        setParamsKonsinyi((prev) => ({
                                            ...prev,
                                            search: element.value,
                                        }));
                                    } else {
                                        return;
                                    }
                                }}
                                onChange={(
                                    _event,
                                    newSupplier: IOptions | null,
                                ) => {
                                    field.onChange(newSupplier);
                                }}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Pilih Konsinyi"
                                        error={!!fieldState?.error}
                                        helperText={
                                            fieldState?.error &&
                                            "Pilih satu konsinyi"
                                        }
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
                {/* {!isPO && (
                    <Grid item xs={12} sm={6} md={5}>
                        <Typography fontWeight={500} component="div">
                            COA:
                        </Typography>
                        <Controller
                            name="coaBayar"
                            control={methods.control}
                            render={({ field, fieldState }) => (
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    loading={isLoadingCoa}
                                    options={mappedCoa ?? []}
                                    getOptionLabel={(option) => {
                                        return `(${option.noAkun}) - ${option.namaAkun}`;
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    value={field.value}
                                    size="small"
                                    onInputChange={(e) => {
                                        if (e) {
                                            const element =
                                                e.target as HTMLInputElement;
                                            setParamsCoa((prev) => ({
                                                ...prev,
                                                search: element.value,
                                            }));
                                        } else {
                                            return;
                                        }
                                    }}
                                    onChange={(
                                        _event,
                                        newPelanggan: {
                                            id: number;
                                            noAkun: string;
                                            namaAkun: string;
                                        } | null,
                                    ) => {
                                        field.onChange(newPelanggan);
                                    }}
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Pilih COA"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                "Pilih satu COA"
                                            }
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                )} */}
                {isPO && (
                    <Grid item xs={12} sm={6} md={5}>
                        <Typography fontWeight={500} component="div">
                            Uang Muka:
                        </Typography>
                        <Controller
                            name="dp"
                            control={methods.control}
                            render={({ field, fieldState }) => (
                                <NumberFormat
                                    customInput={TextField}
                                    placeholder="0"
                                    // margin="dense"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    error={!!fieldState?.error}
                                    helperText={
                                        fieldState?.error &&
                                        fieldState?.error.message
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>Rp.</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container alignItems="center">
                <Grid item xs={6} sm={3}>
                    <Typography>Beri kode faktur manual?</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Controller
                        name="isKodeFaktur"
                        control={methods.control}
                        render={({ field }) => (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={field.value}
                                            onChange={field.onChange}
                                            sx={{
                                                "& .MuiSwitch-thumb": {
                                                    color: "#eaeaea",
                                                },
                                            }}
                                        />
                                    }
                                    label={field.value ? "Ya" : "Tidak"}
                                    componentsProps={{
                                        typography: {
                                            variant: "body2",
                                        },
                                    }}
                                />
                            </FormGroup>
                        )}
                    />
                </Grid>
                {isKodeFaktur && (
                    <Grid item xs={12}>
                        <Controller
                            name="kodeFaktur"
                            control={methods.control}
                            render={({ field, fieldState }) => (
                                <TextField
                                    variant="outlined"
                                    placeholder="Kode Faktur"
                                    size="small"
                                    id="kodeFaktur"
                                    error={!!fieldState?.error}
                                    helperText={
                                        fieldState?.error &&
                                        fieldState?.error.message
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                )}
            </Grid>
            <Stack mt={2} mb={5} direction="column" spacing={2}>
                {products &&
                    products?.map((li, idx) => (
                        <FormProvider key={idx.toString()} {...methods}>
                            <BarangTerpilih
                                key={String(li.id)}
                                id={li.id}
                                nama={li.nama}
                                sku={li.sku}
                                idx={idx}
                                hargaJual={li.harga}
                                isPO={isPO}
                                hpp={li.hpp}
                            />
                        </FormProvider>
                    ))}
            </Stack>
            <Divider />
            <Box sx={{ paddingY: 3 }}>
                <Grid
                    container
                    spacing={2}
                    alignItems="flex-start"
                    marginBottom={2}
                >
                    <Grid item xs={6} sm={3}>
                        <Typography fontWeight={500} component="div">
                            Total Keseluruhan:
                        </Typography>
                        <Typography>
                            {toRupiahDesimal(Number(totalFaktur))}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box
                mt={4}
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr"
                justifyContent="flex-end"
                columnGap="16px"
            >
                <div />
                <Button onClick={onBack} variant="outlined">
                    Kembali
                </Button>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="contained"
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    Simpan
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
};

export default DetailBarangStep;
