import React from "react";
import { ContentGetItemResponse } from "../../constants/types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBack from "@mui/icons-material/ArrowBack";
import toRupiahDesimal from "../../utils/toRupiahDesimal";

interface IModalDetailHargaProps {
    open: boolean;
    onClose: () => void;
    data: ContentGetItemResponse;
}

const ModalDetailHarga = ({ open, onClose, data }: IModalDetailHargaProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Dialog
            maxWidth="sm"
            fullScreen={isPhoneScreen}
            fullWidth
            open={open}
            onClose={() => {}}
            scroll="body"
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={onClose}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Detail Harga ({data.namaBarang})
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <Stack spacing={1}>
                    {data.multipleHarga.map((li, idx) => (
                        <Typography key={idx.toString()}>
                            {li.keterangan} -{" "}
                            {toRupiahDesimal(Number(li.harga))}
                        </Typography>
                    ))}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalDetailHarga;
