import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
} from "@react-pdf/renderer";
import Terbilang from "terbilang-ts";
import PrintStrukA5Table from "./PrintStrukA5Table";
import moment from "moment";
import toRibuan from "../../utils/toRibuan";

Font.register({
    family: "Open-Sans",
    src: "https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf",
});

Font.register({
    family: "Open-Sans-Bold",
    src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#FFF",
        paddingTop: 8,
        paddingBottom: 8,
        paddingHorizontal: 24,
    },
    title: {
        fontSize: 14,
        fontFamily: "Open-Sans-Bold",
    },
    infoToko: {
        fontSize: 11,
        fontFamily: "Open-Sans",
    },
    infoTokoBold: {
        fontSize: 11,
        fontFamily: "Open-Sans-Bold",
    },
    container: {
        flexDirection: "row",
        borderBottomColor: "#000",
        backgroundColor: "#fff",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        textAlign: "center",
        fontSize: 11,
        fontFamily: "Open-Sans-Bold",
        flexGrow: 1,
    },
    no: {
        width: "10%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    namaBarang: {
        width: "50%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    qty: {
        width: "10%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    hargaSatuan: {
        width: "15%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    jumlah: {
        width: "15%",
    },
    divider: {
        marginVertical: 4,
        borderTop: "1px",
        color: "black",
        borderStyle: "dashed",
    },
    penutup: {
        textAlign: "center",
    },
});

interface ICart {
    id: number;
    namaBarang: string;
    sku?: string | undefined;
    qty: number;
    harga: number;
    jumlahStok?: number | undefined;
}

interface IPrintStrukA5Props {
    namaToko: string;
    gambarToko?: string;
    alamatToko: string;
    telpToko: string;
    total: number;
    kasir: string;
    sales: string | null;
    tanggalTransaksi: string;
    cart?: ICart[];
    diskon?: number;
    dpp?: number;
    pajak?: number;
    persenPajak?: number;
    tunai?: number;
    kembalian?: number;
    isPro: number;
    namaMetode?: string | null;
    belumLunas?: boolean;
    qr?: string | null;
    html?: any;
    keterangan?: string | null;
    noInvoice: string;
    subTotal: number;
    keteranganCustomAmount?: string | null;
    hargaCustomAmount?: string | null;
    keteranganOngkir?: string | null;
    hargaOngkir?: string | null;
    jatuhTempo: string | null;
    metodePelanggan: string | null;
}

const PrintStrukA5 = (props: IPrintStrukA5Props) => {
    return (
        <Document>
            <Page
                size={{ width: 8.5 * 72, height: 5.5 * 72 }}
                // orientation="landscape"
                // size="A5"
                style={styles.page}
            >
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                >
                    <View style={styles.title}>
                        <Text>PT. KIKO MART INDONESIA</Text>
                    </View>
                    {/* <Image
                        style={{
                            height: 70,
                            width: 70,
                        }}
                        src="/images/kiko-white.png"
                    /> */}
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                >
                    <View style={styles.infoToko}>
                        <Text>Ruko Wonodri Sendang Raya No. 15 D </Text>
                        <Text>Telp: {props.telpToko}</Text>
                        <Text>Email: cashier.kikomart@gmail.com</Text>
                    </View>
                    <View>
                        <Text style={styles.infoTokoBold}>
                            NO. INVOICE{"    "}:{"     "}
                            <Text style={styles.infoToko}>
                                {props.noInvoice}
                            </Text>
                        </Text>
                        <Text style={styles.infoTokoBold}>
                            TANGGAL{"          "}:{"     "}
                            <Text style={styles.infoToko}>
                                {props.tanggalTransaksi
                                    ? moment(props.tanggalTransaksi).format(
                                          "LL",
                                      )
                                    : "-"}
                            </Text>
                        </Text>
                    </View>
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 14,
                        fontFamily: "Open-Sans",
                    }}
                >
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <Text style={{ fontFamily: "Open-Sans-Bold" }}>
                            INVOICE
                        </Text>
                    </View>
                </View>
                <View
                    style={{
                        fontSize: 11,
                        fontFamily: "Open-Sans",
                        paddingHorizontal: 4,
                    }}
                >
                    <Text>PELANGGAN</Text>
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "flex-start",
                    }}
                >
                    <View
                        style={{
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "#000",
                            width: "50%",
                            paddingHorizontal: 4,
                        }}
                    >
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <View style={{ width: "25%" }}>
                                <Text
                                    style={{
                                        fontSize: 9,
                                        fontFamily: "Open-Sans",
                                    }}
                                >
                                    NAMA
                                </Text>
                            </View>
                            <View style={{ width: "75%" }}>
                                <Text
                                    style={{
                                        fontSize: 9,
                                        fontFamily: "Open-Sans",
                                    }}
                                >
                                    {props.metodePelanggan?.toUpperCase()}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View
                        style={{
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "#fff",
                            width: "5%",
                        }}
                    ></View>
                    <View
                        style={{
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "#000",
                            width: "50%",
                            paddingHorizontal: 4,
                        }}
                    >
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <View style={{ width: "40%" }}>
                                <Text
                                    style={{
                                        fontSize: 9,
                                        fontFamily: "Open-Sans",
                                    }}
                                >
                                    JATUH TEMPO
                                </Text>
                            </View>
                            <View style={{ width: "60%" }}>
                                <Text
                                    style={{
                                        fontSize: 9,
                                        fontFamily: "Open-Sans",
                                    }}
                                >
                                    {props.jatuhTempo}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
                <PrintStrukA5Table
                    cart={props.cart}
                    diskon={props.diskon}
                    dpp={props.dpp}
                    pajak={props.pajak}
                    persenPajak={props.persenPajak}
                    kembalian={props.kembalian}
                    tunai={props.tunai}
                    namaMetode={props.namaMetode}
                    total={props.total}
                    hargaCustomAmount={props.hargaCustomAmount}
                    hargaOngkir={props.hargaOngkir}
                />
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                        marginTop: 8,
                    }}
                >
                    {props.dpp ? (
                        <View
                            style={{
                                fontSize: 8,
                                fontFamily: "Open-Sans",
                            }}
                        >
                            <Text>DPP= {toRibuan(props.dpp)}</Text>
                        </View>
                    ) : null}
                    {props.pajak ? (
                        <View
                            style={{
                                fontSize: 8,
                                fontFamily: "Open-Sans",
                                marginLeft: 4,
                            }}
                        >
                            <Text>
                                PPN(
                                {props.persenPajak}%)= {toRibuan(props.pajak)}
                            </Text>
                        </View>
                    ) : null}
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                >
                    <View
                        style={{
                            marginTop: 8,
                            width: "50%",
                            borderWidth: 1,
                            borderColor: "#000",
                            padding: 4,
                        }}
                    >
                        <View
                            style={{
                                fontSize: 8,
                                fontFamily: "Open-Sans",
                            }}
                        >
                            <Text>TERBILANG</Text>
                        </View>
                        <View
                            style={{
                                marginTop: 8,
                                fontSize: 8,
                                fontFamily: "Open-Sans",
                            }}
                        >
                            <Text>
                                {Terbilang(
                                    Math.round(props.total),
                                ).toUpperCase()}{" "}
                                RUPIAH
                            </Text>
                        </View>
                    </View>
                    <View
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            fontSize: 10,
                            fontFamily: "Open-Sans",
                            marginTop: 8,
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: "Open-Sans-Bold",
                                alignSelf: "flex-end",
                            }}
                        >
                            PEMBAYARAN DAPAT DIBAYAR MELALUI
                        </Text>
                        <Text
                            style={{
                                textAlign: "right",
                                alignSelf: "flex-end",
                            }}
                        >
                            BCA 8165818080
                        </Text>
                        <Text
                            style={{
                                textAlign: "right",
                                alignSelf: "flex-end",
                            }}
                        >
                            PT. Kiko Mart Indonesia
                        </Text>
                    </View>
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 8,
                    }}
                >
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 4,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 11,
                                fontFamily: "Open-Sans",
                            }}
                        >
                            Penerima
                        </Text>
                        <Text
                            style={{
                                fontSize: 11,
                                fontFamily: "Open-Sans",
                                marginTop: 32,
                            }}
                        >
                            (......................................)
                        </Text>
                    </View>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 4,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 11,
                                fontFamily: "Open-Sans",
                            }}
                        >
                            Hormat Kami
                        </Text>
                        <Text
                            style={{
                                fontSize: 11,
                                fontFamily: "Open-Sans",
                                marginTop: 32,
                            }}
                        >
                            (......................................)
                        </Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default PrintStrukA5;
