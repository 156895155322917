import { useQuery } from "react-query";
import {
    ParamsGetFakturKonsinyasi,
    GetFakturKonsinyasiResponse,
} from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (params: ParamsGetFakturKonsinyasi) => {
    const apiGetFakturKonsinyasi = qs.stringifyUrl({
        url: "api/faktur-konsinyasi",
        query: {
            ukmId: params.ukmId,
            search: `${params.search}`,
            size: params.size,
            page: params.page,
            po: params.po,
            retur: params.retur,
            ap: params.ap,
            tanggalAwal: params.tanggalAwal,
            tanggalAkhir: params.tanggalAkhir,
            filterkotak: params.filterkotak ?? undefined,
        },
    });
    const { data } = await axios.get<GetFakturKonsinyasiResponse>(
        apiGetFakturKonsinyasi,
    );
    return data.data;
};

export default function useFakturKonsinyasi(params: ParamsGetFakturKonsinyasi) {
    return useQuery<GetFakturKonsinyasiResponse["data"]>(
        ["fakturKonsinyasi", params],
        () => handleRequest(params),
        {
            enabled: !!params?.ukmId,
        },
    );
}
