import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import ArrowBack from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import toRibuan from "../../utils/toRibuan";
import NoRowsImage from "../NoRowsImage/NoRowsImage";
import LoadingTabel from "../LoadingTabel/LoadingTabel";
import { ContentCopy } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import toRupiahDesimal from "../../utils/toRupiahDesimal";

interface Keterangan {
    jumlah: number;
    keterangan: string;
    tanggal: string;
    invoice: string;
}

interface DetailMutasi {
    namaBarang: string;
    sku: string;
    stokAwal: number | string;
    hargaBarang: number;
    masuk: number;
    keluar: number;
    stokAkhir: number | string;
}

interface IModalDetailMutasiProps {
    fullScreen: boolean;
    isOpenModalDetailMutasi: boolean;
    closeModalDetailMutasi: () => void;
    keteranganMasuk: Keterangan[];
    keteranganKeluar: Keterangan[];
    detailMutasi: DetailMutasi;
}

interface ICopyButton {
    copyText: string;
}

const CopyButton = ({ copyText }: ICopyButton) => {
    return (
        <Button
            onClick={async () => {
                await navigator.clipboard.writeText(copyText);
                Swal.fire({
                    title: "No. Invoice disalin ke papan klip",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }}
            size="small"
            color="buttongreen"
            disableElevation
            sx={{
                minWidth: "unset",
                padding: "8px",
                width: "32px",
                height: "32px",
            }}
        >
            <ContentCopy sx={{ width: "16px", height: "16px" }} />
        </Button>
    );
};

const ModalDetailMutasi = ({
    fullScreen,
    isOpenModalDetailMutasi,
    closeModalDetailMutasi,
    keteranganMasuk,
    keteranganKeluar,
    detailMutasi,
}: IModalDetailMutasiProps) => {
    const formattedMasuk = keteranganMasuk.map((li, idx) => ({
        id: idx,
        jumlah: toRibuan(li.jumlah),
        keterangan: li.keterangan ? li.keterangan : "Tidak ada keterangan",
        tanggal: li.tanggal,
    }));
    const formattedKeluar = keteranganKeluar.map((li, idx) => ({
        id: idx,
        jumlah: toRibuan(li.jumlah),
        keterangan: li.keterangan ? li.keterangan : "Tidak ada keterangan",
        tanggal: li.tanggal,
        invoice: li.invoice,
    }));

    const columnsMasuk: GridColDef[] = [
        {
            field: "tanggal",
            headerName: "Tanggal Mutasi",
            flex: 1,
            minWidth: 160,
        },
        {
            field: "jumlah",
            headerName: "Jumlah Barang",
            align: "right",
            flex: 1,
            minWidth: 160,
        },
        {
            field: "keterangan",
            headerName: "Keterangan",
            flex: 2,
            minWidth: 200,
        },
    ];
    const columnsKeluar: GridColDef[] = [
        {
            field: "tanggal",
            headerName: "Tanggal Mutasi",
            flex: 1,
            minWidth: 160,
        },
        {
            field: "jumlah",
            headerName: "Jumlah Barang",
            align: "right",
            flex: 1,
            minWidth: 160,
        },
        {
            field: "keterangan",
            headerName: "Keterangan",
            flex: 2,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Typography variant="body2">
                        {params.value}
                        {params.row.invoice && (
                            <CopyButton copyText={String(params.row.invoice)} />
                        )}
                    </Typography>
                );
            },
        },
    ];

    return (
        <Dialog
            maxWidth="md"
            fullScreen={fullScreen}
            fullWidth={true}
            open={isOpenModalDetailMutasi}
            onClose={closeModalDetailMutasi}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalDetailMutasi}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Keterangan Mutasi
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">Nama Barang</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>{detailMutasi.namaBarang}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">Kode Barang</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>{detailMutasi.sku}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">Stok Awal</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>
                                {detailMutasi.stokAwal === "Otomatis"
                                    ? detailMutasi.stokAwal
                                    : toRibuan(detailMutasi.stokAwal)}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">Harga</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>
                                {toRupiahDesimal(detailMutasi.hargaBarang)}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">Total Mutasi In</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>
                                {toRibuan(detailMutasi.masuk)}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">
                                Total Mutasi Out
                            </Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>
                                {toRibuan(detailMutasi.keluar)}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography width="40%">Stok Akhir</Typography>
                            <Typography width="3%">:</Typography>
                            <Typography>
                                {detailMutasi.stokAkhir === "Otomatis"
                                    ? detailMutasi.stokAkhir
                                    : toRibuan(detailMutasi.stokAkhir)}
                            </Typography>
                        </Stack>
                    </Stack>
                    {keteranganMasuk.length > 0 && (
                        <Stack spacing={1}>
                            <Typography fontWeight="bold">
                                Mutasi Masuk
                            </Typography>
                            <Box
                                sx={{
                                    height: 240,
                                    width: "100%",
                                    marginTop: 2,
                                    backgroundColor: "#ffffff",
                                    borderRadius: 1,
                                    "& .headerColumn": {
                                        backgroundColor: "#E4EEE8",
                                    },
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        fontWeight: "bold",
                                    },
                                    "& .MuiDataGrid-columnSeparator": {
                                        visibility: "hidden",
                                    },
                                    "& .MuiDataGrid-cell:focus-within": {
                                        outline: "none !important",
                                    },
                                    "& .MuiDataGrid-iconButtonContainer": {
                                        visibility: "visible",
                                        width: "0 !important",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        maxHeight: "fit-content !important",
                                        overflow: "auto",
                                        whiteSpace: "initial !important",
                                        lineHeight: "16px !important",
                                        display: "flex !important",
                                        alignItems: "center",
                                        paddingTop: "10px !important",
                                        paddingBottom: "10px !important",
                                    },
                                    "& .MuiDataGrid-viewport,.MuiDataGrid-row,.MuiDataGrid-renderingZone":
                                        {
                                            maxHeight: "fit-content !important",
                                        },
                                }}
                            >
                                <DataGrid
                                    // paginationMode="server"
                                    components={{
                                        ColumnUnsortedIcon: () => {
                                            return <ImportExportIcon />;
                                        },
                                        NoRowsOverlay: NoRowsImage,
                                        LoadingOverlay: LoadingTabel,
                                        // Pagination: CustomPagination,
                                    }}
                                    rows={formattedMasuk}
                                    columns={columnsMasuk}
                                    disableColumnMenu
                                    disableSelectionOnClick
                                    // rowsPerPageOptions={[10, 15, 20]}
                                    rowsPerPageOptions={[10]}
                                    pageSize={10}
                                    density="compact"
                                />
                            </Box>
                        </Stack>
                    )}
                    {keteranganKeluar.length > 0 && (
                        <Stack spacing={1}>
                            <Typography fontWeight="bold">
                                Mutasi Keluar
                            </Typography>
                            <Box
                                sx={{
                                    height: 240,
                                    width: "100%",
                                    marginTop: 2,
                                    backgroundColor: "#ffffff",
                                    borderRadius: 1,
                                    "& .headerColumn": {
                                        backgroundColor: "#E4EEE8",
                                    },
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        fontWeight: "bold",
                                    },
                                    "& .MuiDataGrid-columnSeparator": {
                                        visibility: "hidden",
                                    },
                                    "& .MuiDataGrid-cell:focus-within": {
                                        outline: "none !important",
                                    },
                                    "& .MuiDataGrid-iconButtonContainer": {
                                        visibility: "visible",
                                        width: "0 !important",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        maxHeight: "fit-content !important",
                                        overflow: "auto",
                                        whiteSpace: "initial !important",
                                        lineHeight: "16px !important",
                                        display: "flex !important",
                                        alignItems: "center",
                                        paddingTop: "10px !important",
                                        paddingBottom: "10px !important",
                                    },
                                    "& .MuiDataGrid-viewport,.MuiDataGrid-row,.MuiDataGrid-renderingZone":
                                        {
                                            maxHeight: "fit-content !important",
                                        },
                                }}
                            >
                                <DataGrid
                                    // paginationMode="server"\
                                    components={{
                                        ColumnUnsortedIcon: () => {
                                            return <ImportExportIcon />;
                                        },
                                        NoRowsOverlay: NoRowsImage,
                                        LoadingOverlay: LoadingTabel,
                                        // Pagination: CustomPagination,
                                    }}
                                    rows={formattedKeluar}
                                    columns={columnsKeluar}
                                    disableColumnMenu
                                    disableSelectionOnClick
                                    // rowsPerPageOptions={[10, 15, 20]}
                                    rowsPerPageOptions={[10]}
                                    pageSize={10}
                                    density="compact"
                                />
                            </Box>
                        </Stack>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalDetailMutasi;
