import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import {
    IOptions,
    ParamsGetItem,
    SelectedProductFakturKonsinyasi,
} from "../../../constants/types";
import useItem from "../../../services/queries/useItem";
import useKategori from "../../../services/queries/useKategori";
import toRupiahDesimal from "../../../utils/toRupiahDesimal";
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";
import NoRowsImage from "../../NoRowsImage/NoRowsImage";
import LoadingTabel from "../../LoadingTabel/LoadingTabel";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { useAuth } from "../../../context/authContext";
import ImportExport from "@mui/icons-material/ImportExport";

interface IPilihBarangStepProps {
    addProducts: (item: SelectedProductFakturKonsinyasi[]) => void;
    addDetailId: (id: number) => void;
}

const PilihBarangStep = ({
    addProducts,
    addDetailId,
}: IPilihBarangStepProps) => {
    const theme = useTheme();
    const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { ukmIdUser } = useAuth();

    const initialParamsStok: ParamsGetItem = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        kategori: "",
        size: 10,
        page: 1,
    };

    const initialParamsKategori: ParamsGetItem = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        kategori: "",
        size: 500,
        page: 1,
    };

    const [paramsStok, setParamsStok] = useState(initialParamsStok);
    const [paramsKategori, setParamsKategori] = useState(initialParamsKategori);
    const [search, setSearch] = useState<string>("");
    const [selectedKategori, setSelectedKategori] =
        React.useState<IOptions | null>(null);

    const { data: stok, isLoading: isLoadingStok } = useItem(paramsStok);
    const { data: kategori, isLoading: isLoadingKategori } =
        useKategori(paramsKategori);

    const [selectedProducts, setSelectedProducts] = useState<
        SelectedProductFakturKonsinyasi[]
    >([]);

    useEffect(() => console.log("paramsStok", paramsStok), [paramsStok]);

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleSearch = () => {
        setParamsStok((prev) => ({
            ...prev,
            search: search,
        }));
    };

    const handleOnPagination = (page: number) => {
        setParamsStok((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsStok((prev) => ({ ...prev, size }));
    };

    const isSelected = (id: number) => {
        const filteredProduct = selectedProducts.filter(
            (li) => li.id === id,
        ).length;
        if (filteredProduct > 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSelectedProducts = (
        id: number,
        nama: string,
        sku: string,
        harga: number,
        stok: number,
        hpp: {
            id: number;
            faktur: string;
            hargaBeli: number;
            jumlah: number;
        }[],
    ) => {
        if (isSelected(id)) {
            const newList = selectedProducts.filter((li) => li.id !== id);
            setSelectedProducts(newList);
        } else {
            setSelectedProducts((prev) => [
                ...prev,
                {
                    id,
                    nama,
                    sku,
                    harga,
                    stok,
                    hpp,
                },
            ]);
        }
    };

    const columns: GridColDef[] = [
        {
            field: "namaBarang",
            headerName: "Nama Barang",
            width: 300,
            flex: 2,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value}>
                        <span className="table-cell-trucate">
                            {params.value}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "kategori",
            headerName: "Kategori",
            headerAlign: "center",
            align: "center",
            // width: 150,
            flex: 1,
            minWidth: 125,
            valueGetter: (params) => params.row.kategori,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value.nama}>
                        <span className="table-cell-trucate">
                            {params.value.nama}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "multipleHarga",
            headerName: "Harga",
            headerAlign: "center",
            align: "center",
            // type: "number",
            // width: 150,
            flex: 1,
            minWidth: 125,
            valueGetter: (params) => params.value ?? 0,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={toRupiahDesimal(Number(params.value[0].harga))}
                    >
                        <span className="table-cell-trucate">
                            {toRupiahDesimal(Number(params.value[0].harga))}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "sku",
            headerName: "SKU",
            headerAlign: "center",
            align: "center",
            // type: "number",
            // width: 150,
            flex: 1,
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value}>
                        <span className="table-cell-trucate">
                            {params.value}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "stok",
            headerName: "Stok",
            headerAlign: "center",
            align: "center",
            // type: "number",
            // width: 150,
            flex: 1,
            minWidth: 100,
            valueGetter: (paramsStok) =>
                paramsStok.value === null ? "\u221e" : paramsStok.value,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={params.value === null ? "\u221e" : params.value}
                    >
                        <span className="table-cell-trucate">
                            {params.value === null ? "\u221e" : params.value}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            align: "center",
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <IconButton
                        disabled={
                            params.row.stok === null || params.row.stok <= 0
                        }
                        onClick={() => {
                            handleSelectedProducts(
                                params.row.id,
                                params.row.namaBarang,
                                params.row.sku,
                                params.row.multipleHarga[0].harga,
                                params.row.stok,
                                params.row.hpp,
                            );
                            // setSelectedProducts((prev) => [
                            //     ...prev,
                            //     {
                            //         id: params.row.id,
                            //         nama: params.row.namaBarang,
                            //         sku: params.row.sku,
                            //         harga: params.row.harga,
                            //         stok: params.row.stok,
                            //     },
                            // ]);
                        }}
                        sx={{
                            minWidth: "unset",
                            padding: "6px",
                            width: "fit-content",
                        }}
                        // startIcon={<EditIcon />}
                        size="small"
                        color="primary"
                    >
                        {isSelected(params.row.id) ? (
                            <CheckBoxIcon />
                        ) : (
                            <CheckBoxOutlineBlankIcon />
                        )}
                    </IconButton>
                );
            },
        },
    ];

    useEffect(() => {
        const timeoutId = setTimeout(
            () =>
                setParamsStok((prev) => ({
                    ...prev,
                    search: search,
                })),
            1000,
        );
        return () => clearTimeout(timeoutId);
    }, [search]);

    useEffect(() => {
        if (ukmIdUser) {
            setParamsStok((prev) => ({
                ...prev,
                ukmId: ukmIdUser[0],
            }));
            setParamsKategori((prev) => ({
                ...prev,
                ukmId: ukmIdUser[0],
            }));
            addDetailId(Number(ukmIdUser[0]));
            // setSelectedKategori(null);
        }
    }, [addDetailId, ukmIdUser]);

    useEffect(() => {
        if (selectedKategori !== null) {
            setParamsStok((prev) => ({
                ...prev,
                kategori: String(selectedKategori.id),
            }));
        } else {
            setParamsStok((prev) => ({
                ...prev,
                kategori: "",
            }));
        }
    }, [selectedKategori]);

    useEffect(() => {
        addProducts(selectedProducts ?? []);
    }, [addProducts, selectedProducts]);

    return (
        <Box>
            <Box
                display="grid"
                gridTemplateColumns={isTabletScreen ? "1fr" : "1fr 0.5fr"}
                columnGap="1em"
                rowGap={isTabletScreen ? "1em" : undefined}
                mb={2}
            >
                <TextField
                    placeholder="Cari barang..."
                    size="small"
                    value={search}
                    onChange={handleChangeSearch}
                    variant="outlined"
                    fullWidth
                    onKeyPress={(e) => {
                        const element = e.target as HTMLInputElement;
                        if (e.key === "Enter") {
                            setParamsStok((prev) => ({
                                ...prev,
                                search: element.value,
                            }));
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleSearch}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        style: {
                            backgroundColor: "white",
                        },
                    }}
                />
                <Autocomplete
                    disablePortal
                    loading={isLoadingKategori}
                    options={kategori?.content ?? []}
                    getOptionLabel={(option) => option.nama}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    size="small"
                    value={selectedKategori}
                    onChange={(_event, newKategori: IOptions | null) => {
                        setSelectedKategori(newKategori);
                    }}
                    // sx={{ width: 300 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Pilih kategori" />
                    )}
                />
            </Box>
            <Box
                sx={{
                    height: 454,
                    width: "100%",
                    "& .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-iconButtonContainer": {
                        visibility: "visible",
                        width: "0 !important",
                    },
                }}
            >
                <DataGrid
                    density="compact"
                    rows={stok?.content ?? []}
                    columns={columns}
                    components={{
                        ColumnUnsortedIcon: () => {
                            return <ImportExport />;
                        },
                        NoRowsOverlay: NoRowsImage,
                        LoadingOverlay: LoadingTabel,
                    }}
                    disableColumnMenu
                    disableSelectionOnClick
                    rowsPerPageOptions={[10]}
                    paginationMode="server"
                    loading={isLoadingStok || isLoadingKategori}
                    onPageSizeChange={handleOnSizeChange}
                    onPageChange={handleOnPagination}
                    rowCount={stok?.totalElements ?? 0}
                    page={paramsStok.page - 1}
                    pageSize={paramsStok.size}
                />
            </Box>
            <Box mt={2}>
                <Typography fontWeight="bold">Barang Terpilih:</Typography>
                <Typography>
                    {selectedProducts.map((li) => li.nama).join(", ")}
                </Typography>
            </Box>
        </Box>
    );
};

export default PilihBarangStep;
