import React, { useEffect, useMemo, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataFakturKonsinyasi } from "../../constants/types";
import { TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import axios from "../../services/axios";
import { useTransaksi } from "../../context/transaksiContext";

export type Values = {
    detail: {
        qty_withdrawn: number | string | null;
        qty_sold: number | string | null;
        stockName: string;
        id: number | null;
        fakturId: number | null;
        itemId: number | null;
        total_qty: number | null;
    }[];
};

export interface IModalConsignmentProcessProps {
    isOpen: boolean;
    onClose: () => void;
    data: DataFakturKonsinyasi;
}

export default function ModalConsignmentProcess({
    isOpen = false,
    onClose,
    data,
}: IModalConsignmentProcessProps) {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { setFetchingItems } = useTransaksi();

    // const createConsignmentProcess = useCreateConsignmentProcess();

    const initialValues: Values = useMemo(
        () => ({
            detail: [],
        }),
        [],
    );

    const { handleSubmit, watch, control, reset } = useForm<Values>({
        defaultValues: initialValues,
    });

    const { fields } = useFieldArray({
        control,
        name: "detail",
    });

    const onSubmit = async (values: Values) => {
        setIsButtonLoading(true);
        try {
            const details = values.detail.map((li) => {
                return {
                    id: li.id,
                    fakturId: li.fakturId,
                    itemId: li.itemId,
                    jumlahDitarik: li.qty_withdrawn,
                    jumlahTerjual: li.qty_sold,
                };
            });
            const payload = {
                konsinyasiId: data.id,
                details,
            };
            console.log("payload", payload);
            const res = await axios.post(`/api/proseskonsinyasi`, payload);
            if (res.data.code === 200) {
                Swal.fire({
                    title: "Proses konsinyasi berhasil disimpan",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                onClose();
                reset();
                setFetchingItems();
            }
            setIsButtonLoading(false);
        } catch (error) {
            console.error(error);
            setIsButtonLoading(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            if (data) {
                reset({
                    detail: data.detail?.map((li) => ({
                        qty_withdrawn: li.barangDitarik,
                        qty_sold: li.barangTerjual,
                        stockName: li.stok.nama,
                        id: li.id,
                        fakturId: li.faktur.id,
                        itemId: li.stok.id,
                        total_qty: li.jumlah,
                    })),
                });
            }
        }
    }, [data, isOpen, reset]);

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth
            open={isOpen}
            onClose={(_, reason) => {
                if (reason === "backdropClick") return;
                reset({});
                onClose();
            }}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={onClose}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Proses Konsinyasi
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                {fields.map((field, idx) => {
                    if (field.qty_sold !== undefined) {
                        return (
                            <Stack key={field.id} mb={6} gap={0.5}>
                                <Stack>
                                    <Typography fontWeight={500}>
                                        {idx + 1}. {field.stockName}
                                    </Typography>
                                    <Typography variant="body2">
                                        Jumlah dikonsinyasi: {field.total_qty}
                                    </Typography>
                                </Stack>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography fontWeight={500}>
                                            Jumlah ditarik:
                                        </Typography>
                                        <Controller
                                            name={`detail.${idx}.qty_withdrawn`}
                                            control={control}
                                            rules={{
                                                required: "Kolom wajib diisi",
                                                min: {
                                                    value: 0,
                                                    message: "Minimal 0",
                                                },
                                                max: {
                                                    value:
                                                        Number(
                                                            watch(
                                                                `detail.${idx}.total_qty`,
                                                            ),
                                                        ) -
                                                        Number(
                                                            watch(
                                                                `detail.${idx}.qty_sold`,
                                                            ),
                                                        ),
                                                    message: `Total barang ditarik &
                                                    terjual tidak boleh lebih
                                                    dari ${Number(
                                                        watch(
                                                            `detail.${idx}.total_qty`,
                                                        ),
                                                    )}`,
                                                },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <NumberFormat
                                                    customInput={TextField}
                                                    variant="outlined"
                                                    placeholder="0"
                                                    size="small"
                                                    fullWidth
                                                    id={`detail.${idx}.qty_withdrawn`}
                                                    error={!!fieldState?.error}
                                                    helperText={
                                                        fieldState?.error
                                                            ? fieldState?.error
                                                                  .message
                                                            : " "
                                                    }
                                                    {...field}
                                                    onChange={(event: any) => {
                                                        field.onChange(
                                                            Number(
                                                                event.target
                                                                    .value,
                                                            ),
                                                        );
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography fontWeight={500}>
                                            Jumlah terjual:
                                        </Typography>
                                        <Controller
                                            name={`detail.${idx}.qty_sold`}
                                            control={control}
                                            rules={{
                                                required: "Kolom wajib diisi",
                                                min: {
                                                    value: 0,
                                                    message: "Minimal 0",
                                                },
                                                max: {
                                                    value:
                                                        Number(
                                                            watch(
                                                                `detail.${idx}.total_qty`,
                                                            ),
                                                        ) -
                                                        Number(
                                                            watch(
                                                                `detail.${idx}.qty_withdrawn`,
                                                            ),
                                                        ),
                                                    message: `Total barang ditarik &
                                                    terjual tidak boleh lebih
                                                    dari ${Number(
                                                        watch(
                                                            `detail.${idx}.total_qty`,
                                                        ),
                                                    )}`,
                                                },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <NumberFormat
                                                    customInput={TextField}
                                                    variant="outlined"
                                                    placeholder="0"
                                                    size="small"
                                                    fullWidth
                                                    id={`detail.${idx}.qty_sold`}
                                                    error={!!fieldState?.error}
                                                    helperText={
                                                        fieldState?.error
                                                            ? fieldState?.error
                                                                  .message
                                                            : " "
                                                    }
                                                    {...field}
                                                    onChange={(event: any) => {
                                                        field.onChange(
                                                            Number(
                                                                event.target
                                                                    .value,
                                                            ),
                                                        );
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                        );
                    }
                })}
                <Grid container justifyContent="flex-end" mt={2}>
                    <LoadingButton
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                        loading={isButtonLoading}
                        loadingPosition="center"
                    >
                        Simpan
                    </LoadingButton>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
