import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
    Image,
} from "@react-pdf/renderer";
import Terbilang from "terbilang-ts";
import PrintStrukA4Table from "./PrintStrukA4Table";
import toRibuan from "../../utils/toRibuan";

Font.register({
    family: "Open-Sans",
    src: "https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf",
});

Font.register({
    family: "Open-Sans-Bold",
    src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#FFF",
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 18,
        fontFamily: "Open-Sans-Bold",
    },
    infoToko: {
        fontSize: 11,
        fontFamily: "Open-Sans",
    },
    infoTokoBold: {
        fontSize: 11,
        fontFamily: "Open-Sans-Bold",
    },
    container: {
        flexDirection: "row",
        borderBottomColor: "#000",
        backgroundColor: "#fff",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        textAlign: "center",
        fontSize: 11,
        fontFamily: "Open-Sans-Bold",
        flexGrow: 1,
    },
    no: {
        width: "10%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    namaBarang: {
        width: "50%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    qty: {
        width: "10%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    hargaSatuan: {
        width: "15%",
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    jumlah: {
        width: "15%",
    },
    divider: {
        marginVertical: 4,
        borderTop: "1px",
        color: "black",
        borderStyle: "dashed",
    },
    penutup: {
        textAlign: "center",
    },
});

interface ICart {
    id: number;
    namaBarang: string;
    sku?: string | undefined;
    qty: number;
    harga: number;
    jumlahStok?: number | undefined;
}

interface IPrintStrukA4Props {
    namaToko: string;
    gambarToko?: string;
    alamatToko: string;
    telpToko: string;
    total: number;
    kasir: string;
    sales: string | null;
    tanggalTransaksi: string;
    cart?: ICart[];
    diskon?: number;
    dpp?: number;
    pajak?: number;
    persenPajak?: number;
    tunai?: number;
    kembalian?: number;
    isPro: number;
    namaMetode?: string | null;
    belumLunas?: boolean;
    qr?: string | null;
    html?: any;
    keterangan?: string | null;
    noInvoice: string;
    subTotal: number;
    keteranganCustomAmount?: string | null;
    hargaCustomAmount?: string | null;
    keteranganOngkir?: string | null;
    hargaOngkir?: string | null;
    jatuhTempo: string | null;
    metodePelanggan: string | null;
}

const PrintStrukA4 = (props: IPrintStrukA4Props) => {
    return (
        <Document>
            <Page
                // size={{ width: "20cm", height: "16.5cm" }}
                size="A4"
                style={styles.page}
            >
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                >
                    <View style={styles.title}>
                        <Text>PT. KIKO MART INDONESIA</Text>
                    </View>
                    <Image
                        style={{
                            height: 70,
                            width: 70,
                        }}
                        src="/images/kiko-white.png"
                    />
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        marginTop: 12,
                        paddingRight: 32,
                    }}
                >
                    <View style={styles.infoToko}>
                        <Text>{props.alamatToko}</Text>
                        <Text>Telp: {props.telpToko}</Text>
                        <Text>Telp: 0811-2933-188</Text>
                        <Text>Email: cashier.kikomart@gmail.com</Text>
                    </View>
                    <View>
                        <Text style={styles.infoTokoBold}>
                            NO. INVOICE{"    "}:{"     "}
                            <Text style={styles.infoToko}>
                                {props.noInvoice}
                            </Text>
                        </Text>
                        <Text style={styles.infoTokoBold}>
                            TANGGAL{"          "}:{"     "}
                            <Text style={styles.infoToko}>
                                {props.tanggalTransaksi}
                            </Text>
                        </Text>
                    </View>
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 24,
                        fontSize: 14,
                        fontFamily: "Open-Sans",
                    }}
                >
                    <View>
                        <Text>
                            ---------------------------------------------------
                        </Text>
                    </View>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingHorizontal: 8,
                            textAlign: "center",
                        }}
                    >
                        <Text>INVOICE</Text>
                    </View>
                    <View>
                        <Text>
                            ---------------------------------------------------
                        </Text>
                    </View>
                </View>
                <View
                    style={{
                        marginTop: 24,
                        fontSize: 11,
                        fontFamily: "Open-Sans",
                        paddingHorizontal: 4,
                    }}
                >
                    <Text>PELANGGAN</Text>
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "flex-start",
                    }}
                >
                    <View
                        style={{
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "#000",
                            width: "50%",
                            paddingHorizontal: 4,
                        }}
                    >
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <View style={{ width: "25%" }}>
                                <Text
                                    style={{
                                        fontSize: 9,
                                        fontFamily: "Open-Sans",
                                    }}
                                >
                                    NAMA
                                </Text>
                            </View>
                            <View style={{ width: "75%" }}>
                                <Text
                                    style={{
                                        fontSize: 9,
                                        fontFamily: "Open-Sans",
                                    }}
                                >
                                    {props.metodePelanggan?.toUpperCase()}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View
                        style={{
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "#fff",
                            width: "5%",
                        }}
                    ></View>
                    <View
                        style={{
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "#000",
                            width: "50%",
                            paddingHorizontal: 4,
                        }}
                    >
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <View style={{ width: "40%" }}>
                                <Text
                                    style={{
                                        fontSize: 9,
                                        fontFamily: "Open-Sans",
                                    }}
                                >
                                    JATUH TEMPO
                                </Text>
                            </View>
                            <View style={{ width: "60%" }}>
                                <Text
                                    style={{
                                        fontSize: 9,
                                        fontFamily: "Open-Sans",
                                    }}
                                >
                                    {props.jatuhTempo}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
                <PrintStrukA4Table
                    cart={props.cart}
                    diskon={props.diskon}
                    dpp={props.dpp}
                    pajak={props.pajak}
                    persenPajak={props.persenPajak}
                    kembalian={props.kembalian}
                    tunai={props.tunai}
                    namaMetode={props.namaMetode}
                    total={props.total}
                    hargaCustomAmount={props.hargaCustomAmount}
                    hargaOngkir={props.hargaOngkir}
                />
                {props.dpp && props.pajak ? (
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "flex-start",
                        }}
                    >
                        <View
                            style={{
                                marginTop: 8,
                                fontSize: 8,
                                fontFamily: "Open-Sans",
                            }}
                        >
                            <Text>
                                DPP= {toRibuan(props.dpp)}
                                {"   "}PPN(
                                {props.persenPajak}%)= {toRibuan(props.pajak)}
                            </Text>
                        </View>
                    </View>
                ) : null}
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                >
                    <View
                        style={{
                            marginTop: 24,
                            width: "50%",
                            borderWidth: 1,
                            borderColor: "#000",
                            padding: 4,
                        }}
                    >
                        <View
                            style={{
                                fontSize: 8,
                                fontFamily: "Open-Sans",
                            }}
                        >
                            <Text>TERBILANG</Text>
                        </View>
                        <View
                            style={{
                                marginTop: 8,
                                fontSize: 8,
                                fontFamily: "Open-Sans",
                            }}
                        >
                            <Text>
                                {Terbilang(
                                    Math.round(props.total),
                                ).toUpperCase()}{" "}
                                RUPIAH
                            </Text>
                        </View>
                    </View>
                    <View
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            fontSize: 10,
                            fontFamily: "Open-Sans",
                            marginTop: 24,
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: "Open-Sans-Bold",
                                alignSelf: "flex-end",
                            }}
                        >
                            PEMBAYARAN DAPAT DIBAYAR MELALUI
                        </Text>
                        <Text
                            style={{
                                textAlign: "right",
                                alignSelf: "flex-end",
                            }}
                        >
                            BCA 8165818080
                        </Text>
                        <Text
                            style={{
                                textAlign: "right",
                                alignSelf: "flex-end",
                            }}
                        >
                            PT. Kiko Mart Indonesia
                        </Text>
                    </View>
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 36,
                    }}
                >
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 4,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 11,
                                fontFamily: "Open-Sans",
                            }}
                        >
                            Penerima
                        </Text>
                        <Text
                            style={{
                                fontSize: 11,
                                fontFamily: "Open-Sans",
                                marginTop: 62,
                            }}
                        >
                            (......................................)
                        </Text>
                    </View>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 4,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 11,
                                fontFamily: "Open-Sans",
                            }}
                        >
                            Hormat Kami
                        </Text>
                        <Text
                            style={{
                                fontSize: 11,
                                fontFamily: "Open-Sans",
                                marginTop: 62,
                            }}
                        >
                            (......................................)
                        </Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default PrintStrukA4;
