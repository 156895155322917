export const subtractDays = (numOfDays: number, date = new Date()) => {
    date.setDate(date.getDate() - numOfDays);

    return date;
};

export const subtractMonths = (numOfMonths: number, date = new Date()) => {
    date.setMonth(date.getMonth() - numOfMonths);

    return date;
};

export const subtractYears = (numOfYears: number, date = new Date()) => {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
};
