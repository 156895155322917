import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Switch,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useMediaQuery,
    Autocomplete,
    Link,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import axios from "../../services/axios";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import useKategori from "../../services/queries/useKategori";
import useIncrementSKU from "../../services/queries/useIncrementSKU";
import {
    AddItemBody,
    AddItemResponse,
    SelectedProductBundling,
    ParamsGetKategori,
    ParamsGetIncrementSKU,
    ErrorFieldResponse,
    IOptions,
    ParamsGetSupplier,
    ParamsGetCoa,
} from "../../constants/types";
import LogoUlo from "../LogoUlo/LogoUlo";
import PilihItemStep from "./PilihItemStep/PilihItemStep";
import DetailItemStep from "./DetailItemStep/DetailItemStep";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import defaultAxios, { AxiosError } from "axios";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import { useStokModal } from "../../context/stokModalContext";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import useLayanan from "../../services/queries/useLayanan";
import toRupiah from "../../utils/toRupiah";
import { useMutasiStokModal } from "../../context/mutasiStokModalContext";
import useSupplier from "../../services/queries/useSupplier";
import useCoa from "../../services/queries/useCoa";
import formatHarga from "../../utils/formatHarga";
import { tipeOptions } from "../../constants/common";

interface IModalStokProps {
    refetchItem: () => void;
    refetchKategori: () => void;
}

// interface IValuesStok {
//     id: number | undefined;
//     nama: string;
//     harga: string;
//     jumlah_stok?: number | null;
//     kategori?: string | number | null;
//     sku: string;
//     kategoriBaru?: string | null;
//     deskripsi?: string;
//     gambar?: File;
// }

enum SectionTambah {
    Satuan = "SATUAN",
    Bundle = "BUNDLE",
    Free = "FREE",
}

const schema = yup.object({
    isActiveStok: yup.boolean(),
    isKategoriBaru: yup.boolean(),
    namaBarang: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama barang terlalu panjang"),
    deskripsiBarang: yup
        .string()
        .nullable()
        .max(500, "Deskripsi terlalu panjang"),
    // harga: yup.string().required("Kolom wajib diisi"),
    kategoriId: yup
        .object()
        .shape({
            id: yup.string(),
            nama: yup.string(),
        })
        .when("isKategoriBaru", {
            is: false,
            then: yup
                .object()
                .shape({
                    id: yup.string().required("Kolom wajib diisi"),
                    nama: yup.string().required("Kolom wajib diisi"),
                })
                .required("Kolom wajib diisi"),
        }),
    // kategoriId: yup.string().when("isKategoriBaru", {
    //     is: false,
    //     then: yup.string().required("Kolom wajib diisi"),
    // }),
    kategoriBaru: yup.string().when("isKategoriBaru", {
        is: true,
        then: yup
            .string()
            .required("Kolom wajib diisi")
            .max(50, "Kategori baru terlalu panjang"),
    }),
    sku: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "SKU terlalu panjang"),
    jumlahStok: yup
        .string()
        .nullable()
        .when("isActiveStok", {
            is: true,
            then: yup.string().required("Kolom wajib diisi"),
        }),
    gambar: yup.mixed(),
    // .test("fileType", "Gambar harus berformat jpg/jpeg/png", (value) =>
    //     ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
    // ),
    favorite: yup.boolean().required("Kolom wajib diisi"),
    isHPP: yup.boolean(),
    hpp: yup
        .string()
        .nullable()
        .when("isHPP", {
            is: true,
            then: yup.string().required("Kolom wajib diisi"),
        }),
    multipleHarga: yup.array().of(
        yup.object().shape({
            harga: yup.string().required("Kolom wajib diisi"),
            keterangan: yup.string().nullable(),
        }),
    ),
    supplier: yup
        .object()
        .shape({
            id: yup.string(),
            nama: yup.string(),
        })
        .when("isHPP", {
            is: true,
            then: yup
                .object()
                .shape({
                    id: yup.string().required("Kolom wajib diisi"),
                    nama: yup.string().required("Kolom wajib diisi"),
                })
                .required("Kolom wajib diisi"),
        }),
    bayarAP: yup
        .string()
        .nullable()
        .when("isHPP", {
            is: true,
            then: yup.string().required("Kolom wajib diisi"),
        }),
    dpp: yup
        .string()
        .nullable()
        .when("isHPP", {
            is: true,
            then: yup.string().required("Kolom wajib diisi"),
        }),
    ppn: yup
        .string()
        .nullable()
        .when("isHPP", {
            is: true,
            then: yup.string().required("Kolom wajib diisi"),
        }),
    coaBayar: yup
        .object()
        .shape({
            id: yup.string(),
            noAkun: yup.string(),
            namaAkun: yup.string(),
        })

        .when("isHPP", {
            is: true,
            then: yup
                .object()
                .shape({
                    id: yup.string().required("Kolom wajib diisi"),
                    noAkun: yup.string().required("Kolom wajib diisi"),
                    namaAkun: yup.string().required("Kolom wajib diisi"),
                })
                .required("Kolom wajib diisi"),
        }),
    tipe: yup.string().required("Kolom wajib diisi"),
});

const ModalStok = ({ refetchItem, refetchKategori }: IModalStokProps) => {
    const moment = require("moment");
    const orderId = useMemo(() => moment().format("YYMMDDHHmmss"), [moment]);
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { isOpenModal, closeModal, dataStok } = useStokModal();
    const { openModalMutasiStok } = useMutasiStokModal();
    const { ukmIdUser } = useAuth();
    const initialParams = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 500,
        page: 1,
        search: "",
    };
    const [paramsKategori] = useState<ParamsGetKategori>(initialParams);
    const { data: dataKategori } = useKategori(paramsKategori);
    const initialParamsIncrementSKU = {
        ukmId: ukmIdUser && ukmIdUser[0],
    };
    const [paramsIncrementSKU] = useState<ParamsGetIncrementSKU>(
        initialParamsIncrementSKU,
    );
    const { data: incrementSKU, refetch: refetchSKU } =
        useIncrementSKU(paramsIncrementSKU);
    const [section, setSection] = useState<SectionTambah>(SectionTambah.Satuan);
    const [activeStep, setActiveStep] = useState(0);
    const [products, setProducts] = useState<SelectedProductBundling[]>([]);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { setFetchingItems } = useTransaksi();
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const { data: user } = useUser();
    const { data: dataLayanan, isLoading: isLoadingLayanan } = useLayanan();
    const [selectedLayanan, setSelectedLayanan] = useState<IOptions | null>(
        null,
    );

    const initialParamsSupplier = {
        size: 300,
        page: 1,
        search: "",
    };
    const [paramsSupplier, setParamsSupplier] =
        React.useState<ParamsGetSupplier>(initialParamsSupplier);

    const { data: supplier, isLoading: isLoadingSupplier } =
        useSupplier(paramsSupplier);

    const mappedSupplier = supplier?.content.map((li) => ({
        id: li.id,
        nama: li.nama,
    }));

    const initialParamsCoa = {
        size: 300,
        page: 1,
        search: "",
        kategori: "",
    };
    const [paramsCoa, setParamsCoa] = useState<ParamsGetCoa>(initialParamsCoa);
    const { data: coa, isLoading: isLoadingCoa } = useCoa(paramsCoa);
    const mappedCoa = coa?.content
        .filter((c) => c.header !== 1)
        .map((li) => ({
            id: li.id,
            noAkun: li.noAkun,
            namaAkun: li.namaAkun,
        }));

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        // if (activeStep === 1) {
        //     setProducts([]);
        // }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const addProducts = (item: SelectedProductBundling[]) => {
        setProducts(item);
    };

    const resetBundlingSteps = () => {
        setActiveStep(0);
        setProducts([]);
    };

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const initialValues = useMemo(
        () => ({
            ukmId: ukmIdUser && ukmIdUser[0],
            namaBarang: "",
            deskripsiBarang: "",
            kategoriId: undefined,
            kategoriBaru: "",
            harga: undefined,
            jumlahStok: 1,
            sku: "",
            gambar: null,
            isActiveStok: true,
            isKategoriBaru: false,
            favorite: false,
            multipleHarga: [
                { harga: "", keterangan: "Harga Retail" },
                { harga: "", keterangan: "Harga Grosir" },
                { harga: "", keterangan: "Harga E-Commerce" },
                { harga: "", keterangan: "Harga Promo" },
                { harga: "", keterangan: "Harga Grabmart" },
            ],
            tipe: "Pieces",
        }),
        [ukmIdUser],
    );

    const steps = [
        {
            label: "Pilih produk",
            description: `Anda dapat memilih produk lebih dari satu untuk dijadikan satu paket penjualan.`,
        },
        {
            label: "Atur deskripsi dan harga paket",
            description: "Berikan nama dan atur harga paket penjualan Anda.",
        },
    ];

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setValue,
        setError,
        clearErrors,
        watch,
    } = useForm<AddItemBody>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const isKategoriBaru = watch("isKategoriBaru");
    const isActiveStok = watch("isActiveStok");
    const isHPP = watch("isHPP");
    const watchSKU = watch("sku");
    const watchHPP = watch("hpp");
    const watchJumlahStok = watch("jumlahStok");
    const watchDpp = watch("dpp");

    const stokKaliHarga =
        Number(formatHarga(String(watchJumlahStok))) *
        Number(formatHarga(String(watchHPP)));

    useEffect(() => {
        if (watchDpp) {
            const hpp = Number(formatHarga(String(watchDpp)));
            const ppn = hpp * (11 / 100);
            setValue("ppn", ppn);
        }
    }, [setValue, watchDpp, watchHPP]);

    const generateSKU = () =>
        watch("namaBarang") === ""
            ? ""
            : watch("namaBarang")
                  .toUpperCase()
                  .replace(" ", "")
                  .substring(0, 4) +
              "-" +
              incrementSKU?.urutanke;

    const onSubmit = async (values: AddItemBody) => {
        const formattedHarga = Number(formatHarga(String(values.harga)));
        const formattedHargaBeli = formatHarga(String(values.hpp));
        const formattedBayarAP = formatHarga(String(values.bayarAP));
        const formattedDpp = formatHarga(String(values.dpp));
        const formattedPpn = formatHarga(String(values.ppn));
        setIsButtonLoading(true);
        if (dataStok) {
            try {
                const formData = new FormData();
                formData.append("ukmId", String(ukmIdUser && ukmIdUser[0]));
                formData.append("namaBarang", values.namaBarang);
                formData.append(
                    "deskripsiBarang",
                    values.deskripsiBarang === null
                        ? ""
                        : values.deskripsiBarang,
                );
                formData.append("sku", values.sku);
                if (values.gambar) {
                    formData.append(
                        "gambar",
                        values.gambar ? (values.gambar as any) : "",
                    );
                }
                formData.append(
                    "kategoriBaru",
                    values.isKategoriBaru === true ? values.kategoriBaru : "",
                );
                formData.append(
                    "kategoriId",
                    values.isKategoriBaru === true
                        ? ""
                        : String(values.kategoriId.id),
                );
                formData.append(
                    "isActiveStok",
                    values.isActiveStok === true
                        ? "1"
                        : values.isActiveStok === false
                        ? "0"
                        : "0",
                );
                formData.append(
                    "jumlahStok",
                    values.isActiveStok === true
                        ? String(values.jumlahStok)
                        : values.isActiveStok === false
                        ? ""
                        : "",
                );
                formData.append(
                    "favorite",
                    values.favorite === true
                        ? "1"
                        : values.favorite === false
                        ? "0"
                        : "0",
                );
                for (let i = 0; i < values.multipleHarga.length; i += 1) {
                    formData.append(
                        `harga[${i}][harga]`,
                        formatHarga(String(values.multipleHarga[i].harga)),
                    );
                    formData.append(
                        `harga[${i}][keterangan]`,
                        String(values.multipleHarga[i].keterangan),
                    );
                }
                formData.append("tipe", values.tipe ?? "");
                formData.append("_method", "put");

                const { data } = await axios.post<AddItemResponse>(
                    `/api/item/${values.id}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    Swal.fire({
                        title: "Barang telah diubah",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    setFetchingItems();
                    refetchItem();
                    refetchKategori();
                    closeModal();
                }
                setIsButtonLoading(false);
            } catch (error) {
                if (defaultAxios.isAxiosError(error)) {
                    const serverError = error as AxiosError<
                        ErrorFieldResponse | undefined
                    >;
                    if (serverError && serverError?.response) {
                        const fieldError = serverError?.response?.data;
                        if (fieldError?.errors) {
                            Object.keys(fieldError.errors).forEach((key) => {
                                const errorMessage = fieldError.errors[key];
                                setError(key as keyof typeof initialValues, {
                                    type: "manual",
                                    message: errorMessage[0],
                                });
                            });
                        }
                    }
                }
                console.error(error);
                setIsButtonLoading(false);
            }
        } else {
            try {
                const formData = new FormData();
                formData.append("ukmId", String(values.ukmId));
                formData.append("namaBarang", values.namaBarang);
                formData.append("deskripsiBarang", values.deskripsiBarang);
                formData.append("sku", values.sku);
                if (values.gambar) {
                    formData.append(
                        "gambar",
                        values.gambar ? (values.gambar as any) : "",
                    );
                }
                formData.append(
                    "kategoriBaru",
                    values.isKategoriBaru === true ? values.kategoriBaru : "",
                );
                formData.append(
                    "kategoriId",
                    values.isKategoriBaru === true
                        ? ""
                        : String(values.kategoriId.id),
                );
                formData.append("harga", String(formattedHarga));
                formData.append(
                    "isActiveStok",
                    values.isActiveStok === true
                        ? "1"
                        : values.isActiveStok === false
                        ? "0"
                        : "0",
                );
                formData.append(
                    "jumlahStok",
                    values.isActiveStok === true
                        ? String(values.jumlahStok)
                        : values.isActiveStok === false
                        ? ""
                        : "",
                );
                formData.append(
                    "favorite",
                    values.favorite === true
                        ? "1"
                        : values.favorite === false
                        ? "0"
                        : "0",
                );
                formData.append(
                    "hargaBeli",
                    values.isHPP === true
                        ? formattedHargaBeli
                        : values.isHPP === false
                        ? "0"
                        : "0",
                );
                for (let i = 0; i < values.multipleHarga.length; i += 1) {
                    formData.append(
                        `harga[${i}][harga]`,
                        formatHarga(String(values.multipleHarga[i].harga)),
                    );
                    formData.append(
                        `harga[${i}][keterangan]`,
                        String(values.multipleHarga[i].keterangan),
                    );
                }
                if (values.supplier?.id) {
                    formData.append("suplier", String(values.supplier?.id));
                }
                if (values.bayarAP) {
                    formData.append("bayarAP", formattedBayarAP);
                }
                if (values.dpp) {
                    formData.append("dpp", formattedDpp);
                }
                if (values.dpp) {
                    formData.append("ppn", formattedPpn);
                }
                if (values.coaBayar?.id) {
                    formData.append("coaBayar", String(values.coaBayar?.id));
                }
                formData.append("tipe", values.tipe ?? "");

                const { data } = await axios.post<AddItemResponse>(
                    `/api/item`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    Swal.fire({
                        title: values.namaBarang + " telah ditambahkan",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    setFetchingItems();
                    refetchItem();
                    refetchKategori();
                    closeModal();
                }
                setIsButtonLoading(false);
            } catch (error) {
                if (defaultAxios.isAxiosError(error)) {
                    const serverError = error as AxiosError<
                        ErrorFieldResponse | undefined
                    >;
                    if (serverError && serverError?.response) {
                        const fieldError = serverError?.response?.data;
                        if (fieldError?.errors) {
                            Object.keys(fieldError.errors).forEach((key) => {
                                const errorMessage = fieldError.errors[key];
                                setError(key as keyof typeof initialValues, {
                                    type: "manual",
                                    message: errorMessage[0],
                                });
                            });
                        }
                    }
                }
                console.error(error);
                setIsButtonLoading(false);
            }
        }
        // console.log(formattedHarga);
    };
    //  untuk debugging value tiap field
    // const values = getValues();

    useEffect(() => {
        if (isOpenModal) {
            if (dataStok) {
                if (dataStok.isBundling > 0) {
                    addProducts(
                        dataStok.detailBundling.map((li) => ({
                            id: li.id,
                            nama: li.namaBarang,
                            harga: li.harga,
                            stok: li.stok,
                        })),
                    );
                    setActiveStep(1);
                    setSection(SectionTambah.Bundle);
                } else {
                    reset(dataStok);
                    setSection(SectionTambah.Satuan);
                }
                console.log(dataStok);
            } else {
                reset(initialValues);
            }
        }
    }, [isOpenModal, dataStok, reset, initialValues]);

    useEffect(() => {
        if (isOpenModal) {
            refetchSKU();
        }
    }, [isOpenModal, refetchSKU]);

    useEffect(() => {
        if (!isOpenModal) {
            resetBundlingSteps();
        }
    }, [isOpenModal]);

    // console.log(generateSKU());

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModal}
            onClose={closeModal}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModal}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        {dataStok ? "Ubah Barang" : "Tambah Barang"}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                {/* <code>{JSON.stringify(values)}</code> */}
                {!dataStok && (
                    <Box mb="26px">
                        <ToggleButtonGroup
                            color="primary"
                            value={section}
                            exclusive
                            onChange={(_, value) => {
                                if (value !== null) {
                                    setSection(value);
                                    resetBundlingSteps();
                                }
                            }}
                            fullWidth
                        >
                            <ToggleButton value={SectionTambah.Satuan}>
                                Satuan
                            </ToggleButton>
                            {ukmData?.isPro === 1 ? (
                                <ToggleButton
                                    value={SectionTambah.Bundle}
                                    // disabled={!!dataStok}
                                >
                                    Bundling / Paket
                                </ToggleButton>
                            ) : (
                                <ToggleButton
                                    value={SectionTambah.Free}
                                    // disabled={!!dataStok}
                                    // disabled={ukmData?.isPro !== 1}
                                    sx={{ display: "flex", gap: 1 }}
                                >
                                    <Typography fontSize="14px">
                                        Bundling / Paket
                                    </Typography>
                                    <Chip
                                        label="PRO"
                                        size="small"
                                        onClick={() =>
                                            setSection(SectionTambah.Free)
                                        }
                                    />
                                </ToggleButton>
                            )}
                        </ToggleButtonGroup>
                    </Box>
                )}

                {/* {dataStok?.itemSatuan && (
                    <React.Fragment>
                        <Typography variant="h6" fontWeight="bold">
                            List barang dalam paket
                        </Typography>
                        <Box
                            display="grid"
                            gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
                            gap={3}
                            marginBottom={3}
                        >
                            {dataStok.itemSatuan.map((item) => (
                                <Card variant="outlined" sx={{ padding: 1 }}>
                                    <Box
                                        display="grid"
                                        alignItems="center"
                                        gridTemplateColumns="1fr auto"
                                        columnGap={2}
                                    >
                                        <Box>
                                            <Typography fontWeight="bold">
                                                {item.namaBarang}
                                            </Typography>
                                            <Typography>
                                                {toRupiah(item.harga ?? 0)}
                                            </Typography>
                                        </Box>
                                        <Typography variant="h5">0</Typography>
                                    </Box>
                                </Card>
                            ))}
                        </Box>
                    </React.Fragment>
                )} */}

                {section === SectionTambah.Free && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        gap={2}
                        sx={{
                            width: "100%",
                            height: isPhoneScreen
                                ? "calc(100vh - 200px)"
                                : "400px",
                        }}
                    >
                        <LogoUlo width="100px" />
                        <Typography textAlign="center">
                            Mohon maaf, fitur ini hanya untuk pengguna versi
                            PRO. Pilih layanan terlebih dahulu:
                        </Typography>
                        <Stack
                            width={isPhoneScreen ? "100%" : "60%"}
                            marginTop={2}
                            spacing={2}
                        >
                            <Autocomplete
                                disablePortal
                                loading={isLoadingLayanan}
                                options={dataLayanan ?? []}
                                getOptionLabel={(option) => option.nama}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                size="small"
                                value={selectedLayanan}
                                onChange={(
                                    _event,
                                    selected: IOptions | null,
                                ) => {
                                    setSelectedLayanan(selected);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Pilih layanan"
                                    />
                                )}
                            />
                            <Stack
                                direction={isPhoneScreen ? "column" : "row"}
                                display="flex"
                                justifyContent="space-between"
                                alignItems={isPhoneScreen ? "normal" : "center"}
                            >
                                <Box>
                                    <Typography color="#343941" fontSize="18px">
                                        {
                                            dataLayanan?.find(
                                                (item) =>
                                                    item.id ===
                                                    selectedLayanan?.id,
                                            )?.nama
                                        }
                                    </Typography>
                                    <Typography variant="body2" color="#6D6D6D">
                                        {
                                            dataLayanan?.find(
                                                (item) =>
                                                    item.id ===
                                                    selectedLayanan?.id,
                                            )?.deskripsiLayanan
                                        }
                                    </Typography>
                                </Box>
                                <Typography
                                    fontWeight="bold"
                                    color="#343941"
                                    fontSize="18px"
                                    mt={isPhoneScreen ? 2 : 0}
                                >
                                    {toRupiah(
                                        dataLayanan?.find(
                                            (item) =>
                                                item.id === selectedLayanan?.id,
                                        )?.harga ?? 0,
                                    )}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Button
                            variant="contained"
                            target="_blank"
                            href={
                                process.env.NODE_ENV === "production"
                                    ? `https://juragan.ukmlokal.com/payment/U-${orderId}${user?.userId}/${selectedLayanan?.id}/${ukmId}`
                                    : `http://localhost:3000/payment/U-${orderId}${user?.userId}/${selectedLayanan?.id}/${ukmId}`
                            }
                            rel="noreferrer"
                        >
                            Upgrade ke Pro
                        </Button>
                    </Box>
                )}

                {section === SectionTambah.Satuan && (
                    <Fragment>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={8}>
                                <Controller
                                    name="namaBarang"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            // margin="dense"
                                            id="namaBarang"
                                            label="Nama Barang"
                                            fullWidth
                                            variant="outlined"
                                            error={Boolean(errors.namaBarang)}
                                            helperText={
                                                errors.namaBarang
                                                    ? errors.namaBarang.message
                                                    : ""
                                            }
                                            {...field}
                                            onBlur={() => {
                                                if (dataStok) {
                                                    field.onBlur();
                                                } else {
                                                    if (watchSKU.length === 0) {
                                                        setValue(
                                                            "sku",
                                                            generateSKU(),
                                                        );
                                                        clearErrors("sku");
                                                    }
                                                    field.onBlur();
                                                }
                                            }}
                                        />
                                    )}
                                    rules={{ required: "Nama barang required" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="sku"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            // margin="dense"
                                            id="sku"
                                            label="SKU"
                                            fullWidth
                                            variant="outlined"
                                            error={Boolean(errors.sku)}
                                            helperText={
                                                errors.sku
                                                    ? errors.sku.message
                                                    : ""
                                            }
                                            {...field}
                                        />
                                    )}
                                    rules={{ required: "SKU required" }}
                                    defaultValue={generateSKU()}
                                />
                            </Grid>
                            <Grid item xs={12} marginTop={2}>
                                <Typography variant="body1">Tipe</Typography>
                                <Typography variant="body2" color="#545E6A">
                                    Silahkan pilih tipe produk.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="tipe"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <FormControl fullWidth>
                                            <InputLabel>Tipe</InputLabel>
                                            <Select
                                                value={field.value}
                                                label="Tipe"
                                                onChange={field.onChange}
                                                error={!!fieldState.error}
                                            >
                                                {tipeOptions.map((li) => (
                                                    <MenuItem
                                                        key={li.id}
                                                        value={li.id}
                                                    >
                                                        {li.nama}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} marginTop={2}>
                                <Typography variant="body1">
                                    Multiple Harga
                                </Typography>
                                <Typography variant="body2" color="#545E6A">
                                    Silahkan atur harga multiple anda.
                                </Typography>
                            </Grid>
                            <Grid item xs={5} sm={7}>
                                <Controller
                                    name={`multipleHarga.0.keterangan`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            // margin="dense"
                                            id={`multipleHarga.0.keterangan`}
                                            label="Keterangan"
                                            fullWidth
                                            variant="outlined"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                fieldState?.error.message
                                            }
                                            disabled
                                            sx={{
                                                "& .MuiOutlinedInput-input.Mui-disabled":
                                                    {
                                                        color: "rgba(0, 0, 0, 0.87)",
                                                        WebkitTextFillColor:
                                                            "rgba(0, 0, 0, 0.87)",
                                                    },
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <Controller
                                    name={`multipleHarga.0.harga`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <NumberFormat
                                            customInput={TextField}
                                            label="Harga"
                                            // margin="dense"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            fullWidth
                                            variant="outlined"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                fieldState?.error.message
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Typography>
                                                            Rp.
                                                        </Typography>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={5} sm={7}>
                                <Controller
                                    name={`multipleHarga.1.keterangan`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            // margin="dense"
                                            id={`multipleHarga.1.keterangan`}
                                            label="Keterangan"
                                            fullWidth
                                            variant="outlined"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                fieldState?.error.message
                                            }
                                            disabled
                                            sx={{
                                                "& .MuiOutlinedInput-input.Mui-disabled":
                                                    {
                                                        color: "rgba(0, 0, 0, 0.87)",
                                                        WebkitTextFillColor:
                                                            "rgba(0, 0, 0, 0.87)",
                                                    },
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <Controller
                                    name={`multipleHarga.1.harga`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <NumberFormat
                                            customInput={TextField}
                                            label="Harga"
                                            // margin="dense"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            fullWidth
                                            variant="outlined"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                fieldState?.error.message
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Typography>
                                                            Rp.
                                                        </Typography>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={5} sm={7}>
                                <Controller
                                    name={`multipleHarga.2.keterangan`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            // margin="dense"
                                            id={`multipleHarga.2.keterangan`}
                                            label="Keterangan"
                                            fullWidth
                                            variant="outlined"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                fieldState?.error.message
                                            }
                                            disabled
                                            sx={{
                                                "& .MuiOutlinedInput-input.Mui-disabled":
                                                    {
                                                        color: "rgba(0, 0, 0, 0.87)",
                                                        WebkitTextFillColor:
                                                            "rgba(0, 0, 0, 0.87)",
                                                    },
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <Controller
                                    name={`multipleHarga.2.harga`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <NumberFormat
                                            customInput={TextField}
                                            label="Harga"
                                            // margin="dense"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            fullWidth
                                            variant="outlined"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                fieldState?.error.message
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Typography>
                                                            Rp.
                                                        </Typography>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={5} sm={7}>
                                <Controller
                                    name={`multipleHarga.3.keterangan`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            // margin="dense"
                                            id={`multipleHarga.3.keterangan`}
                                            label="Keterangan"
                                            fullWidth
                                            variant="outlined"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                fieldState?.error.message
                                            }
                                            disabled
                                            sx={{
                                                "& .MuiOutlinedInput-input.Mui-disabled":
                                                    {
                                                        color: "rgba(0, 0, 0, 0.87)",
                                                        WebkitTextFillColor:
                                                            "rgba(0, 0, 0, 0.87)",
                                                    },
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <Controller
                                    name={`multipleHarga.3.harga`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <NumberFormat
                                            customInput={TextField}
                                            label="Harga"
                                            // margin="dense"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            fullWidth
                                            variant="outlined"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                fieldState?.error.message
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Typography>
                                                            Rp.
                                                        </Typography>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={5} sm={7}>
                                <Controller
                                    name={`multipleHarga.4.keterangan`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            // margin="dense"
                                            id={`multipleHarga.4.keterangan`}
                                            label="Keterangan"
                                            fullWidth
                                            variant="outlined"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                fieldState?.error.message
                                            }
                                            disabled
                                            sx={{
                                                "& .MuiOutlinedInput-input.Mui-disabled":
                                                    {
                                                        color: "rgba(0, 0, 0, 0.87)",
                                                        WebkitTextFillColor:
                                                            "rgba(0, 0, 0, 0.87)",
                                                    },
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <Controller
                                    name={`multipleHarga.4.harga`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <NumberFormat
                                            customInput={TextField}
                                            label="Harga"
                                            // margin="dense"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            fullWidth
                                            variant="outlined"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                fieldState?.error.message
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Typography>
                                                            Rp.
                                                        </Typography>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} marginTop={2}>
                                <Typography variant="body1">
                                    Deskripsi
                                </Typography>
                                <Typography variant="body2" color="#545E6A">
                                    Silahkan beri deskripsi untuk barang anda
                                    (opsional).
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="deskripsiBarang"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            // margin="dense"
                                            id="deskripsiBarang"
                                            label="Deskripsi"
                                            fullWidth
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            error={Boolean(
                                                errors.deskripsiBarang,
                                            )}
                                            helperText={
                                                errors.deskripsiBarang
                                                    ? errors.deskripsiBarang
                                                          .message
                                                    : ""
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Box marginTop={2} marginBottom={1}>
                            <Typography variant="body1">Gambar</Typography>
                            <Typography variant="body2" color="#545E6A">
                                Silahkan upload gambar barang (opsional).
                            </Typography>
                        </Box>
                        <Stack>
                            <Controller
                                name="gambar"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="gambar"
                                        fullWidth
                                        type="file"
                                        variant="outlined"
                                        // InputProps={{
                                        //     endAdornment: (
                                        //         <InputAdornment position="end">
                                        //             <IconButton
                                        //                 onClick={(event: any) => {
                                        //                     event.target.value = "";
                                        //                 }}
                                        //             >
                                        //                 <CloseIcon />
                                        //             </IconButton>
                                        //         </InputAdornment>
                                        //     ),
                                        // }}
                                        inputProps={{
                                            accept: "image/*",
                                        }}
                                        error={Boolean(errors.gambar)}
                                        helperText={
                                            errors.gambar
                                                ? errors.gambar.message
                                                : ""
                                        }
                                        {...field}
                                        // value={field.value || ""}
                                        onChange={(event: any) => {
                                            if (
                                                event.target.files[0].size <
                                                2097152
                                            ) {
                                                console.log(
                                                    event.target.files[0],
                                                );
                                                setValue(
                                                    "gambar",
                                                    event.target.files[0],
                                                );
                                                clearErrors("gambar");
                                            } else {
                                                event.target.value = "";
                                                setError("gambar", {
                                                    type: "manual",
                                                    message:
                                                        "Ukuran gambar harus kurang dari 2MB",
                                                });
                                            }
                                        }}
                                        value={undefined}
                                    />
                                )}
                            />
                            <Typography variant="caption" color="#6D6D6D">
                                *Resolusi optimal: 300 x 300
                            </Typography>
                            <Typography variant="caption" color="#6D6D6D">
                                *Ukuran maksimal: 2MB
                            </Typography>
                        </Stack>
                        <Grid container marginTop={2}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1">
                                    Kategori
                                </Typography>
                                <Typography variant="body2" color="#545E6A">
                                    Cek jika ingin menambah kategori baru.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Controller
                                    name="isKategoriBaru"
                                    control={control}
                                    render={({ field }) => (
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox {...field} />
                                                }
                                                label="Tambah kategori baru"
                                                componentsProps={{
                                                    typography: {
                                                        variant: "body2",
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container marginTop={1}>
                            <Grid item xs={12} sm={6}>
                                {isKategoriBaru ? (
                                    <Controller
                                        name="kategoriBaru"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                // margin="dense"
                                                id="kategoriBaru"
                                                label="Kategori Baru"
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(
                                                    errors.kategoriBaru,
                                                )}
                                                helperText={
                                                    errors.kategoriBaru
                                                        ? errors.kategoriBaru
                                                              .message
                                                        : " "
                                                }
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: "Kategori baru required",
                                        }}
                                    />
                                ) : (
                                    <FormControl
                                        // margin="normal"
                                        fullWidth
                                        disabled={isKategoriBaru}
                                    >
                                        {/* <InputLabel error={Boolean(errors.kategori)}>
                Pilih kategori
              </InputLabel> */}
                                        <Controller
                                            control={control}
                                            name="kategoriId"
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <FormControl
                                                    // size="small"
                                                    fullWidth
                                                >
                                                    {/* <InputLabel>
                                                        Pilih Kategori
                                                    </InputLabel> */}
                                                    <Autocomplete
                                                        options={
                                                            dataKategori?.content ??
                                                            []
                                                        }
                                                        renderInput={(
                                                            params,
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Kategori"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                        isOptionEqualToValue={(
                                                            option,
                                                            value,
                                                        ) =>
                                                            String(
                                                                option.id,
                                                            ) ===
                                                            String(value.id)
                                                        }
                                                        getOptionLabel={(
                                                            option,
                                                        ) => option.nama}
                                                        fullWidth
                                                        value={value}
                                                        onChange={(
                                                            _event,
                                                            newKategori: IOptions | null,
                                                        ) => {
                                                            onChange(
                                                                newKategori,
                                                            );
                                                        }}
                                                    />
                                                    {/* <Select
                                                        label="Pilih Kategori"
                                                        margin="dense"
                                                        onChange={onChange}
                                                        value={value}
                                                        error={Boolean(
                                                            errors.kategoriId,
                                                        )}
                                                    >
                                                        {dataKategori &&
                                                            dataKategori.totalElements >
                                                            0 ? (
                                                            dataKategori.content.map(
                                                                (k) => (
                                                                    <MenuItem
                                                                        key={
                                                                            k.id
                                                                        }
                                                                        value={
                                                                            k.id
                                                                        }
                                                                    >
                                                                        {k.nama}
                                                                    </MenuItem>
                                                                ),
                                                            )
                                                        ) : (
                                                            <MenuItem value="">
                                                                Tidak ada
                                                                kategori
                                                            </MenuItem>
                                                        )}
                                                    </Select> */}
                                                </FormControl>
                                            )}
                                        />
                                        <FormHelperText
                                            error={Boolean(errors.kategoriId)}
                                        >
                                            {errors.kategoriId
                                                ? errors.kategoriId.id?.message
                                                : " "}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            display={
                                !!dataStok
                                    ? "none"
                                    : ukmData?.isPro === 0
                                    ? "none"
                                    : undefined
                            }
                            container
                            alignItems="center"
                        >
                            <Grid item xs={5} md={5}>
                                <Typography variant="body1">
                                    Harga Pokok Penjualan (HPP)
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="isHPP"
                                    control={control}
                                    render={({ field }) => (
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        sx={{
                                                            "& .MuiSwitch-thumb":
                                                                {
                                                                    color: "#eaeaea",
                                                                },
                                                        }}
                                                    />
                                                }
                                                label={
                                                    field.value
                                                        ? "Aktif"
                                                        : "Tidak Aktif"
                                                }
                                                componentsProps={{
                                                    typography: {
                                                        variant: "body2",
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="#545E6A">
                                    Nonaktifkan jika tidak membutuhkan harga
                                    pokok penjualan (HPP).
                                </Typography>
                            </Grid>
                        </Grid>
                        {isHPP ? (
                            <Grid
                                display={
                                    !!dataStok
                                        ? "none"
                                        : ukmData?.isPro === 0
                                        ? "none"
                                        : undefined
                                }
                                container
                                marginY={1}
                                spacing={2}
                            >
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="supplier"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <Autocomplete
                                                disablePortal
                                                disableClearable
                                                loading={isLoadingSupplier}
                                                options={mappedSupplier ?? []}
                                                getOptionLabel={(option) =>
                                                    option.nama
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) => option.id === value.id}
                                                value={field.value}
                                                onInputChange={(e) => {
                                                    if (e) {
                                                        const element =
                                                            e.target as HTMLInputElement;
                                                        setParamsSupplier(
                                                            (prev) => ({
                                                                ...prev,
                                                                search: element.value,
                                                            }),
                                                        );
                                                    } else {
                                                        return;
                                                    }
                                                }}
                                                onChange={(
                                                    _event,
                                                    newSupplier: IOptions | null,
                                                ) => {
                                                    field.onChange(newSupplier);
                                                }}
                                                fullWidth
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Pilih supplier"
                                                        error={
                                                            !!fieldState?.error
                                                        }
                                                        helperText={
                                                            fieldState?.error &&
                                                            "Pilih satu supplier"
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="coaBayar"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <Autocomplete
                                                disablePortal
                                                disableClearable
                                                loading={isLoadingCoa}
                                                options={mappedCoa ?? []}
                                                getOptionLabel={(option) => {
                                                    return `(${option.noAkun}) - ${option.namaAkun}`;
                                                }}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) => option.id === value.id}
                                                value={field.value}
                                                onInputChange={(e) => {
                                                    if (e) {
                                                        const element =
                                                            e.target as HTMLInputElement;
                                                        setParamsCoa(
                                                            (prev) => ({
                                                                ...prev,
                                                                search: element.value,
                                                            }),
                                                        );
                                                    } else {
                                                        return;
                                                    }
                                                }}
                                                onChange={(
                                                    _event,
                                                    newCoa: {
                                                        id: number;
                                                        noAkun: string;
                                                        namaAkun: string;
                                                    } | null,
                                                ) => {
                                                    field.onChange(newCoa);
                                                }}
                                                fullWidth
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Pilih COA"
                                                        error={
                                                            !!fieldState?.error
                                                        }
                                                        helperText={
                                                            fieldState?.error &&
                                                            "Pilih satu COA"
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="hpp"
                                        control={control}
                                        render={({ field }) => (
                                            <NumberFormat
                                                customInput={TextField}
                                                label="Harga Pokok Penjualan"
                                                // margin="dense"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.hpp)}
                                                helperText={
                                                    errors.hpp
                                                        ? errors.hpp.message
                                                        : ""
                                                }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography>
                                                                Rp.
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="bayarAP"
                                        control={control}
                                        render={({ field }) => (
                                            <NumberFormat
                                                customInput={TextField}
                                                label="Bayar AP"
                                                // margin="dense"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                fullWidth
                                                variant="outlined"
                                                error={
                                                    Boolean(errors.bayarAP) ||
                                                    Number(
                                                        formatHarga(
                                                            String(field.value),
                                                        ),
                                                    ) > stokKaliHarga
                                                }
                                                helperText={
                                                    errors.bayarAP
                                                        ? errors.bayarAP.message
                                                        : Number(
                                                              formatHarga(
                                                                  String(
                                                                      field.value,
                                                                  ),
                                                              ),
                                                          ) > stokKaliHarga
                                                        ? "Kelebihan"
                                                        : "Isi 0 jika tidak ingin bayar"
                                                }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography>
                                                                Rp.
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="dpp"
                                        control={control}
                                        render={({ field }) => (
                                            <NumberFormat
                                                customInput={TextField}
                                                label="DPP"
                                                // margin="dense"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.dpp)}
                                                helperText={
                                                    errors.dpp
                                                        ? errors.dpp.message
                                                        : ""
                                                }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography>
                                                                Rp.
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="ppn"
                                        control={control}
                                        render={({ field }) => (
                                            <NumberFormat
                                                customInput={TextField}
                                                label="PPN (11%)"
                                                // margin="dense"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.ppn)}
                                                helperText={
                                                    errors.ppn
                                                        ? errors.ppn.message
                                                        : ""
                                                }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography>
                                                                Rp.
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                inputProps={{ readOnly: true }}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            ""
                        )}
                        <Grid container alignItems="center" mt={2}>
                            <Grid item xs={5} md={5}>
                                <Typography variant="body1">Stok</Typography>
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="isActiveStok"
                                    control={control}
                                    render={({ field }) => (
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        disabled={!!dataStok}
                                                        checked={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        sx={{
                                                            "& .MuiSwitch-thumb":
                                                                {
                                                                    color: "#eaeaea",
                                                                },
                                                        }}
                                                    />
                                                }
                                                label={
                                                    field.value
                                                        ? "Aktif"
                                                        : "Tidak Aktif"
                                                }
                                                componentsProps={{
                                                    typography: {
                                                        variant: "body2",
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="#545E6A">
                                    Nonaktifkan jika tidak membutuhkan stok.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            marginTop={1}
                            marginBottom={2}
                            columnSpacing={2}
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={6}>
                                {isActiveStok ? (
                                    <Controller
                                        name="jumlahStok"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                disabled={!!dataStok}
                                                margin="dense"
                                                id="jumlahStok"
                                                label="Jumlah Stok"
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 1 },
                                                }}
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(
                                                    errors.jumlahStok,
                                                )}
                                                helperText={
                                                    errors.jumlahStok
                                                        ? errors.jumlahStok
                                                              .message
                                                        : ""
                                                }
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: "Jumlah stok required",
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                display={!!dataStok ? undefined : "none"}
                            >
                                <Typography
                                    component="div"
                                    variant="body2"
                                    color="#545E6A"
                                >
                                    Anda dapat melakukan perubahan jumlah stok
                                    melalui fitur Mutasi Stok.{" "}
                                    <Link
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            closeModal();
                                            openModalMutasiStok();
                                        }}
                                        underline="hover"
                                        sx={{ cursor: "pointer" }}
                                    >
                                        Ke Mutasi Stok
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" mb={3}>
                            <Grid item xs={5} md={5}>
                                <Typography variant="body1">Favorit</Typography>
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="favorite"
                                    control={control}
                                    render={({ field }) => (
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={field.value}
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        sx={{
                                                            "& .MuiSwitch-thumb":
                                                                {
                                                                    color: "#eaeaea",
                                                                },
                                                        }}
                                                    />
                                                }
                                                label={
                                                    field.value
                                                        ? "Favorit"
                                                        : "Tidak Favorit"
                                                }
                                                componentsProps={{
                                                    typography: {
                                                        variant: "body2",
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="#545E6A">
                                    Jika mengaktifkan favorit akan membuat
                                    tampilan barang berada di paling awal.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Stack
                            direction="row"
                            spacing={isDesktopScreen ? 0 : 2}
                            alignItems="center"
                        >
                            <Button
                                variant="outlined"
                                onClick={() => closeModal()}
                                fullWidth={isDesktopScreen ? false : true}
                                sx={{ display: { xs: "block", md: "none" } }}
                            >
                                <Typography>Kembali</Typography>
                            </Button>
                            <LoadingButton
                                loading={isButtonLoading}
                                loadingPosition="center"
                                variant="contained"
                                onClick={handleSubmit(onSubmit)}
                                type="submit"
                                fullWidth={isDesktopScreen ? false : true}
                            >
                                Simpan
                            </LoadingButton>
                        </Stack>
                    </Fragment>
                )}

                {section === SectionTambah.Bundle && (
                    <Fragment>
                        <Grid>
                            <Stepper
                                activeStep={activeStep}
                                orientation="vertical"
                            >
                                {steps.map((step, index) => (
                                    <Step key={String(index + 1)}>
                                        <StepLabel>{step.label}</StepLabel>
                                        <StepContent>
                                            <Typography color="GrayText">
                                                {step.description}
                                            </Typography>
                                            <Box my={4}>
                                                {index === 0 && (
                                                    <Fragment>
                                                        <PilihItemStep
                                                            addProducts={
                                                                addProducts
                                                            }
                                                        />
                                                        <Box
                                                            mt={4}
                                                            display="grid"
                                                            gridTemplateColumns="1fr 1fr 1fr"
                                                            justifyContent="flex-end"
                                                            columnGap="16px"
                                                        >
                                                            <div />
                                                            <Button
                                                                disabled={
                                                                    index === 0
                                                                }
                                                                onClick={
                                                                    handleBack
                                                                }
                                                                variant="outlined"
                                                            >
                                                                Kembali
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                disabled={
                                                                    activeStep ===
                                                                        0 &&
                                                                    products.length ===
                                                                        0
                                                                }
                                                                onClick={
                                                                    handleNext
                                                                }
                                                            >
                                                                Lanjutkan
                                                            </Button>
                                                        </Box>
                                                    </Fragment>
                                                )}
                                                {index === 1 && (
                                                    <DetailItemStep
                                                        products={products}
                                                        onBack={handleBack}
                                                        dataStokBundle={{
                                                            id: dataStok?.id,
                                                            item: dataStok?.detailBundling.map(
                                                                (li) => ({
                                                                    itemId: li.id,
                                                                    jumlahItem:
                                                                        li.jumlahYangDiambil,
                                                                }),
                                                            ) ?? [
                                                                {
                                                                    itemId: 1,
                                                                    jumlahItem: 1,
                                                                },
                                                            ],
                                                            namaBundling:
                                                                dataStok?.namaBarang ??
                                                                "",
                                                            deskripsiBundling:
                                                                dataStok?.deskripsiBarang ??
                                                                "",
                                                            kategoriBundling:
                                                                dataStok
                                                                    ?.kategoriId
                                                                    .id ?? "",
                                                            sku:
                                                                dataStok?.sku ??
                                                                "",
                                                            hargaBundling:
                                                                String(
                                                                    dataStok?.harga,
                                                                ) ?? "",
                                                            isActiveStok:
                                                                dataStok?.isActiveStok ??
                                                                true,
                                                            jumlahStok:
                                                                dataStok?.jumlahStok ??
                                                                1,
                                                            favorite:
                                                                dataStok?.favorite ??
                                                                false,
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                    </Fragment>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ModalStok;
