import React from "react";
import { BlobProvider } from "@react-pdf/renderer";
import Print from "@mui/icons-material/Print";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";
import {
    DataFakturKonsinyasi,
    DetailFaktur,
    LogBayarAP,
} from "../../constants/types";
import toRibuan from "../../utils/toRibuan";
import toRupiahDesimal from "../../utils/toRupiahDesimal";
import LoadingTabel from "../LoadingTabel/LoadingTabel";
import NoRowsImage from "../NoRowsImage/NoRowsImage";
import PrintFakturKonsinyasi from "../PrintFakturKonsinyasi/PrintFakturKonsinyasi";
import useUkm from "../../services/queries/useUkm";
import useKonsinyi from "../../services/queries/useKonsinyi";
import ImportExport from "@mui/icons-material/ImportExport";

interface IModalDetailFakturKonsinyasiProps {
    fullScreen: boolean;
    isOpenModalDetailFaktur: boolean;
    closeModalDetailFaktur: () => void;
    openModalDetailFaktur: (
        data: DetailFaktur[],
        dataFaktur: DataFakturKonsinyasi,
        logBayar?: LogBayarAP[],
    ) => void;
    detailFaktur: DetailFaktur[];
    dataFaktur: DataFakturKonsinyasi;
    logBayar?: LogBayarAP[];
    isAP?: boolean;
}

const ModalDetailFakturKonsinyasi = ({
    fullScreen,
    isOpenModalDetailFaktur,
    closeModalDetailFaktur,
    openModalDetailFaktur,
    detailFaktur,
    dataFaktur,
    logBayar,
    isAP,
}: IModalDetailFakturKonsinyasiProps) => {
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const initialParamsKonsinyi = {
        size: 500,
        page: 1,
        search: "",
    };

    const { data: konsinyi } = useKonsinyi(initialParamsKonsinyi);

    const ukmData = React.useMemo(() => {
        if (dataFaktur.ukm.id) {
            return ukm?.content.find((li) => li.id === dataFaktur.ukm.id);
        }
        return ukm?.content[0];
    }, [ukm?.content, dataFaktur.ukm.id]);

    const konsinyiData = React.useMemo(() => {
        if (dataFaktur.konsinyi.id) {
            return konsinyi?.content.find(
                (li) => li.id === dataFaktur.konsinyi.id,
            );
        }
        return konsinyi?.content[0];
    }, [dataFaktur.konsinyi.id, konsinyi?.content]);

    const columnsMasuk: GridColDef[] = [
        {
            field: "stok",
            headerName: "Nama Barang",
            flex: 1,
            minWidth: 160,
            renderCell: (params) => {
                return (
                    <Typography variant="body2">
                        {params.value.id ? params.value.nama : "-"}
                    </Typography>
                );
            },
        },
        {
            field: "faktur",
            headerName: "Faktur",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Typography variant="body2">
                        {params.value.id ? params.value.faktur : "-"}
                    </Typography>
                );
            },
        },

        {
            field: "hargaSatuan",
            headerName: "Harga Satuan",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="subtitle2"
                        fontWeight={400}
                        textAlign="right"
                    >
                        {params.value
                            ? toRupiahDesimal(Number(params.value))
                            : "-"}
                    </Typography>
                );
            },
        },
        {
            field: "jumlah",
            headerName: "Jumlah",
            flex: 1,
            minWidth: 120,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="subtitle2"
                        fontWeight={400}
                        textAlign="right"
                    >
                        {toRibuan(params.value)}
                    </Typography>
                );
            },
        },
        {
            field: "barangDitarik",
            headerName: "Barang Ditarik",
            flex: 1,
            minWidth: 120,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="subtitle2"
                        fontWeight={400}
                        textAlign="right"
                    >
                        {toRibuan(params.value)}
                    </Typography>
                );
            },
        },
        {
            field: "barangTerjual",
            headerName: "Barang Terjual",
            flex: 1,
            minWidth: 120,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="subtitle2"
                        fontWeight={400}
                        textAlign="right"
                    >
                        {toRibuan(params.value)}
                    </Typography>
                );
            },
        },
    ];

    return (
        <React.Fragment>
            <Dialog
                maxWidth="lg"
                fullScreen={fullScreen}
                fullWidth={true}
                open={isOpenModalDetailFaktur}
                onClose={() => {}}
                scroll="body"
                PaperProps={{
                    sx: {
                        borderRadius: 1,
                    },
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton onClick={closeModalDetailFaktur}>
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" fontWeight="bold">
                            Detail Faktur
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={3}>
                        <Stack spacing={1}>
                            <Stack direction="row" spacing={1}>
                                <Typography width="40%">Tanggal</Typography>
                                <Typography width="3%">:</Typography>
                                <Typography>
                                    {dataFaktur.tanggal
                                        ? moment(dataFaktur.tanggal).format(
                                              "LL",
                                          )
                                        : "-"}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Typography width="40%">No. Faktur</Typography>
                                <Typography width="3%">:</Typography>
                                <Typography>{dataFaktur.faktur}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Typography width="40%">Konsinyi</Typography>
                                <Typography width="3%">:</Typography>
                                <Typography>
                                    {dataFaktur.konsinyi.id
                                        ? dataFaktur.konsinyi.nama
                                        : "-"}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Typography width="40%">Jatuh Tempo</Typography>
                                <Typography width="3%">:</Typography>
                                <Typography>
                                    {dataFaktur.jatuhTempo
                                        ? moment(dataFaktur.jatuhTempo).format(
                                              "LL",
                                          )
                                        : "-"}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Typography width="40%">Total</Typography>
                                <Typography width="3%">:</Typography>
                                <Typography>
                                    {toRupiahDesimal(Number(dataFaktur.total))}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack spacing={1}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography fontWeight={500}>
                                    Detail Barang
                                </Typography>
                                <BlobProvider
                                    document={
                                        <PrintFakturKonsinyasi
                                            dataFaktur={dataFaktur}
                                            dataUkm={ukmData}
                                            dataKonsinyi={konsinyiData}
                                        />
                                    }
                                >
                                    {({ blob, url, loading }) => {
                                        return (
                                            <LoadingButton
                                                loading={loading}
                                                disableElevation
                                                loadingPosition="start"
                                                startIcon={<Print />}
                                                color="buttonblue"
                                                variant="contained"
                                                href={url ?? ""}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Print Faktur Konsinyasi
                                            </LoadingButton>
                                        );
                                    }}
                                </BlobProvider>

                                {/* Uncomment code below to enable return konsinyasi */}
                                {/* <Button
                                    // disabled={
                                    //     dataFaktur.total <= 0 ||
                                    //     (user &&
                                    //         !user.akses.juraganPembelian.includes(
                                    //             54,
                                    //         ))
                                    // }
                                    disabled={false}
                                    disableElevation
                                    color="buttonred"
                                    variant="contained"
                                    onClick={() => {
                                        openModalReturPembelian();
                                        closeModalDetailFaktur();
                                    }}
                                    sx={{ display: isAP ? "none" : undefined }}
                                >
                                    Retur Konsinyasi
                                </Button> */}
                            </Box>
                            <Box
                                sx={{
                                    height: 240,
                                    width: "100%",
                                    "& .MuiDataGrid-cell:focus-within": {
                                        outline: "none !important",
                                    },
                                    "& .MuiDataGrid-iconButtonContainer": {
                                        visibility: "visible",
                                        width: "0 !important",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        maxHeight: "fit-content !important",
                                        overflow: "auto",
                                        whiteSpace: "initial !important",
                                        lineHeight: "16px !important",
                                        display: "flex !important",
                                        alignItems: "center",
                                        paddingTop: "10px !important",
                                        paddingBottom: "10px !important",
                                    },
                                    "& .MuiDataGrid-viewport,.MuiDataGrid-row,.MuiDataGrid-renderingZone":
                                        {
                                            maxHeight: "fit-content !important",
                                        },
                                }}
                            >
                                <DataGrid
                                    components={{
                                        ColumnUnsortedIcon: () => {
                                            return <ImportExport />;
                                        },
                                        NoRowsOverlay: NoRowsImage,
                                        LoadingOverlay: LoadingTabel,
                                    }}
                                    rows={detailFaktur}
                                    columns={columnsMasuk}
                                    disableColumnMenu
                                    disableSelectionOnClick
                                    rowsPerPageOptions={[10]}
                                    pageSize={10}
                                    density="compact"
                                />
                            </Box>
                        </Stack>
                        <Stack spacing={1} display={isAP ? undefined : "none"}>
                            <Typography fontWeight={500}>Log Bayar</Typography>
                            <Box
                                sx={{
                                    height: 240,
                                    width: "100%",
                                    "& .MuiDataGrid-cell:focus-within": {
                                        outline: "none !important",
                                    },
                                    "& .MuiDataGrid-iconButtonContainer": {
                                        visibility: "visible",
                                        width: "0 !important",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        maxHeight: "fit-content !important",
                                        overflow: "auto",
                                        whiteSpace: "initial !important",
                                        lineHeight: "16px !important",
                                        display: "flex !important",
                                        alignItems: "center",
                                        paddingTop: "10px !important",
                                        paddingBottom: "10px !important",
                                    },
                                    "& .MuiDataGrid-viewport,.MuiDataGrid-row,.MuiDataGrid-renderingZone":
                                        {
                                            maxHeight: "fit-content !important",
                                        },
                                }}
                            >
                                {/* <DataGrid
                                    components={{
                                        ColumnUnsortedIcon: AscDescIcon,
                                        NoRowsOverlay: NoRowsImage,
                                        LoadingOverlay: LoadingTabel,
                                    }}
                                    rows={logBayar ?? []}
                                    columns={columnsLogBayar}
                                    disableColumnMenu
                                    disableSelectionOnClick
                                    rowsPerPageOptions={[10]}
                                    pageSize={10}
                                    density="compact"
                                /> */}
                            </Box>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
            {/* <ModalReturPembelian
                closeModalReturPembelian={closeModalReturPembelian}
                isOpenModalReturPembelian={isOpenModalReturPembelian}
                openModalDetailFaktur={openModalDetailFaktur}
                fullScreen={fullScreen}
                dataFaktur={dataFaktur}
                detailFaktur={detailFaktur}
            /> */}
            {/* <ModalDeleteAP
                isOpenModalDeleteAP={isOpenModalDeleteAP}
                closeModalDeleteAP={closeModalDeleteAP}
                closeModalDetailFaktur={closeModalDetailFaktur}
                logBayar={selectedLogBayar}
            /> */}
        </React.Fragment>
    );
};

export default ModalDetailFakturKonsinyasi;
