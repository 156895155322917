/* eslint-disable @typescript-eslint/no-unused-expressions */
// import "react-datepicker/dist/react-datepicker.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "chart.js/auto";
import "./Laporan.css";
import "../../components/FilterLaporan/FilterLaporan.css";
import id from "date-fns/locale/id";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import Spinner from "../../components/Spinner/Spinner";
import LogoUlo from "../../components/LogoUlo/LogoUlo";
import toRibuan from "../../utils/toRibuan";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import ReceiptIcon from "@mui/icons-material/Receipt";
import NoRowsImage from "../../components/NoRowsImage/NoRowsImage";
import scrollToHash from "../../utils/scrollToHash";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LoadingTabel from "../../components/LoadingTabel/LoadingTabel";
import PieChartIcon from "@mui/icons-material/PieChart";
import useMediaQuery from "@mui/material/useMediaQuery";
import useMutasiStok from "../../services/queries/useMutasiStok";
import ModalAlertPro from "../../components/ModalAlertPro/ModalAlertPro";
import useListInvoice from "../../services/queries/useListInvoice";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import useLaporanTotal from "../../services/queries/useLaporanTotal";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import useProdukTerjual from "../../services/queries/useProdukTerjual";
import useDetailInvoice from "../../services/queries/useDetailInvoice";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useGrafikPenjualan from "../../services/queries/useGrafikPenjualan";
import ModalRevertInvoice from "../../components/ModalRevertInvoice/ModalRevertInvoice";
import CardLaporanKalkulasi from "../../components/CardLaporanKalkulasi/CardLaporanKalkulasi";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { Line } from "react-chartjs-2";
import { apiUrl } from "../../constants/common";
import { useAuth } from "../../context/authContext";
import { useTheme } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import { Link as ReactScroll } from "react-scroll";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    Customer,
    IOptions,
    ParamsGetLaporanMetodePembayaran,
    ParamsGetLaporanTotal,
    ParamsGetListInvoice,
    ParamsGetMutasiStok,
    ParamsGetProdukTerjual,
    ParamsGetUkm,
    ParamsGrafikPenjualan,
    ParamsKesimpulan,
} from "../../constants/types";
import useNoWaAdmin from "../../services/queries/useNoWaAdmin";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ModalDetailInvoice from "../../components/ModalDetailInvoice/ModalDetailInvoice";
import ModalDetailMutasi from "../../components/ModalDetailMutasi/ModalDetailMutasi";
import TokoTidakAktif from "../../components/TokoTidakAktif/TokoTidakAktif";
import useUsersByUkm from "../../services/queries/useUsersByUkm";
import useMetodePembayaran from "../../services/queries/useMetodePembayaran";
import {
    Autocomplete,
    Checkbox,
    FormControlLabel,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
} from "@mui/material";
import moment from "moment";
import useLaporanMetodePembayaran from "../../services/queries/useLaporanMetodePembayaran";
import logoUloGif from "../../assets/images/LoadingUlo.gif";
import toRupiahDesimal from "../../utils/toRupiahDesimal";
import { IconContext } from "react-icons";
import { GiWallet } from "react-icons/gi";
import { BlobProvider } from "@react-pdf/renderer";
import PrintStrukKesimpulan from "../../components/PrintStrukKesimpulan/PrintStrukKesimpulan";
import LoadingButton from "@mui/lab/LoadingButton";
import useKesimpulan from "../../services/queries/useKesimpulan";
import { subtractMonths, subtractYears } from "../../utils/subtractDate";

// import ModalKonfirmasiBayar from "../../components/ModalKonfirmasiBayar/ModalKonfirmasiBayar";
// import TabelTerjual from "../../components/TabelTerjual/TabelTerjual";
// import TabelInvoice from "../../components/TabelInvoice/TabelInvoice";
// import FilterLaporan from "../../components/FilterLaporan/FilterLaporan";
// import GrafikPenjualan from "../../components/GrafikPenjualan/GrafikPenjualan";
// import RingkasanPenjualan from "../../components/RingkasanPenjualan/RingkasanPenjualan";
// import ButtonAnchor from "../../components/ButtonAnchor/ButtonAnchor";

registerLocale("id", id);
setDefaultLocale("id");

interface ILaporan {}

interface Keterangan {
    jumlah: number;
    keterangan: string;
    tanggal: string;
    invoice: string;
}

interface DetailMutasi {
    namaBarang: string;
    sku: string;
    stokAwal: number;
    hargaBarang: number;
    masuk: number;
    keluar: number;
    stokAkhir: number;
}

interface IOpsiMetodePembayaran {
    id: number;
    nama: string;
    ukmId: { id: number; nama: string | null };
    // ukm: { id: number; nama: string };
}

type DateFilter = [Date | null, Date | null];

const Laporan = (props: ILaporan) => {
    const theme = useTheme();
    const { data: noWaAdmin } = useNoWaAdmin();
    let now = moment().format("YYYY/MM/DD");
    var today = new Date();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    // const [isOpenKalender, setIsOpenKalender] = useState(false);
    const [searchMutasiStok, setSearchMutasiStok] = useState<any>("");
    const [searchProdukTerjual, setSearchProdukTerjual] = useState<any>("");
    const [searchDaftarInvoice, setSearchDaftarInvoice] = useState<any>("");
    const [openTooltipTotalPendapatan, setOpenTooltipTotalPendapatan] =
        useState(false);
    const [openTooltipTotalTransaksi, setOpenTooltipTotalTransaksi] =
        useState(false);
    const [openTooltipProdukTerjual, setOpenTooltipProdukTerjual] =
        useState(false);
    const [isOpenModalAlertPro, setIsOpenModalAlertPro] = useState(false);
    const [isOpenModalRevertInvoice, setIsOpenModalRevertInvoice] =
        useState(false);
    const [isOpenModalDetailMutasi, setIsOpenModalDetailMutasi] =
        useState(false);
    const [keteranganMasuk, setKeteranganMasuk] = useState<Keterangan[]>([]);
    const [keteranganKeluar, setKeteranganKeluar] = useState<Keterangan[]>([]);
    const initialDetailMutasi = {
        namaBarang: "",
        sku: "",
        stokAwal: 0,
        hargaBarang: 0,
        masuk: 0,
        keluar: 0,
        stokAkhir: 0,
    };
    const [detailMutasi, setDetailMutasi] =
        useState<DetailMutasi>(initialDetailMutasi);
    const [showButton, setShowButton] = useState(false);

    const tooltipTotalPendapatan = `
    Total Pendapatan menunjukkan total keuntunganmu pada periode waktu tertentu.
    `;
    const tooltipTotalTransaksi = `
    Total Transaksi menunjukkan berapa banyak transaksi yang dilakukan pada periode waktu tertentu.
    `;
    const tooltipProdukTerjual = `
    Jumlah Stok Terjual menunjukkan jumlah barang yang terjual pada periode waktu tertentu.
    `;

    const { data: user, status: loadingUser } = useUser();
    const { ukmIdUser } = useAuth();

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };

    const initialParamsGrafikPenjualan = {
        ukmId: ukmIdUser && ukmIdUser[0],
        tanggalAwal: now,
        tanggalAkhir: now,
    };

    const initialParamsLaporanTotal = {
        ukmId: ukmIdUser && ukmIdUser[0],
        tanggalAwal: now,
        tanggalAkhir: now,
    };

    const initialParamsProdukTerjual = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 10,
        page: 1,
        tanggalAwal: now,
        tanggalAkhir: now,
    };

    const initialParamsListInvoice = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 10,
        page: 1,
        tanggalAwal: now,
        tanggalAkhir: now,
        kasir: "",
        sales: "",
        metodeId: "",
        voidInvoice: "",
    };

    const initialParamsMutasiStok = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 10,
        page: 1,
        tanggalAwal: now,
        tanggalAkhir: now,
    };

    const initialParamsLaporanMetodePembayaran = {
        tglawal: now,
        tglakhir: now,
    };

    const [id, setId] = useState<number>();
    const [jatuhTempo, setJatuhTempo] = useState<string | null>(null);
    const initialCustomer = {
        id: null,
        nama: null,
    };
    const [customer, setCustomer] = useState<Customer>(initialCustomer);
    const [metode, setMetode] = React.useState<number>(0);
    const [dateRange, setDateRange] = useState<DateFilter>([
        new Date(),
        new Date(),
    ]);
    const [startDate, endDate] = dateRange;
    const [isOpenModalDetailInvoice, setIsOpenModalDetailInvoice] =
        useState(false);
    const [paramsUkm] = useState<ParamsGetUkm>(initialParamsUkm);
    const [paramsGrafikPenjualan, setParamsGarafikPenjualan] =
        useState<ParamsGrafikPenjualan>(initialParamsGrafikPenjualan);
    const [paramsLaporanTotal, setParamsLaporanTotal] =
        useState<ParamsGetLaporanTotal>(initialParamsLaporanTotal);
    const [paramsProdukTerjual, setParamsProdukTerjual] =
        useState<ParamsGetProdukTerjual>(initialParamsProdukTerjual);
    const [paramsListInvoice, setParamsListInvoice] =
        useState<ParamsGetListInvoice>(initialParamsListInvoice);
    const [paramsMutasiStok, setParamsMutasiStok] =
        useState<ParamsGetMutasiStok>(initialParamsMutasiStok);
    const [paramsLaporanMetodePembayaran, setParamsLaporanMetodePembayaran] =
        useState<ParamsGetLaporanMetodePembayaran>(
            initialParamsLaporanMetodePembayaran,
        );

    const { data: ukm } = useUkm(paramsUkm);
    const {
        data: grafikPenjualan,
        status: loadingGrafikPenjualan,
        refetch: refetchGrafikPenjualan,
    } = useGrafikPenjualan(paramsGrafikPenjualan);
    const {
        data: laporanTotal,
        status: loadingLaporanTotal,
        refetch,
    } = useLaporanTotal(paramsLaporanTotal);
    const {
        data: listInvoice,
        refetch: refecthListInvoice,
        isLoading: isLoadingListInvoice,
    } = useListInvoice(paramsListInvoice);
    const {
        data: produkTerjual,
        refetch: refetchProdukTerjual,
        isLoading: isLoadingProdukTerjual,
    } = useProdukTerjual(paramsProdukTerjual);
    const { data: detailInvoice, status: statusDetailInvoice } =
        useDetailInvoice(Number(id));
    const {
        data: mutasiStok,
        refetch: refetchMutasiStok,
        isLoading: isLoadingMutasiStok,
    } = useMutasiStok(paramsMutasiStok);
    const {
        data: laporanMetodePembayaran,
        refetch: refetchLaporanMetodePembayaran,
        isLoading: isLoadingLaporanMetodePembayaran,
    } = useLaporanMetodePembayaran(paramsLaporanMetodePembayaran);

    const ukmId = useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const initialParamsUsersByUkm = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 100,
        page: 1,
        search: "",
    };
    // const [paramsUsersByUkm, setParamsUsersByUkm] =
    //     useState<ParamsPostUsersByUkm>(initialParamsUsersByUkm);
    const { data: usersByUkm, isLoading: isLoadingUsersByUkm } = useUsersByUkm(
        initialParamsUsersByUkm,
    );

    const mappedUsersByUkm = usersByUkm?.content.map((li) => ({
        id: li.userId,
        nama: li.namaLengkap,
    }));

    const { data: metodePembayaran, isLoading: isLoadingMetodePembayaran } =
        useMetodePembayaran(ukmId);

    const mappedMetodePembayaran = metodePembayaran?.map((li) => ({
        id: li.id,
        nama: li.namaMetode,
        ukmId: { id: li.ukmId.id, nama: li.ukmId.nama },
    }));
    const initialParamsKesimpulan = {
        ukmId: ukmIdUser && ukmIdUser[0],
        tanggal: now,
    };
    const [paramsKesimpulan, setParamsKesimpulan] =
        React.useState<ParamsKesimpulan>(initialParamsKesimpulan);
    const {
        data: kesimpulan,
        refetch: refetchKesimpulan,
        isLoading: isLoadingKesimpulan,
    } = useKesimpulan(paramsKesimpulan);

    const [kasir, setKasir] = useState<IOptions | null>(null);
    const [sales, setSales] = useState<IOptions | null>(null);
    const [metodeId, setMetodeId] = useState<IOpsiMetodePembayaran | null>(
        null,
    );
    const [voidInvoice, setVoidInvoice] = useState<boolean>(false);

    const handleChangeVoidInvoice = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setVoidInvoice(event.target.checked);
    };

    const handleConvertSatuan = (value: string) => {
        if (Number(value) >= 1000000000)
            return Number(value) / 1000000000 + "m";
        if (Number(value) >= 1000000) return Number(value) / 1000000 + "jt";
        if (Number(value) >= 1000) return Number(value) / 1000 + "rb";
        return String(value);
    };

    const options = {
        plugins: { legend: { display: false } },
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
            mode: "index" as const,
            intersect: false,
        },
        stacked: false,
        locale: "id-ID",
        scales: {
            x: {
                display: true,
                grid: {
                    drawOnChartArea: false,
                },
            },
            y: {
                display: true,
                position: "left" as const,
                ticks: {
                    callback: function (value: any, index: any, values: any) {
                        return handleConvertSatuan(value);
                    },
                },
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Total Pendapatan",
                },
            },
            y1: {
                display: true,
                position: "right" as const,
                grid: {
                    drawOnChartArea: false,
                },
                gridLines: {
                    drawBorder: false,
                },
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Jumlah Transaksi",
                },
            },
        },
    };

    const data = {
        labels: grafikPenjualan?.labels,
        datasets: [
            {
                label: "Total Pendapatan",
                data: grafikPenjualan?.data.totalPendapatan,
                borderColor: "#45A779",
                backgroundColor: "#45A779",
                yAxisID: "y",
                tension: 0.5,
                pointRadius: 0,
            },
            {
                label: "Total Transaksi",
                data: grafikPenjualan?.data.totalTransaksi,
                borderColor: "#e8d43a",
                backgroundColor: "#e8d43a",
                yAxisID: "y1",
                tension: 0.5,
                pointRadius: 0,
            },
            // {
            //     label: "Stok Terjual",
            //     data: grafikPenjualan?.data.jumlahProdukTerjual,
            //     borderColor: "#3b5284",
            //     backgroundColor: "#3b5284",
            //     yAxisID: "y1",
            //     tension: 0.5,
            //     pointRadius: 0,
            // },
        ],
    };

    const columns: GridColDef[] = [
        {
            field: "namaItem",
            headerName: "Nama Barang",
            headerAlign: "center",
            align: "left",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Box marginY={1}>
                        <Typography variant="subtitle2">
                            {params.value}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "jumlahItem",
            headerName: "Jumlah Item",
            headerAlign: "center",
            align: "center",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Box marginY={1}>
                        <Typography variant="subtitle2" fontWeight="bold">
                            {params.value}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "totalHarga",
            headerName: "Total Harga",
            headerAlign: "center",
            align: "right",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Box marginY={1}>
                        <Typography variant="subtitle2">
                            {toRupiahDesimal(Number(params.value))}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    const columnsListInvoice: GridColDef[] = [
        {
            field: "tanggal",
            headerName: "Tanggal",
            headerAlign: "center",
            align: "left",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Box marginY={1}>
                        <Typography variant="subtitle2">
                            {params.value}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "nomorInvoice",
            headerName: "Invoice",
            headerAlign: "center",
            align: "center",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Box marginY={1}>
                        <Typography variant="subtitle2" fontWeight="bold">
                            {params.value}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "metode",
            headerName: "Metode",
            headerAlign: "center",
            align: "center",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Box marginY={1}>
                        <Typography variant="subtitle2">
                            {params.value ?? "-"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "hargaPenjualan",
            headerName: "Subtotal Harga",
            headerAlign: "center",
            align: "right",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Box marginY={1}>
                        <Typography variant="subtitle2">
                            {toRupiahDesimal(Number(params.value))}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "diskon",
            headerName: "Diskon",
            headerAlign: "center",
            align: "right",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Box marginY={1}>
                        <Typography variant="subtitle2">
                            {toRupiahDesimal(Number(params.value))}
                        </Typography>
                    </Box>
                );
            },
        },
        // {
        //     field: "uangYangDibayar",
        //     headerName: "Tunai",
        //     headerAlign: "center",
        //     align: "right",
        //     flex: isPhoneScreen ? undefined : 1,
        //     minWidth: 150,
        //     renderCell: (params) => {
        //         return (
        //             <Box marginY={1}>
        //                 <Typography variant="subtitle2">
        //                     {toRupiah(params.value)}\
        //                 </Typography>
        //             </Box>
        //         );
        //     },
        // },
        // {
        //     field: "kembalian",
        //     headerName: "Kembalian",
        //     headerAlign: "center",
        //     align: "right",
        //     flex: isPhoneScreen ? undefined : 1,
        //     minWidth: 150,
        //     renderCell: (params) => {
        //         return (
        //             <Box marginY={1}>
        //                 <Typography variant="subtitle2">
        //                     {toRupiah(params.value)}
        //                 </Typography>
        //             </Box>
        //         );
        //     },
        // },
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            minWidth: 160,
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setId(params.row.id);
                                setJatuhTempo(params.row.jatuhTempo);
                                setCustomer(params.row.customer);
                                setMetode(params.row.metodeId);
                                setIsOpenModalDetailInvoice(true);
                            }}
                            size="small"
                            color="buttongreen"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <VisibilityIcon
                                sx={{ width: "16px", height: "16px" }}
                            />
                        </Button>
                        <Button
                            disabled={
                                params.row.hargaPenjualan < 0 ||
                                params.row.isReverted !== 0
                            }
                            variant="contained"
                            onClick={() => {
                                setId(params.row.id);
                                setCustomer(params.row.customer);
                                setMetode(params.row.metodeId);
                                setIsOpenModalRevertInvoice(true);
                            }}
                            size="small"
                            color="buttonyellow"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                                // visibility:
                                //     params.row.hargaPenjualan > 0 &&
                                //     params.row.isReverted === 0
                                //         ? "hidden"
                                //         : undefined,
                            }}
                        >
                            <DeleteIcon
                                sx={{ width: "16px", height: "16px" }}
                            />
                        </Button>
                    </Stack>
                );
            },
        },
    ];

    const columnsMutasiStok: GridColDef[] = [
        {
            field: "namaBarang",
            headerName: "Info Barang",
            headerAlign: "center",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 155,
            valueGetter: (params) => params.value,
            renderCell: (params) => {
                return (
                    <Stack direction="column">
                        <Typography variant="subtitle2" fontWeight="bold">
                            {params.value}
                        </Typography>
                        <Typography variant="caption">
                            {params.row.sku}
                        </Typography>
                    </Stack>
                );
            },
        },
        {
            field: "stokAwal",
            headerName: "Stok Awal",
            headerAlign: "center",
            align: "center",
            type: "number",
            flex: isPhoneScreen ? undefined : 0.5,
            minWidth: 150,
            valueGetter: (params) =>
                params.value === "Otomatis"
                    ? params.value
                    : toRibuan(params.value),
        },
        {
            field: "hargaBarang",
            headerName: "Harga",
            headerAlign: "center",
            align: "right",
            type: "number",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 150,
            valueGetter: (params) => params.value,
            renderCell: (params) => {
                return (
                    <Box marginY={1}>
                        <Typography variant="subtitle2">
                            {toRupiahDesimal(Number(params.value))}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "masuk",
            headerName: "Mutasi In",
            headerAlign: "center",
            align: "center",
            type: "number",
            flex: isPhoneScreen ? undefined : 0.5,
            minWidth: 155,
            valueGetter: (params) =>
                params.value === 0 ? "-" : toRibuan(params.value),
        },
        {
            field: "keluar",
            headerName: "Mutasi Out",
            headerAlign: "center",
            align: "center",
            type: "number",
            flex: isPhoneScreen ? undefined : 0.5,
            minWidth: 155,
            valueGetter: (params) =>
                params.value === 0 ? "-" : toRibuan(params.value),
        },
        {
            field: "stokAkhir",
            headerName: "Stok Akhir",
            headerAlign: "center",
            align: "center",
            flex: isPhoneScreen ? undefined : 0.5,
            minWidth: 150,
            valueGetter: (params) =>
                params.value === "Otomatis"
                    ? params.value
                    : toRibuan(params.value),
        },
        {
            field: "aksi",
            headerName: "",
            headerAlign: "center",
            minWidth: 160,
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="buttongreen"
                        onClick={() => {
                            setDetailMutasi({
                                namaBarang: params.row.namaBarang,
                                sku: params.row.sku,
                                stokAwal: params.row.stokAwal,
                                hargaBarang: Number(params.row.hargaBarang),
                                masuk: params.row.masuk,
                                keluar: params.row.keluar,
                                stokAkhir: Number(params.row.stokAkhir),
                            });
                            setKeteranganMasuk(params.row.keteranganMasuk);
                            setKeteranganKeluar(params.row.keteranganKeluar);
                            setIsOpenModalDetailMutasi(true);
                        }}
                        size="small"
                        disableElevation
                        sx={{
                            minWidth: "unset",
                            padding: "8px",
                            width: "32px",
                            height: "32px",
                        }}
                    >
                        <VisibilityIcon
                            sx={{ width: "16px", height: "16px" }}
                        />
                    </Button>
                );
            },
        },
    ];

    const closeModalDetailinvoice = useCallback(() => {
        setIsOpenModalDetailInvoice(false);
    }, []);

    const totalTransaksi =
        laporanMetodePembayaran &&
        laporanMetodePembayaran?.content
            .map((li) => Number(li.totalTransaksi))
            .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0,
            );
    const totalPendapatan =
        laporanMetodePembayaran &&
        laporanMetodePembayaran?.content
            .map((li) => Number(li.totalPendapatan))
            .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0,
            );

    const handleChangePeriode = (event: SelectChangeEvent) => {
        if (event.target.value === "setahun") {
            setIsOpenModalAlertPro(true);
        } else {
            setDateRange([new Date(event.target.value), new Date()]);
            setParamsGarafikPenjualan((prev) => ({
                ...prev,
                tanggalAwal: String(event.target.value),
                tanggalAkhir: now,
                page: 1,
            }));
            setParamsLaporanTotal((prev) => ({
                ...prev,
                tanggalAwal: String(event.target.value),
                tanggalAkhir: now,
                page: 1,
            }));
            setParamsProdukTerjual((prev) => ({
                ...prev,
                tanggalAwal: String(event.target.value),
                tanggalAkhir: now,
                page: 1,
            }));
            setParamsListInvoice((prev) => ({
                ...prev,
                tanggalAwal: String(event.target.value),
                tanggalAkhir: now,
                page: 1,
            }));
            setParamsMutasiStok((prev) => ({
                ...prev,
                tanggalAwal: String(event.target.value),
                tanggalAkhir: now,
                page: 1,
            }));
            setParamsLaporanMetodePembayaran((prev) => ({
                ...prev,
                tglawal: String(event.target.value),
                tglakhir: now,
                page: 1,
            }));
            refetch();
            refecthListInvoice();
            refetchProdukTerjual();
            refetchMutasiStok();
            refetchGrafikPenjualan();
            refetchLaporanMetodePembayaran();
        }
    };

    const handleChangeJangkaWaktu = (
        tanggalAwal: string,
        tanggalAkhir: string,
    ) => {
        setParamsLaporanTotal((prev) => ({
            ...prev,
            tanggalAwal: tanggalAwal,
            tanggalAkhir: tanggalAkhir,
            page: 1,
        }));
        setParamsProdukTerjual((prev) => ({
            ...prev,
            tanggalAwal: tanggalAwal,
            tanggalAkhir: tanggalAkhir,
            page: 1,
        }));
        setParamsListInvoice((prev) => ({
            ...prev,
            tanggalAwal: tanggalAwal,
            tanggalAkhir: tanggalAkhir,
            page: 1,
        }));
        setParamsMutasiStok((prev) => ({
            ...prev,
            tanggalAwal: tanggalAwal,
            tanggalAkhir: tanggalAkhir,
            page: 1,
        }));
        setParamsGarafikPenjualan((prev) => ({
            ...prev,
            tanggalAwal: tanggalAwal,
            tanggalAkhir: tanggalAkhir,
            page: 1,
        }));
        setParamsLaporanMetodePembayaran((prev) => ({
            ...prev,
            tglawal: tanggalAwal,
            tglakhir: tanggalAkhir,
            page: 1,
        }));
        setParamsKesimpulan((prev) => ({
            ...prev,
            tanggal: tanggalAkhir,
        }));
        refetch();
        refecthListInvoice();
        refetchProdukTerjual();
        refetchMutasiStok();
        refetchLaporanMetodePembayaran();
        refetchKesimpulan();
    };

    const handleOnPaginationInvoice = (page: number) => {
        setParamsListInvoice((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChangeInvoice = (size: number) => {
        setParamsListInvoice((prev) => ({ ...prev, size }));
    };

    const handleOnPaginationProdukTerjual = (page: number) => {
        setParamsProdukTerjual((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChangeProdukTerjual = (size: number) => {
        setParamsProdukTerjual((prev) => ({ ...prev, size }));
    };

    const handleOnPaginationMutasiStok = (page: number) => {
        setParamsMutasiStok((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChangeMutasiStok = (size: number) => {
        setParamsMutasiStok((prev) => ({ ...prev, size }));
    };

    const apiExportLaporan = `${apiUrl}api/downloadlaporan?ukmId=${
        ukmIdUser && ukmIdUser[0]
    }&tanggalAwal=${paramsLaporanTotal.tanggalAwal}&tanggalAkhir=${
        paramsLaporanTotal.tanggalAkhir === ""
            ? paramsLaporanTotal.tanggalAwal
            : paramsLaporanTotal.tanggalAkhir
    }`;

    useEffect(() => {
        if (kasir !== null) {
            setParamsListInvoice((prev) => ({
                ...prev,
                kasir: String(kasir.id),
            }));
        } else {
            setParamsListInvoice((prev) => ({
                ...prev,
                kasir: "",
            }));
        }
    }, [kasir]);

    useEffect(() => {
        if (sales !== null) {
            setParamsListInvoice((prev) => ({
                ...prev,
                sales: String(sales.id),
            }));
        } else {
            setParamsListInvoice((prev) => ({
                ...prev,
                sales: "",
            }));
        }
    }, [sales]);

    useEffect(() => {
        if (metodeId !== null) {
            if (metodeId.id === 0) {
                setParamsListInvoice((prev) => ({
                    ...prev,
                    metodeId: "Tunai",
                }));
            } else {
                setParamsListInvoice((prev) => ({
                    ...prev,
                    metodeId: Number(metodeId.id),
                }));
            }
        } else {
            setParamsListInvoice((prev) => ({
                ...prev,
                metodeId: "",
            }));
        }
    }, [metodeId]);

    useEffect(() => {
        if (voidInvoice) {
            setParamsListInvoice((prev) => ({
                ...prev,
                voidInvoice: "void",
            }));
        } else {
            setParamsListInvoice((prev) => ({
                ...prev,
                voidInvoice: "",
            }));
        }
    }, [voidInvoice]);

    useEffect(() => {
        if (ukmIdUser) {
            setParamsGarafikPenjualan((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
            setParamsLaporanTotal((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
            setParamsProdukTerjual((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
            setParamsListInvoice((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
            setParamsKesimpulan((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        console.log(paramsProdukTerjual);
        console.log(grafikPenjualan);
    }, [grafikPenjualan, paramsProdukTerjual]);

    useEffect(() => {
        scrollToHash();
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 100) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);

    // This function will scroll the window to the top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });
    };

    if (
        loadingUser === "loading" ||
        loadingLaporanTotal === "loading" ||
        loadingGrafikPenjualan === "loading"
    ) {
        return (
            <Box
                position="relative"
                height={isLaptopScreen ? "100vh" : "calc(100vh - 56px)"}
            >
                <Spinner />
            </Box>
        );
    }

    if (user?.aksesLaporan === 0 || user?.aksesLaporan === null) {
        return <TokoTidakAktif label="Anda tidak memiliki akses halaman ini" />;
    }

    if (ukmData?.isActive === 0 || ukmData?.isActive === null) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                bgcolor="#ffffff"
                gap={3}
                padding={2}
                sx={{
                    height: isTabletScreen
                        ? "calc(100vh - 64px)"
                        : isPhoneScreen
                        ? "calc(100vh - 56px)"
                        : "100vh",
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <LogoUlo width="100px" />
                    <Typography marginTop={2} textAlign="center">
                        Mohon maaf, ukm anda berstatus tidak aktif
                    </Typography>
                    {ukmData.isPro === 1 ? (
                        <Typography textAlign="center">
                            Anda tetap bisa melakukan unduh laporan.
                        </Typography>
                    ) : null}
                </Box>
                {ukmData.isPro === 1 ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <DatePicker
                            className="range-filter"
                            dateFormat="dd/MM/yyyy"
                            disabledKeyboardNavigation
                            // open={isOpenKalender}
                            onFocus={(e) => e.target.blur()}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                                if (update[1]) {
                                    handleChangeJangkaWaktu(
                                        moment(update[0]).format("YYYY/MM/DD"),
                                        moment(update[1]).format("YYYY/MM/DD"),
                                    );
                                }
                            }}
                            maxDate={today}
                            minDate={
                                ukmData?.isPro === 1
                                    ? subtractYears(1)
                                    : subtractMonths(3)
                            }
                            // showPreviousMonths={
                            //     isPhoneScreen || isTabletScreen ? false : true
                            // }
                            monthsShown={
                                isPhoneScreen || isTabletScreen ? 1 : 2
                            }
                            // inline
                            customInput={
                                <Stack
                                    display="flex"
                                    justifyContent="space-between"
                                    direction="row"
                                    width="100%"
                                    bgcolor="#FFFF"
                                    height="40px"
                                    minWidth="250px"
                                    maxWidth="250px"
                                >
                                    <Typography>
                                        {moment(
                                            paramsProdukTerjual.tanggalAwal,
                                        ).format("DD/MM/YYYY")}{" "}
                                        -{" "}
                                        {paramsProdukTerjual.tanggalAkhir &&
                                            moment(
                                                paramsProdukTerjual.tanggalAkhir,
                                            ).format("DD/MM/YYYY")}
                                    </Typography>
                                    <ArrowDropDownIcon
                                        sx={{
                                            marginRight: -3,
                                        }}
                                    />
                                </Stack>
                            }
                        />
                        <Button
                            disabled={user && !user.akses.laporan.includes(31)}
                            variant="contained"
                            onClick={() => {
                                window.open(apiExportLaporan, "_blank");
                            }}
                            startIcon={<CloudDownloadIcon />}
                            color="ulo"
                            sx={{ minWidth: "200px" }}
                        >
                            Unduh Laporan
                        </Button>
                    </Box>
                ) : (
                    <Button
                        variant="outlined"
                        startIcon={<WhatsAppIcon />}
                        target="_blank"
                        color="ulo"
                        href={`https://api.whatsapp.com/send?phone=${noWaAdmin?.nomor}&text=Kenapa%20toko%20saya%20yaitu%20${ukmData.namaToko}%20berstatus%20tidak%20aktif?`}
                    >
                        Hubungi Kami
                    </Button>
                )}
            </Box>
        );
    }

    return (
        <Box>
            <Box>
                <Stack
                    direction={{
                        xs: "column",
                        sm: "column",
                        md: "column",
                        lg: "row",
                    }}
                    display="flex"
                    justifyContent="space-between"
                    spacing={2}
                    marginY={2}
                >
                    <Typography id="laporan" variant="h6" fontWeight="bold">
                        Laporan
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        display="flex"
                    >
                        <ReactScroll
                            activeClass="active"
                            to="daftarInvoice"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            style={{ width: "fit-content" }}
                        >
                            <Button
                                variant="outlined"
                                startIcon={
                                    isPhoneScreen ||
                                    isTabletScreen ? undefined : (
                                        <ReceiptIcon fontSize="medium" />
                                    )
                                }
                                size="small"
                                sx={{
                                    width: "fit-content",
                                    backgroundColor: "white",
                                }}
                            >
                                {isPhoneScreen || isTabletScreen ? (
                                    <ReceiptIcon fontSize="medium" />
                                ) : (
                                    "Laporan Invoice"
                                )}
                            </Button>
                        </ReactScroll>
                        <ReactScroll
                            activeClass="active"
                            to="laporanMetodePembayaran"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            style={{ width: "fit-content" }}
                        >
                            <Button
                                variant="outlined"
                                startIcon={
                                    isPhoneScreen ||
                                    isTabletScreen ? undefined : (
                                        <IconContext.Provider
                                            value={{
                                                size: "18px",
                                            }}
                                        >
                                            <GiWallet />
                                        </IconContext.Provider>
                                    )
                                }
                                size="small"
                                sx={{
                                    width: "fit-content",
                                    backgroundColor: "white",
                                }}
                            >
                                {isPhoneScreen || isTabletScreen ? (
                                    <IconContext.Provider
                                        value={{
                                            size: "24px",
                                        }}
                                    >
                                        <GiWallet />
                                    </IconContext.Provider>
                                ) : (
                                    "Laporan Metode Pembayaran"
                                )}
                            </Button>
                        </ReactScroll>
                        <ReactScroll
                            activeClass="active"
                            to="mutasiStok"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            style={{ width: "fit-content" }}
                        >
                            <Button
                                variant="outlined"
                                startIcon={
                                    isPhoneScreen ||
                                    isTabletScreen ? undefined : (
                                        <MoveToInboxIcon />
                                    )
                                }
                                size="small"
                                sx={{
                                    width: "fit-content",
                                    backgroundColor: "white",
                                }}
                            >
                                {isPhoneScreen || isTabletScreen ? (
                                    <MoveToInboxIcon />
                                ) : (
                                    "Laporan Mutasi"
                                )}
                            </Button>
                        </ReactScroll>
                        <ReactScroll
                            activeClass="active"
                            to="produkTerjual"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            style={{ width: "fit-content" }}
                        >
                            <Button
                                variant="outlined"
                                startIcon={
                                    isPhoneScreen ||
                                    isTabletScreen ? undefined : (
                                        <ReceiptIcon fontSize="medium" />
                                    )
                                }
                                size="small"
                                sx={{
                                    width: "fit-content",
                                    backgroundColor: "white",
                                }}
                            >
                                {isPhoneScreen || isTabletScreen ? (
                                    <PieChartIcon fontSize="medium" />
                                ) : (
                                    "Stok Terjual"
                                )}
                            </Button>
                        </ReactScroll>
                        {/* <ButtonAnchor
                            to="daftarInvoice"
                            icon={<ReceiptIcon fontSize="medium" />}
                            buttonText="Laporan Invoice"
                        />
                        <ButtonAnchor
                            to="mutasiStok"
                            icon={<MoveToInboxIcon />}
                            buttonText="Laporan Mutasi"
                        />
                        <ButtonAnchor
                            to="#produkTerjual"
                            icon={<PieChartIcon />}
                            buttonText="Stok Terjual"
                        /> */}
                        <Button
                            disabled={user && !user.akses.laporan.includes(31)}
                            variant="contained"
                            onClick={() => {
                                console.log(ukmData);
                                if (ukmData?.isPro === 1) {
                                    window.open(apiExportLaporan, "_blank");
                                } else {
                                    setIsOpenModalAlertPro(true);
                                }
                            }}
                            startIcon={
                                isPhoneScreen || isTabletScreen ? undefined : (
                                    <CloudDownloadIcon />
                                )
                            }
                            size="small"
                            sx={{ width: "fit-content" }}
                        >
                            <Stack direction="row" gap={1} alignItems="center">
                                {isPhoneScreen || isTabletScreen ? (
                                    <CloudDownloadIcon />
                                ) : (
                                    "Unduh Laporan"
                                )}
                                {ukmData?.isPro === 1 ? null : (
                                    <Chip
                                        label="PRO"
                                        size="small"
                                        sx={{
                                            backgroundColor: "#fff",
                                            color: "primary.main",
                                            borderRadius: 1,
                                        }}
                                    />
                                )}
                            </Stack>
                        </Button>
                    </Stack>
                </Stack>
                <Box
                    display="grid"
                    gridTemplateColumns={isPhoneScreen ? "1fr" : "1fr 2fr"}
                    gap={2}
                    width={isPhoneScreen ? "100%" : "60%"}
                    marginBottom={3}
                >
                    <FormControl>
                        <Select
                            value={
                                paramsLaporanTotal.tanggalAwal === now ||
                                paramsLaporanTotal.tanggalAwal ===
                                    moment()
                                        .subtract(7, "days")
                                        .format("YYYY-MM-DD") ||
                                paramsLaporanTotal.tanggalAwal ===
                                    moment()
                                        .subtract(1, "month")
                                        .format("YYYY-MM-DD") ||
                                paramsLaporanTotal.tanggalAwal ===
                                    moment()
                                        .subtract(3, "months")
                                        .format("YYYY-MM-DD") ||
                                paramsLaporanTotal.tanggalAwal ===
                                    moment()
                                        .subtract(1, "years")
                                        .format("YYYY-MM-DD")
                                    ? paramsLaporanTotal.tanggalAwal
                                    : "Custom"
                            }
                            size="small"
                            onChange={handleChangePeriode}
                            sx={{ backgroundColor: "white" }}
                        >
                            <MenuItem value={now}>Hari ini</MenuItem>
                            <MenuItem
                                value={moment()
                                    .subtract(7, "days")
                                    .format("YYYY-MM-DD")}
                            >
                                1 Minggu Terakhir
                            </MenuItem>
                            <MenuItem
                                value={moment()
                                    .subtract(1, "month")
                                    .format("YYYY-MM-DD")}
                            >
                                1 Bulan Terakhir
                            </MenuItem>
                            <MenuItem
                                value={moment()
                                    .subtract(3, "months")
                                    .format("YYYY-MM-DD")}
                            >
                                3 Bulan Terakhir
                            </MenuItem>
                            <MenuItem
                                value={
                                    ukmData?.isPro === 1
                                        ? moment()
                                              .subtract(1, "years")
                                              .format("YYYY-MM-DD")
                                        : "setahun"
                                }
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    color={
                                        ukmData?.isPro === 1
                                            ? undefined
                                            : "#dddddd"
                                    }
                                >
                                    1 Tahun Terakhir
                                </Typography>
                                {ukmData?.isPro === 1 ? null : (
                                    <Chip label="PRO" size="small" />
                                )}
                            </MenuItem>
                            <MenuItem value={"Custom"} sx={{ display: "none" }}>
                                Custom
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Stack
                        direction="column"
                        position="relative"
                        sx={{
                            minWidth: "100%",
                            marginTop: 0,
                        }}
                    >
                        {/* <Button
                            variant="outlined"
                            onClick={() => setIsOpenKalender(!isOpenKalender)}
                            disableRipple
                            disableElevation
                            fullWidth
                            color="inherit"
                            endIcon={<ArrowDropDownIcon />}
                            sx={{
                                borderColor: "#c4c4c4",
                                justifyContent: "space-between",
                                fontSize: "16px",
                                fontWeight: 400,
                                "&:hover": {
                                    borderColor: "black",
                                    bgcolor: "transparent",
                                },
                            }}
                        >
                            {`${moment(paramsProdukTerjual.tanggalAwal).format(
                                "DD/MM/YYYY",
                            )} - ${
                                paramsProdukTerjual.tanggalAkhir &&
                                moment(paramsProdukTerjual.tanggalAkhir).format(
                                    "DD/MM/YYYY",
                                )
                            }`}
                        </Button> */}
                        {/* {isOpenKalender && ( */}
                        {/* <ClickAwayListener
                                 onClickAway={() => setIsOpenKalender(false)}
                             > */}
                        <DatePicker
                            className="range-filter"
                            dateFormat="dd/MM/yyyy"
                            disabledKeyboardNavigation
                            // open={isOpenKalender}
                            onFocus={(e) => e.target.blur()}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                                if (update[1]) {
                                    handleChangeJangkaWaktu(
                                        moment(update[0]).format("YYYY/MM/DD"),
                                        moment(update[1]).format("YYYY/MM/DD"),
                                    );
                                }
                            }}
                            maxDate={today}
                            minDate={
                                ukmData?.isPro === 1
                                    ? subtractYears(1)
                                    : subtractMonths(3)
                            }
                            // showPreviousMonths={
                            //     isPhoneScreen || isTabletScreen ? false : true
                            // }
                            monthsShown={
                                isPhoneScreen || isTabletScreen ? 1 : 2
                            }
                            // inline
                            customInput={
                                <Stack
                                    display="flex"
                                    justifyContent="space-between"
                                    direction="row"
                                    width="100%"
                                    bgcolor="#FFFF"
                                    height="40px"
                                >
                                    <Typography>
                                        {moment(
                                            paramsProdukTerjual.tanggalAwal,
                                        ).format("DD/MM/YYYY")}{" "}
                                        -{" "}
                                        {paramsProdukTerjual.tanggalAkhir &&
                                            moment(
                                                paramsProdukTerjual.tanggalAkhir,
                                            ).format("DD/MM/YYYY")}
                                    </Typography>
                                    <ArrowDropDownIcon
                                        sx={{
                                            marginRight: -3,
                                        }}
                                    />
                                </Stack>
                            }
                        />
                        {/* </ClickAwayListener> */}
                        {/* )} */}
                    </Stack>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <CardLaporanKalkulasi
                            namaCard="Total Pendapatan"
                            onClickAway={() =>
                                setOpenTooltipTotalPendapatan(false)
                            }
                            tooltipTitle={tooltipTotalPendapatan}
                            onClose={() => setOpenTooltipTotalPendapatan(false)}
                            isOpenTooltip={openTooltipTotalPendapatan}
                            onClickTooltip={() =>
                                setOpenTooltipTotalPendapatan((prev) => !prev)
                            }
                            isiCard={toRupiahDesimal(
                                Number(laporanTotal?.totalPendapatan),
                            )}
                            icon={
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    fontWeight="bold"
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    Rp
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <CardLaporanKalkulasi
                            namaCard="Total Transaksi"
                            onClickAway={() =>
                                setOpenTooltipTotalTransaksi(false)
                            }
                            tooltipTitle={tooltipTotalTransaksi}
                            onClose={() => setOpenTooltipTotalTransaksi(false)}
                            isOpenTooltip={openTooltipTotalTransaksi}
                            onClickTooltip={() =>
                                setOpenTooltipTotalTransaksi((prev) => !prev)
                            }
                            isiCard={`${toRibuan(
                                Number(laporanTotal?.totalTransaksi),
                            )} Transaksi`}
                            icon={
                                <InsertChartIcon
                                    sx={{
                                        color: "primary.main",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <CardLaporanKalkulasi
                            namaCard="Jumlah Stok Terjual"
                            onClickAway={() =>
                                setOpenTooltipProdukTerjual(false)
                            }
                            tooltipTitle={tooltipProdukTerjual}
                            onClose={() => setOpenTooltipProdukTerjual(false)}
                            isOpenTooltip={openTooltipProdukTerjual}
                            onClickTooltip={() =>
                                setOpenTooltipProdukTerjual((prev) => !prev)
                            }
                            isiCard={`${toRibuan(
                                Number(laporanTotal?.jumlahStokTerjual),
                            )} Stok`}
                            icon={
                                <PieChartIcon
                                    sx={{
                                        color: "primary.main",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                />
                            }
                        />
                    </Grid>
                </Grid>
                <Grid mt={2}>
                    <Box
                        sx={{
                            backgroundColor: "#FFFFFF",
                            border: 1,
                            borderColor: "#d4d4d4",
                            borderRadius: 1,
                            width: "100%",
                            padding: { xs: 1, lg: 2 },
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Line
                                data={data}
                                options={options}
                                width="100%"
                                height="300"
                            />
                        </Box>
                        <Stack
                            marginTop={1}
                            direction="row"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Stack direction="row">
                                <Box
                                    sx={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                        backgroundColor: "#45A779",
                                    }}
                                ></Box>
                                <Typography marginLeft={1} variant="subtitle2">
                                    Total Pendapatan
                                </Typography>
                            </Stack>
                            <Stack direction="row">
                                <Box
                                    sx={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                        backgroundColor: "#e8d43a",
                                    }}
                                ></Box>
                                <Typography marginLeft={1} variant="subtitle2">
                                    Jumlah Transaksi
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid>
                <Grid container spacing="14px" marginY="24px">
                    <Grid item xs={12}>
                        <Box sx={{ width: "100%" }}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Stack
                                        direction={{ xs: "column", sm: "row" }}
                                        width="100%"
                                        display="flex"
                                        sx={{
                                            alignItems: {
                                                xs: "stretch",
                                                sm: "center",
                                            },
                                            justifyContent: {
                                                xs: "space-between",
                                                sm: "space-between",
                                            },
                                        }}
                                        spacing={2}
                                    >
                                        <Stack
                                            direction={{
                                                xs: "column",
                                                sm: "row",
                                            }}
                                            sx={{
                                                alignItems: {
                                                    sx: "normal",
                                                    sm: "center",
                                                },
                                            }}
                                            spacing={{ xs: 1, sm: 2 }}
                                        >
                                            <Typography
                                                id="daftarInvoice"
                                                fontWeight="bold"
                                            >
                                                Daftar Invoice
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {`Periode: ${moment(
                                                    paramsProdukTerjual.tanggalAwal,
                                                ).format("DD/MM/YYYY")} - ${
                                                    paramsProdukTerjual.tanggalAkhir &&
                                                    moment(
                                                        paramsProdukTerjual.tanggalAkhir,
                                                    ).format("DD/MM/YYYY")
                                                }`}
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction={{
                                                xs: "column",
                                                sm: "row",
                                            }}
                                            sx={{
                                                alignItems: {
                                                    sx: "normal",
                                                    sm: "center",
                                                },
                                            }}
                                            spacing={{ xs: 1, sm: 2 }}
                                        >
                                            <TextField
                                                placeholder="Cari invoice..."
                                                size="small"
                                                value={searchDaftarInvoice}
                                                onChange={(e) =>
                                                    setSearchDaftarInvoice(
                                                        e.target.value,
                                                    )
                                                }
                                                onKeyPress={(e) => {
                                                    e.key === "Enter"
                                                        ? setParamsListInvoice(
                                                              (prev) => ({
                                                                  ...prev,
                                                                  search: searchDaftarInvoice,
                                                                  page: 1,
                                                              }),
                                                          )
                                                        : undefined;
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <Link
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            onClick={() =>
                                                                setParamsListInvoice(
                                                                    (prev) => ({
                                                                        ...prev,
                                                                        search: searchDaftarInvoice,
                                                                        page: 1,
                                                                    }),
                                                                )
                                                            }
                                                            sx={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <SearchIcon fontSize="small" />{" "}
                                                        </Link>
                                                    ),
                                                }}
                                                variant="outlined"
                                            />
                                            {ukmData && (
                                                <BlobProvider
                                                    document={
                                                        <PrintStrukKesimpulan
                                                            namaToko={
                                                                ukmData?.namaToko ??
                                                                "Toko"
                                                            }
                                                            alamatToko={
                                                                ukmData?.alamatToko ??
                                                                "Toko"
                                                            }
                                                            telpToko={
                                                                ukmData?.noTelp ??
                                                                "Toko"
                                                            }
                                                            gambarToko={
                                                                ukmData?.gambarTokoBW ===
                                                                null
                                                                    ? undefined
                                                                    : ukmData?.gambarTokoBW
                                                            }
                                                            tanggalTransaksi={moment(
                                                                paramsKesimpulan.tanggal,
                                                            ).format(
                                                                "DD MMM YYYY",
                                                            )}
                                                            html={undefined}
                                                            kesimpulan={
                                                                kesimpulan ?? []
                                                            }
                                                        />
                                                    }
                                                >
                                                    {({
                                                        blob,
                                                        url,
                                                        loading,
                                                        error,
                                                    }) => {
                                                        return (
                                                            <LoadingButton
                                                                loading={
                                                                    loading ||
                                                                    isLoadingKesimpulan
                                                                }
                                                                loadingPosition="center"
                                                                variant="contained"
                                                                href={url ?? ""}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                Print Tutup Buku
                                                            </LoadingButton>
                                                        );
                                                    }}
                                                </BlobProvider>
                                            )}
                                        </Stack>
                                    </Stack>
                                    <Grid container spacing={2} marginY={2}>
                                        <Grid item xs={12} md={3}>
                                            <Autocomplete
                                                disablePortal
                                                loading={isLoadingUsersByUkm}
                                                options={mappedUsersByUkm ?? []}
                                                getOptionLabel={(option) =>
                                                    option.nama
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) => option.id === value.id}
                                                size="small"
                                                value={kasir}
                                                onChange={(
                                                    _event,
                                                    newKasir: IOptions | null,
                                                ) => {
                                                    setKasir(newKasir);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Pilih kasir"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Autocomplete
                                                disablePortal
                                                loading={isLoadingUsersByUkm}
                                                options={mappedUsersByUkm ?? []}
                                                getOptionLabel={(option) =>
                                                    option.nama
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) => option.id === value.id}
                                                size="small"
                                                value={sales}
                                                onChange={(
                                                    _event,
                                                    newSales: IOptions | null,
                                                ) => {
                                                    setSales(newSales);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Pilih sales"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Autocomplete
                                                disablePortal
                                                loading={
                                                    isLoadingMetodePembayaran
                                                }
                                                options={
                                                    mappedMetodePembayaran ?? []
                                                }
                                                getOptionLabel={(option) =>
                                                    option.nama
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) => option.id === value.id}
                                                size="small"
                                                value={metodeId}
                                                onChange={(
                                                    _event,
                                                    newMetodeId: IOpsiMetodePembayaran | null,
                                                ) => {
                                                    setMetodeId(newMetodeId);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Pilih metode"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={voidInvoice}
                                                        onChange={
                                                            handleChangeVoidInvoice
                                                        }
                                                        inputProps={{
                                                            "aria-label":
                                                                "controlled",
                                                        }}
                                                    />
                                                }
                                                label="Void Invoice?"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box
                                        marginTop={2}
                                        sx={{
                                            height: 630,
                                            width: "100%",
                                            borderRadius: 1,
                                            "& .headerColumn": {
                                                backgroundColor: "#E4EEE8",
                                            },
                                            "& .MuiDataGrid-columnHeaderTitle":
                                                {
                                                    fontWeight: "bold",
                                                },
                                            "& .MuiDataGrid-columnSeparator": {
                                                visibility: "hidden",
                                            },
                                            "& .MuiDataGrid-cell:focus-within":
                                                {
                                                    outline: "none !important",
                                                },
                                            "& .MuiDataGrid-iconButtonContainer":
                                                {
                                                    visibility: "visible",
                                                    width: "0 !important",
                                                },
                                        }}
                                    >
                                        {/* <ThemeProvider theme={theme}> */}
                                        <DataGrid
                                            pagination
                                            rows={listInvoice?.content ?? []}
                                            columns={columnsListInvoice}
                                            components={{
                                                ColumnUnsortedIcon: () => {
                                                    return <ImportExportIcon />;
                                                },
                                                NoRowsOverlay: NoRowsImage,
                                                LoadingOverlay: LoadingTabel,
                                            }}
                                            rowsPerPageOptions={[10]}
                                            disableColumnMenu
                                            disableSelectionOnClick
                                            paginationMode="server"
                                            onPageSizeChange={
                                                handleOnSizeChangeInvoice
                                            }
                                            onPageChange={
                                                handleOnPaginationInvoice
                                            }
                                            rowCount={
                                                listInvoice?.totalElements ?? 0
                                            }
                                            page={paramsListInvoice.page - 1}
                                            pageSize={paramsListInvoice.size}
                                            loading={isLoadingListInvoice}
                                        />
                                        {/* </ThemeProvider> */}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ width: "100%" }}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Stack
                                        direction={{ xs: "column", sm: "row" }}
                                        width="100%"
                                        display="flex"
                                        sx={{
                                            alignItems: {
                                                xs: "stretch",
                                                sm: "center",
                                            },
                                            justifyContent: {
                                                xs: "space-between",
                                                sm: "space-between",
                                            },
                                        }}
                                        spacing={2}
                                    >
                                        <Stack
                                            direction={{
                                                xs: "column",
                                                sm: "row",
                                            }}
                                            sx={{
                                                alignItems: {
                                                    sx: "normal",
                                                    sm: "center",
                                                },
                                            }}
                                            spacing={{ xs: 1, sm: 2 }}
                                        >
                                            <Typography
                                                id="laporanMetodePembayaran"
                                                fontWeight="bold"
                                            >
                                                Metode Pembayaran
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {`Periode: ${moment(
                                                    paramsLaporanMetodePembayaran.tglawal,
                                                ).format("DD/MM/YYYY")} - ${
                                                    paramsLaporanMetodePembayaran.tglakhir &&
                                                    moment(
                                                        paramsLaporanMetodePembayaran.tglakhir,
                                                    ).format("DD/MM/YYYY")
                                                }`}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <TableContainer
                                        component={Paper}
                                        variant="outlined"
                                        sx={{
                                            marginTop: 3,
                                            borderRadius: 1,
                                            backgroundColor: "#fff",
                                            border: "1px solid rgba(0,0,0,0.12)",
                                            display:
                                                isLoadingLaporanMetodePembayaran
                                                    ? "flex"
                                                    : undefined,

                                            justifyContent:
                                                isLoadingLaporanMetodePembayaran
                                                    ? "center"
                                                    : undefined,

                                            alignItems:
                                                isLoadingLaporanMetodePembayaran
                                                    ? "center"
                                                    : undefined,

                                            padding: 2,
                                            minHeight: 300,
                                            width: "100%",
                                        }}
                                    >
                                        {isLoadingLaporanMetodePembayaran ? (
                                            <img
                                                src={logoUloGif}
                                                height="100px"
                                                width="100px"
                                                style={{ objectFit: "contain" }}
                                                alt="loading..."
                                            />
                                        ) : (
                                            <Table
                                                sx={{ minWidth: 700 }}
                                                size="small"
                                                aria-label="spanning table"
                                            >
                                                <TableHead>
                                                    <TableRow
                                                        sx={{
                                                            backgroundColor:
                                                                "primary.main",
                                                        }}
                                                    >
                                                        <TableCell
                                                            colSpan={2}
                                                            sx={{
                                                                color: "#fff",
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Metode Pembayaran
                                                        </TableCell>
                                                        <TableCell
                                                            colSpan={2}
                                                            align="center"
                                                            sx={{
                                                                color: "#fff",
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Jumlah Transaksi
                                                        </TableCell>
                                                        <TableCell
                                                            colSpan={2}
                                                            align="right"
                                                            sx={{
                                                                color: "#fff",
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Jumlah Pendapatan
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {laporanMetodePembayaran?.content.map(
                                                    (li) => (
                                                        <TableBody
                                                            key={li.metodeId}
                                                        >
                                                            <TableRow>
                                                                <TableCell
                                                                    colSpan={2}
                                                                >
                                                                    {
                                                                        li.metodeNamma
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    colSpan={2}
                                                                    align="center"
                                                                    sx={{
                                                                        borderLeft:
                                                                            "1px solid rgba(0,0,0,0.12)",
                                                                        borderRight:
                                                                            "1px solid rgba(0,0,0,0.12)",
                                                                    }}
                                                                >
                                                                    {
                                                                        li.totalTransaksi
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    colSpan={2}
                                                                    align="right"
                                                                >
                                                                    {toRupiahDesimal(
                                                                        Number(
                                                                            li.totalPendapatan,
                                                                        ),
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    ),
                                                )}
                                                <TableFooter
                                                    sx={{
                                                        borderTop:
                                                            "2px solid rgba(0,0,0,0.12)",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={2}
                                                            sx={{
                                                                fontSize: 14,
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            TOTAL
                                                        </TableCell>
                                                        <TableCell
                                                            colSpan={2}
                                                            align="center"
                                                            sx={{
                                                                fontSize: 14,
                                                                fontWeight:
                                                                    "bold",
                                                                borderLeft:
                                                                    "1px solid rgba(0,0,0,0.12)",
                                                                borderRight:
                                                                    "1px solid rgba(0,0,0,0.12)",
                                                            }}
                                                        >
                                                            {totalTransaksi}
                                                        </TableCell>
                                                        <TableCell
                                                            colSpan={2}
                                                            align="right"
                                                            sx={{
                                                                fontSize: 14,
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {toRupiahDesimal(
                                                                Number(
                                                                    totalPendapatan,
                                                                ),
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        )}
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ width: "100%" }}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Stack
                                        direction={{ xs: "column", sm: "row" }}
                                        width="100%"
                                        display="flex"
                                        sx={{
                                            alignItems: {
                                                xs: "stretch",
                                                sm: "center",
                                            },
                                            justifyContent: {
                                                xs: "space-between",
                                                sm: "space-between",
                                            },
                                        }}
                                        spacing={2}
                                    >
                                        <Stack
                                            direction={{
                                                xs: "column",
                                                sm: "row",
                                            }}
                                            sx={{
                                                alignItems: {
                                                    sx: "normal",
                                                    sm: "center",
                                                },
                                            }}
                                            spacing={{ xs: 1, sm: 2 }}
                                        >
                                            <Typography
                                                id="mutasiStok"
                                                fontWeight="bold"
                                            >
                                                Mutasi Stok
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {`Periode: ${moment(
                                                    paramsProdukTerjual.tanggalAwal,
                                                ).format("DD/MM/YYYY")} - ${
                                                    paramsProdukTerjual.tanggalAkhir &&
                                                    moment(
                                                        paramsProdukTerjual.tanggalAkhir,
                                                    ).format("DD/MM/YYYY")
                                                }`}
                                            </Typography>
                                        </Stack>
                                        <TextField
                                            placeholder="Cari barang mutasi..."
                                            size="small"
                                            value={searchMutasiStok}
                                            onChange={(e) =>
                                                setSearchMutasiStok(
                                                    e.target.value,
                                                )
                                            }
                                            onKeyPress={(e) => {
                                                e.key === "Enter"
                                                    ? setParamsMutasiStok(
                                                          (prev) => ({
                                                              ...prev,
                                                              search: searchMutasiStok,
                                                              page: 1,
                                                          }),
                                                      )
                                                    : undefined;
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <Link
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        onClick={() =>
                                                            setParamsMutasiStok(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    search: searchMutasiStok,
                                                                    page: 1,
                                                                }),
                                                            )
                                                        }
                                                        sx={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <SearchIcon fontSize="small" />{" "}
                                                    </Link>
                                                ),
                                            }}
                                            variant="outlined"
                                        />
                                    </Stack>
                                    <Box
                                        marginTop={2}
                                        sx={{
                                            height: 630,
                                            width: "100%",
                                            borderRadius: 1,
                                            "& .headerColumn": {
                                                backgroundColor: "#E4EEE8",
                                            },
                                            "& .MuiDataGrid-columnHeaderTitle":
                                                {
                                                    fontWeight: "bold",
                                                },
                                            "& .MuiDataGrid-columnSeparator": {
                                                visibility: "hidden",
                                            },
                                            "& .MuiDataGrid-cell:focus-within":
                                                {
                                                    outline: "none !important",
                                                },
                                            "& .MuiDataGrid-iconButtonContainer":
                                                {
                                                    visibility: "visible",
                                                    width: "0 !important",
                                                },
                                        }}
                                    >
                                        {/* <ThemeProvider theme={theme}> */}
                                        <DataGrid
                                            pagination
                                            rows={mutasiStok?.content ?? []}
                                            getRowId={(row) => row.itemId}
                                            columns={columnsMutasiStok}
                                            components={{
                                                ColumnUnsortedIcon: () => {
                                                    return <ImportExportIcon />;
                                                },
                                                NoRowsOverlay: NoRowsImage,
                                                LoadingOverlay: LoadingTabel,
                                            }}
                                            rowsPerPageOptions={[10]}
                                            disableColumnMenu
                                            disableSelectionOnClick
                                            paginationMode="server"
                                            onPageSizeChange={
                                                handleOnSizeChangeMutasiStok
                                            }
                                            onPageChange={
                                                handleOnPaginationMutasiStok
                                            }
                                            rowCount={
                                                mutasiStok?.totalElements ?? 0
                                            }
                                            page={paramsMutasiStok.page - 1}
                                            pageSize={paramsMutasiStok.size}
                                            loading={isLoadingMutasiStok}
                                        />
                                        {/* </ThemeProvider> */}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ width: "100%" }}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Stack
                                        direction={{
                                            xs: "column",
                                            sm: "row",
                                        }}
                                        width="100%"
                                        display="flex"
                                        sx={{
                                            alignItems: {
                                                xs: "stretch",
                                                sm: "center",
                                            },
                                            justifyContent: {
                                                xs: "space-between",
                                                sm: "space-between",
                                            },
                                        }}
                                        spacing={2}
                                    >
                                        <Stack
                                            direction={{
                                                xs: "column",
                                                sm: "row",
                                            }}
                                            sx={{
                                                alignItems: {
                                                    sx: "normal",
                                                    sm: "center",
                                                },
                                            }}
                                            spacing={{ xs: 1, sm: 2 }}
                                        >
                                            <Typography
                                                id="produkTerjual"
                                                fontWeight="bold"
                                            >
                                                Stok Terjual
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {`Periode: ${moment(
                                                    paramsProdukTerjual.tanggalAwal,
                                                ).format("DD/MM/YYYY")} - ${
                                                    paramsProdukTerjual.tanggalAkhir &&
                                                    moment(
                                                        paramsProdukTerjual.tanggalAkhir,
                                                    ).format("DD/MM/YYYY")
                                                }`}
                                            </Typography>
                                        </Stack>
                                        <TextField
                                            placeholder="Cari barang..."
                                            size="small"
                                            value={searchProdukTerjual}
                                            onChange={(e) =>
                                                setSearchProdukTerjual(
                                                    e.target.value,
                                                )
                                            }
                                            onKeyPress={(e) => {
                                                e.key === "Enter"
                                                    ? setParamsProdukTerjual(
                                                          (prev) => ({
                                                              ...prev,
                                                              search: searchProdukTerjual,
                                                              page: 1,
                                                          }),
                                                      )
                                                    : undefined;
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <Link
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        onClick={() =>
                                                            setParamsProdukTerjual(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    search: searchProdukTerjual,
                                                                    page: 1,
                                                                }),
                                                            )
                                                        }
                                                        sx={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <SearchIcon fontSize="small" />{" "}
                                                    </Link>
                                                ),
                                            }}
                                            variant="outlined"
                                        />
                                    </Stack>
                                    <Box
                                        marginTop={2}
                                        sx={{
                                            height: 630,
                                            width: "100%",
                                            borderRadius: 1,
                                            "& .headerColumn": {
                                                backgroundColor: "#E4EEE8",
                                            },
                                            "& .MuiDataGrid-columnHeaderTitle":
                                                {
                                                    fontWeight: "bold",
                                                },
                                            "& .MuiDataGrid-columnSeparator": {
                                                visibility: "hidden",
                                            },
                                            "& .MuiDataGrid-cell:focus-within":
                                                {
                                                    outline: "none !important",
                                                },
                                            "& .MuiDataGrid-iconButtonContainer":
                                                {
                                                    visibility: "visible",
                                                    width: "0 !important",
                                                },
                                        }}
                                    >
                                        {/* <ThemeProvider theme={theme}> */}
                                        <DataGrid
                                            pagination
                                            rows={produkTerjual?.content ?? []}
                                            columns={columns}
                                            components={{
                                                ColumnUnsortedIcon: () => {
                                                    return <ImportExportIcon />;
                                                },
                                                NoRowsOverlay: NoRowsImage,
                                                LoadingOverlay: LoadingTabel,
                                            }}
                                            rowsPerPageOptions={[10]}
                                            disableColumnMenu
                                            disableSelectionOnClick
                                            paginationMode="server"
                                            onPageSizeChange={
                                                handleOnSizeChangeProdukTerjual
                                            }
                                            onPageChange={
                                                handleOnPaginationProdukTerjual
                                            }
                                            rowCount={
                                                produkTerjual?.totalElements ??
                                                0
                                            }
                                            page={paramsProdukTerjual.page - 1}
                                            pageSize={paramsProdukTerjual.size}
                                            loading={isLoadingProdukTerjual}
                                        />
                                        {/* </ThemeProvider> */}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    display={showButton ? "block" : "none"}
                    position="fixed"
                    right={30}
                    bottom={30}
                >
                    <Fab color="primary" onClick={scrollToTop}>
                        <KeyboardArrowUpIcon />
                    </Fab>
                </Box>
                {detailInvoice && (
                    <ModalDetailInvoice
                        fullScreen={fullScreen}
                        isOpenModalDetailInvoice={isOpenModalDetailInvoice}
                        statusDetailInvoice={statusDetailInvoice}
                        closeModalDetailInvoice={closeModalDetailinvoice}
                        tanggalTransaksi={String(
                            detailInvoice.tanggalTransaksi,
                        )}
                        nomorInvoice={String(detailInvoice.nomorInvoice)}
                        daftarItem={detailInvoice.daftarItem}
                        totalHarga={Number(detailInvoice.totalHarga)}
                        diskon={Number(detailInvoice.diskon)}
                        totalPembayaran={Number(detailInvoice.totalPembayaran)}
                        uangYangDibayar={Number(detailInvoice.uangYangDibayar)}
                        kembalian={Number(detailInvoice.kembalian)}
                        kasir={detailInvoice.kasir}
                        sales={detailInvoice.sales}
                        pajak={Number(detailInvoice.pajak)}
                        dpp={Number(detailInvoice.dpp)}
                        namaMetode={detailInvoice.metode}
                        keterangan={detailInvoice.keterangan}
                        encrypt={detailInvoice.encrypt}
                        noInvoice={detailInvoice.nomorInvoice}
                        ongkir={detailInvoice.ongkir}
                        ketOngkir={detailInvoice.ketOngkir}
                        customAmount={detailInvoice.customAmount}
                        ketCustomAmount={detailInvoice.ketCustomAmount}
                        jatuhTempo={jatuhTempo}
                        metodePelanggan={
                            customer.id
                                ? customer.nama
                                : detailInvoice.metodePelanggan
                        }
                    />
                )}
                <ModalAlertPro
                    isOpenModalAlertPro={isOpenModalAlertPro}
                    closeModalAlertPro={() => setIsOpenModalAlertPro(false)}
                    namaToko={ukmData?.namaToko}
                />
                {detailInvoice && (
                    <ModalRevertInvoice
                        diskon={Number(detailInvoice?.diskon)}
                        totalHarga={Number(detailInvoice?.totalHarga)}
                        totalTagihan={Number(detailInvoice?.totalPembayaran)}
                        uangDibayar={Number(detailInvoice?.uangYangDibayar)}
                        kembalian={Number(detailInvoice?.kembalian)}
                        refetchLaporan={refetch}
                        refetchListInvoice={refecthListInvoice}
                        refetchProdukTerjual={refetchProdukTerjual}
                        refetchMutasiStok={refetchMutasiStok}
                        refetchGrafikPenjualan={refetchGrafikPenjualan}
                        item={detailInvoice?.daftarItem}
                        statusItem={statusDetailInvoice}
                        invoiceId={Number(id)}
                        isOpenModalRevertInvoice={isOpenModalRevertInvoice}
                        closeModalRevertInvoice={() =>
                            setIsOpenModalRevertInvoice(false)
                        }
                        customer={customer}
                        ongkir={detailInvoice.ongkir}
                        ketOngkir={detailInvoice.ketOngkir}
                        customAmount={detailInvoice.customAmount}
                        ketCustomAmount={detailInvoice.ketCustomAmount}
                        metodeId={metode}
                    />
                )}
                <ModalDetailMutasi
                    fullScreen={fullScreen}
                    isOpenModalDetailMutasi={isOpenModalDetailMutasi}
                    closeModalDetailMutasi={() =>
                        setIsOpenModalDetailMutasi(false)
                    }
                    keteranganMasuk={keteranganMasuk}
                    keteranganKeluar={keteranganKeluar}
                    detailMutasi={detailMutasi}
                />
            </Box>
            <LoadingOverlay open={statusDetailInvoice === "loading"} />
        </Box>
    );
};

export default Laporan;
