import React from "react";
import { Text, Font, View, StyleSheet } from "@react-pdf/renderer";
import toRibuan from "../../utils/toRibuan";

Font.register({
    family: "Open-Sans-Bold",
    src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
});

interface ICart {
    id: number;
    namaBarang: string;
    sku?: string | undefined;
    qty: number;
    harga: number;
    jumlahStok?: number | undefined;
}

interface IPrintStrukA5SubtotalProps {
    cart?: ICart[];
}

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
        fontFamily: "Open-Sans-Bold",
        borderLeftColor: "#fff",
        borderLeftWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
        fontSize: 8,
    },
    description: {
        width: "70%",
        textAlign: "right",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 18,
    },
    subtotalText: {
        width: "15%",
        textAlign: "right",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 18,
    },
    subtotal: {
        width: "15%",
        textAlign: "right",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        paddingHorizontal: 8,
        height: 18,
    },
});

const PrintStrukA5Subtotal = ({ cart }: IPrintStrukA5SubtotalProps) => {
    const total = cart
        ?.map((df) => df.qty * df.harga)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return (
        <View style={styles.row}>
            <Text style={styles.description}> </Text>
            <Text style={styles.subtotalText}>Subtotal</Text>
            <Text style={styles.subtotal}>{toRibuan(Number(total))}</Text>
        </View>
    );
};

export default PrintStrukA5Subtotal;
