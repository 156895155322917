/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
    Box,
    TextField,
    Button,
    Typography,
    Link,
    Stack,
    Chip,
    Pagination,
} from "@mui/material";
import React, { useState } from "react";
import { ParamsGetUkm } from "../../constants/types";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import Spinner from "../Spinner/Spinner";
import ModalUkm from "../ModalUkm/ModalUkm";
import ModalAlertPro from "../ModalAlertPro/ModalAlertPro";
import ModalDeleteUkm from "../ModalDeleteUkm/ModalDeleteUkm";
import CardInfoToko from "./CardInfoToko";
import { useUkmModal } from "../../context/ukmModalContext";
import { getLocalUkmId, setLocalUkmId } from "../../utils/handleChangeUkmId";
import Select, { StylesConfig } from "react-select";

interface IInfoTokoProps {}

interface IOption {
    value: number;
    label: string;
}

const InfoToko = (props: IInfoTokoProps) => {
    const { data: user, refetch: refetchUser } = useUser();
    const initialParamsUkm = {
        size: 4,
        page: 1,
        search: "",
    };
    const initialParamsAllUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const [paramsUkm, setParamsUkm] = useState<ParamsGetUkm>(initialParamsUkm);
    const {
        data: ukm,
        status: statusUkm,
        refetch: refetchUkm,
        isLoading: isLoadingUkm,
    } = useUkm(paramsUkm);
    const { data: allUkm } = useUkm(initialParamsAllUkm);
    const { openModalUkm } = useUkmModal();
    const [search, setSearch] = useState("");
    const optionsUkm = React.useMemo(
        () =>
            allUkm?.content.map((ukms, index) => ({
                value: ukms.id,
                label: ukms.namaToko,
            })),
        [allUkm?.content],
    );
    const [isOpenModalAlertPro, setIsOpenModalAlertPro] = useState(false);

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const defaultSelected = optionsUkm?.find((li) => li.value === ukmId);
    const [selectUkm, setSelectUkm] = React.useState<IOption | undefined>(
        defaultSelected,
    );

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return allUkm?.content.find((li) => li.id === ukmId);
        }
        return allUkm?.content[0];
    }, [allUkm?.content, ukmId]);

    const customStyles: StylesConfig<any> = {
        control: (base: any, state: any) => ({
            ...base,
            background: "#FFFF",
            borderRadius: 8,
            borderColor: state.isFocused ? ukmData?.warna : "#d4d4d4",
            borderWidth: 1,
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: state.isFocused ? ukmData?.warna : "black",
            },
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? "#f5f5f5"
                    : isFocused
                    ? "#f5f5f5"
                    : undefined,
                color: isDisabled
                    ? "#ccc"
                    : isSelected
                    ? "black"
                        ? "black"
                        : "black"
                    : "black",
                cursor: isDisabled ? "not-allowed" : "default",

                ":active": {
                    ...styles[":active"],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? "black"
                            : "white"
                        : undefined,
                },
            };
        },
        input: (styles) => ({ ...styles, width: 150 }),
        placeholder: (styles) => ({ ...styles }),
        singleValue: (styles, { data }) => ({
            ...styles,
            borderBlockEndWidth: 0,
        }),
    };

    if (statusUkm === "loading") {
        return (
            <Stack
                display="grid"
                gridTemplateColumns="1fr"
                alignItems="center"
                height={400}
                position="relative"
            >
                <Spinner />
            </Stack>
        );
    }

    return (
        <React.Fragment>
            <Stack
                direction="row"
                spacing={2}
                marginBottom={2}
                alignItems="center"
            >
                <Typography variant="h6" fontWeight="bold">
                    Pindah UKM
                </Typography>
                {ukmData?.isPro === 1 ? null : (
                    <Chip
                        label="PRO"
                        size="small"
                        onClick={() => setIsOpenModalAlertPro(true)}
                    />
                )}
            </Stack>
            <Stack
                component={ukmData?.isPro === 0 ? Link : Stack}
                onClick={() => {
                    if (ukmData?.isPro === 1) {
                        return;
                    }
                    setIsOpenModalAlertPro(true);
                }}
                underline="none"
                direction="column"
                spacing={1}
                marginTop={2}
                sx={{
                    width: { xs: 300, sm: 400 },
                    cursor: ukmData?.isPro === 0 ? "pointer" : undefined,
                }}
            >
                <Select
                    styles={customStyles}
                    value={selectUkm}
                    options={optionsUkm}
                    onChange={(e) => {
                        const selected = optionsUkm?.find(
                            (li) => li.value === e?.value,
                        );
                        if (selected) {
                            setSelectUkm(selected);
                        }
                    }}
                    isLoading={isLoadingUkm}
                    isDisabled={ukmData?.isPro === 0}
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                    menuPlacement="auto"
                />
                <Button
                    disabled={ukmData?.isPro === 0}
                    variant="contained"
                    onClick={() => {
                        if (selectUkm?.value) {
                            user?.userId &&
                                setLocalUkmId(user?.userId, selectUkm?.value);
                            window.location.reload();
                        }
                    }}
                >
                    Simpan Perubahan
                </Button>
            </Stack>
            <Typography
                variant="h6"
                marginBottom={2}
                marginTop={8}
                fontWeight="bold"
            >
                Pengaturan UKM
            </Typography>
            <Stack
                display="flex"
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                marginBottom={2}
            >
                <TextField
                    placeholder="Cari nama ukm..."
                    size="small"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={(e) => {
                        e.key === "Enter"
                            ? setParamsUkm((prev) => ({
                                  ...prev,
                                  search: search,
                                  page: 1,
                              }))
                            : undefined;
                    }}
                    InputProps={{
                        endAdornment: (
                            <Link
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                onClick={() =>
                                    setParamsUkm((prev) => ({
                                        ...prev,
                                        search: search,
                                        page: 1,
                                    }))
                                }
                                sx={{ cursor: "pointer" }}
                            >
                                <SearchIcon fontSize="small" />
                            </Link>
                        ),
                        style: {
                            backgroundColor: "white",
                        },
                    }}
                    variant="outlined"
                />
                <Button
                    variant="contained"
                    size="large"
                    startIcon={<AddIcon />}
                    onClick={() => openModalUkm()}
                    sx={{ display: "none", height: "40px" }}
                >
                    Tambah UKM
                </Button>
            </Stack>
            <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
                gap={3}
            >
                {ukm && ukm.totalElements > 0 ? (
                    ukm.content.map((ukms) => (
                        <CardInfoToko
                            key={ukms.id}
                            id={ukms.id}
                            namaToko={ukms.namaToko}
                            gambarToko={ukms.gambarToko}
                            alamatToko={ukms.alamatToko}
                            noTelp={ukms.noTelp}
                            warna={ukms.warna}
                            deskripsi={ukms.deskripsi}
                            besarUsaha={ukms.besarUsaha}
                            jenisUsaha={ukms.jenisUsaha}
                            omset={ukms.omset}
                            isPro={ukms.isPro}
                            isActive={ukms.isActive}
                        />
                    ))
                ) : (
                    <Box
                        display="flex"
                        flex={1}
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                    >
                        <Typography variant="h5" fontWeight="bold">
                            UKM tidak ditemukan
                        </Typography>
                    </Box>
                )}
            </Box>
            <Box display="flex" justifyContent="center" paddingY={2}>
                <Pagination
                    color="primary"
                    count={Math.ceil(
                        Number(
                            ukm && ukm?.totalElements / initialParamsUkm.size,
                        ),
                    )}
                    page={paramsUkm.page}
                    onChange={(event, value) => {
                        setParamsUkm((prev) => ({
                            ...prev,
                            page: value,
                        }));
                    }}
                    siblingCount={0}
                    // sx={{ justifyContent: "center" }}
                />
            </Box>
            <ModalUkm refetchUkm={refetchUkm} refetchUser={refetchUser} />
            <ModalDeleteUkm refetch={refetchUkm} />
            <ModalAlertPro
                isOpenModalAlertPro={isOpenModalAlertPro}
                closeModalAlertPro={() => setIsOpenModalAlertPro(false)}
                namaToko={ukmData?.namaToko}
            />
        </React.Fragment>
    );
};

export default InfoToko;
