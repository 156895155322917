/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    SelectedProductBundling,
    ParamsGetItem,
} from "../../../constants/types";
import useItem from "../../../services/queries/useItem";
import useKategori from "../../../services/queries/useKategori";
import useUser from "../../../services/queries/useUser";
import toRupiah from "../../../utils/toRupiah";
import Spinner from "../../Spinner/Spinner";
import SearchIcon from "@mui/icons-material/Search";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { useAuth } from "../../../context/authContext";
import "./PilihItemStep.css";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useStokModal } from "../../../context/stokModalContext";
import NoRowsImage from "../../NoRowsImage/NoRowsImage";
import LoadingTabel from "../../LoadingTabel/LoadingTabel";
import toRupiahDesimal from "../../../utils/toRupiahDesimal";

interface IPilihItemStepProps {
    addProducts: (item: SelectedProductBundling[]) => void;
}

const PilihItemStep = ({ addProducts }: IPilihItemStepProps) => {
    const { data: user, status: statusUser } = useUser();
    const { ukmIdUser } = useAuth();
    const { dataStok } = useStokModal();

    const initialParamsStok: ParamsGetItem = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        kategori: "",
        size: 10,
        page: 1,
    };

    const initialParamsKategori: ParamsGetItem = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        kategori: "",
        size: 500,
        page: 1,
    };

    const [paramsStok, setParamsStok] = useState(initialParamsStok);
    const [paramsKategori, setParamsKategori] = useState(initialParamsKategori);
    const [search, setSearch] = useState<string>("");
    const [selectedKategori, setSelectedKategori] = useState<string>("");

    const { data: stok, isLoading: isLoadingStok } = useItem(paramsStok);
    const { data: kategori, status: statusKategori } =
        useKategori(paramsKategori);

    // const filteredRows = stok?.content.filter((value) => value.stok > 0);
    const [selectedProducts, setSelectedProducts] = useState<
        SelectedProductBundling[]
    >([]);

    const columns: GridColDef[] = [
        {
            field: "namaBarang",
            headerName: "Nama Barang",
            width: 300,
            flex: 2,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value}>
                        <span className="table-cell-trucate">
                            {params.value}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "kategori",
            headerName: "Kategori",
            headerAlign: "center",
            align: "center",
            // width: 150,
            flex: 1,
            minWidth: 125,
            valueGetter: (params) => params.row.kategori,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value.nama}>
                        <span className="table-cell-trucate">
                            {params.value.nama}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "multipleHarga",
            headerName: "Harga",
            headerAlign: "center",
            align: "center",
            // type: "number",
            // width: 150,
            flex: 1,
            minWidth: 125,
            valueGetter: (params) => params.value ?? 0,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={toRupiahDesimal(Number(params.value[0].harga))}
                    >
                        <span className="table-cell-trucate">
                            {toRupiahDesimal(Number(params.value[0].harga))}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "sku",
            headerName: "SKU",
            headerAlign: "center",
            align: "center",
            // type: "number",
            // width: 150,
            flex: 1,
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value}>
                        <span className="table-cell-trucate">
                            {params.value}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "stok",
            headerName: "Stok",
            headerAlign: "center",
            align: "center",
            // type: "number",
            // width: 150,
            flex: 1,
            minWidth: 100,
            valueGetter: (paramsStok) =>
                paramsStok.value === null ? "\u221e" : paramsStok.value,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={params.value === null ? "\u221e" : params.value}
                    >
                        <span className="table-cell-trucate">
                            {params.value === null ? "\u221e" : params.value}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            align: "center",
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <IconButton
                        disabled={
                            (params.row.stok <= 0 &&
                                params.row.stok !== null) ||
                            params.row.isBundling > 0
                        }
                        onClick={() => {
                            handleSelectedProducts(
                                params.row.id,
                                params.row.namaBarang,
                                params.row.harga,
                                params.row.stok,
                            );
                            // setSelectedProducts((prev) => [
                            //     ...prev,
                            //     {
                            //         id: params.row.id,
                            //         nama: params.row.namaBarang,
                            //         sku: params.row.sku,
                            //         harga: params.row.harga,
                            //         stok: params.row.stok,
                            //     },
                            // ]);
                        }}
                        sx={{
                            minWidth: "unset",
                            padding: "6px",
                            width: "fit-content",
                        }}
                        // startIcon={<EditIcon />}
                        size="small"
                        color="primary"
                    >
                        {isSelected(params.row.id) ? (
                            <CheckBoxIcon />
                        ) : (
                            <CheckBoxOutlineBlankIcon />
                        )}
                    </IconButton>
                );
            },
        },
    ];

    const stokAllProduct = useMemo(() => {
        if (stok && stok.content.length > 0) {
            return stok.content
                ?.filter((item) => item.isBundling === 0)
                .map((list) => ({
                    id: list.id,
                    nama: list.namaBarang,
                    harga: list.harga,
                    stok: list.stok,
                }));
        }
        setSelectedProducts([]);
    }, [stok]);

    const truncate = (str: string) => {
        return str.length > 15 ? str.substring(0, 12) + "..." : str;
    };

    const handleClikAllProduct = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                console.log(event.target.checked);
                setSelectedProducts(stokAllProduct ?? []);
                return;
            }
            return setSelectedProducts([]);
        },
        [stokAllProduct],
    );

    useEffect(() => {
        const timeoutId = setTimeout(
            () =>
                setParamsStok((prev) => ({
                    ...prev,
                    search: search,
                })),
            1000,
        );
        return () => clearTimeout(timeoutId);
    }, [search]);

    useEffect(() => {
        if (user) {
            setParamsStok((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));

            setParamsKategori((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser, user]);

    const handleOnPagination = (page: number) => {
        setParamsStok((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsStok((prev) => ({ ...prev, size }));
    };

    const handleSearchStok = () => {
        setParamsStok((prev) => ({
            ...prev,
            search,
            kategori: selectedKategori,
        }));
    };

    const isSelected = (id: number) => {
        const filteredProduct = selectedProducts.filter(
            (li) => li.id === id,
        ).length;
        if (filteredProduct > 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSelectedProducts = (
        id: number,
        nama: string,
        harga: number,
        stok: number,
    ) => {
        if (isSelected(id)) {
            const newList = selectedProducts.filter((li) => li.id !== id);
            setSelectedProducts(newList);
        } else {
            setSelectedProducts((prev) => [
                ...prev,
                {
                    id,
                    nama,
                    harga,
                    stok,
                },
            ]);
        }
    };

    // const handleSelectedProducts = (
    //     ids: GridSelectionModel,
    //     _: GridCallbackDetails,
    // ) => {
    //     const selectedRows: SelectedProductBundling[] | undefined =
    //         stok?.content
    //             .filter((li) => ids.includes(li.id))
    //             .map((li) => ({
    //                 id: li.id,
    //                 nama: li.namaBarang,
    //                 harga: li.harga,
    //                 stok: li.stok,
    //             }));
    //     addProducts(selectedRows ?? []);
    // };

    useEffect(() => {
        addProducts(selectedProducts ?? []);
    }, [addProducts, selectedProducts]);

    const isLoading = statusUser === "loading" || statusKategori === "loading";

    if (isLoading) {
        return (
            <Stack
                display="grid"
                gridTemplateColumns="1fr"
                alignItems="center"
                height={400}
                position="relative"
            >
                <Spinner />
            </Stack>
        );
    }

    return (
        <Box className="pilih-item">
            {dataStok ? (
                <Box mb={2}>
                    <Typography fontWeight="bold">
                        Barang Sebelumnya:
                    </Typography>
                    <Typography>
                        {dataStok?.detailBundling
                            .map(
                                (li) =>
                                    `${li.namaBarang} (${li.jumlahYangDiambil})`,
                            )
                            .join(", ")}
                    </Typography>
                </Box>
            ) : (
                ""
            )}
            <Box
                ml={-1.1}
                flexDirection="row"
                display="flex"
                alignItems="center"
            >
                <Checkbox
                    onChange={handleClikAllProduct}
                    checked={selectedProducts.length === stokAllProduct?.length}
                />
                <Typography>Pilih Semua</Typography>
            </Box>
            <Box className="pilih-item__header" mb={2}>
                <TextField
                    placeholder="Cari barang..."
                    size="small"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={(e) => {
                        e.key === "Enter" ? handleSearchStok() : undefined;
                    }}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        style: {
                            backgroundColor: "white",
                        },
                    }}
                />
                <FormControl size="small" fullWidth>
                    <InputLabel>Pilih kategori</InputLabel>
                    <Select
                        label="Pilih Kategori"
                        value={selectedKategori}
                        onChange={(e) => setSelectedKategori(e.target.value)}
                        sx={{ backgroundColor: "#ffffff" }}
                    >
                        <MenuItem value="">-- Pilih Kategori --</MenuItem>
                        {kategori &&
                            kategori.totalElements > 0 &&
                            kategori.content.map((li) => (
                                <MenuItem
                                    key={String(li.id)}
                                    value={String(li.id)}
                                >
                                    {li.nama}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <Button
                    variant="outlined"
                    size="large"
                    fullWidth
                    sx={{
                        backgroundColor: "#ffffff",
                        lineHeight: 2,
                    }}
                    onClick={handleSearchStok}
                >
                    <SearchIcon />
                </Button>
            </Box>
            <Box
                sx={{
                    height: 460,
                    width: "100%",
                    backgroundColor: "#ffffff",
                    borderRadius: 1,
                    "& .headerColumn": {
                        backgroundColor: "#E4EEE8",
                        // fontSize: "18px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                    },
                    "& .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-iconButtonContainer": {
                        visibility: "visible",
                        width: "0 !important",
                    },
                }}
            >
                <DataGrid
                    rows={stok?.content ?? []}
                    // rows={stok?.content ?? []}
                    components={{
                        ColumnUnsortedIcon: () => {
                            return <ImportExportIcon />;
                        },
                        NoRowsOverlay: NoRowsImage,
                        LoadingOverlay: LoadingTabel,
                    }}
                    columns={columns}
                    // checkboxSelection
                    paginationMode="server"
                    // rowsPerPageOptions={[10, 15, 20]}
                    rowsPerPageOptions={[10]}
                    onPageSizeChange={handleOnSizeChange}
                    onPageChange={handleOnPagination}
                    rowCount={stok?.totalElements ?? 0}
                    page={paramsStok.page - 1}
                    pageSize={paramsStok.size}
                    disableColumnMenu
                    disableSelectionOnClick
                    density="compact"
                    loading={isLoadingStok}
                    // onSelectionModelChange={handleSelectedProducts}
                    // selectionModel={selectionModel}
                    // isRowSelectable={(params) =>
                    //     params.row.stok > 0 || params.row.stok === null
                    // }
                />
            </Box>
            <Box mt={2}>
                <Typography fontWeight="bold">Barang Terpilih:</Typography>
                <Typography>
                    {selectedProducts.map((li) => li.nama).join(", ")}
                </Typography>
            </Box>
        </Box>
    );
};

export default PilihItemStep;
