import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import { FakturKonsinyiValues } from "../../../constants/types";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import formatHarga from "../../../utils/formatHarga";
import toRupiahDesimal from "../../../utils/toRupiahDesimal";
import Autocomplete from "@mui/material/Autocomplete";

registerLocale("id", id);
setDefaultLocale("id");

interface IBarangTerpilihProps {
    id: number;
    nama: string;
    sku: string;
    idx: number;
    hargaJual: number;
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    isPO?: boolean;
}

const BarangTerpilih = ({
    id,
    nama,
    sku,
    idx,
    hargaJual,
    hpp,
    isPO,
}: IBarangTerpilihProps) => {
    const today = new Date();
    const { control, setValue, register, watch } =
        useFormContext<FakturKonsinyiValues>();
    const isTanggalExp = watch(`details.${idx}.isTanggalExp`);
    const watchJumlahStok = watch(`details.${idx}.jumlahStok`);
    const watchHargaBeli = watch(`details.${idx}.hargaBeli`);
    const watchTotalHarga = watch(`details.${idx}.total`);
    const faktur = watch(`details.${idx}.faktur`);

    return (
        <Box alignItems="end" key={String(id)}>
            <Box marginTop={3}>
                <Typography fontWeight="bold">{`${
                    idx + 1
                }. ${nama} (${sku})`}</Typography>
                <input
                    type="hidden"
                    {...register(`details.${idx}.itemId`)}
                    value={id}
                />
                <Grid
                    container
                    alignItems="flex-start"
                    columnSpacing={1}
                    marginTop={1}
                >
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography>Pilih faktur:</Typography>
                        <Controller
                            name={`details.${idx}.faktur`}
                            control={control}
                            rules={{ required: "Kolom wajib diisi" }}
                            render={({ field, fieldState }) => (
                                <Autocomplete
                                    disablePortal
                                    // loading={isLoadingKategori}
                                    disableClearable
                                    options={hpp ?? []}
                                    getOptionLabel={(option) => option.faktur}
                                    getOptionDisabled={(option) =>
                                        Number(option.jumlah) < 1
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    size="small"
                                    value={field.value}
                                    onChange={(
                                        _event,
                                        newFaktur: {
                                            id: number;
                                            faktur: string;
                                            hargaBeli: number;
                                            jumlah: number;
                                        } | null,
                                    ) => {
                                        field.onChange(newFaktur);
                                    }}
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="-- Pilih Faktur --"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error
                                                    ? "Pilih satu faktur"
                                                    : `Jumlah stok: ${field.value?.jumlah}`
                                            }
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography fontWeight={500}>Jumlah Stok:</Typography>
                        <Controller
                            name={`details.${idx}.jumlahStok`}
                            control={control}
                            rules={{
                                required: "Kolom wajib diisi",
                                min: {
                                    value: 1,
                                    message:
                                        "Minimal stok mutasi adalah 1 buah", // JS only: <p>error message</p> TS only support string
                                },
                                max: {
                                    value: faktur ? faktur?.jumlah : 0,
                                    message: `Stok maksimal`, // JS only: <p>error message</p> TS only support string
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <NumberFormat
                                    customInput={TextField}
                                    variant="outlined"
                                    placeholder="Jumlah Stok"
                                    size="small"
                                    fullWidth
                                    id={`details.${idx}.jumlahStok`}
                                    error={!!fieldState?.error}
                                    helperText={
                                        fieldState?.error
                                            ? fieldState?.error.message
                                            : " "
                                    }
                                    {...field}
                                    onChange={(
                                        event: React.ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                        >,
                                    ) => {
                                        field.onChange(
                                            String(event.target.value),
                                        );
                                        const totalNominal =
                                            Number(
                                                formatHarga(
                                                    String(watchHargaBeli),
                                                ),
                                            ) *
                                            Number(
                                                formatHarga(
                                                    String(event.target.value),
                                                ),
                                            );
                                        setValue(
                                            `details.${idx}.total`,
                                            totalNominal,
                                        );
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography fontWeight={500} marginLeft={3}>
                            Harga (satuan):
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography marginTop={-3}>@</Typography>
                            <Controller
                                name={`details.${idx}.hargaBeli`}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <NumberFormat
                                        customInput={TextField}
                                        placeholder="0"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        error={!!fieldState?.error}
                                        helperText={
                                            fieldState?.error
                                                ? fieldState?.error.message
                                                : " "
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography>Rp.</Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                        {...field}
                                        onChange={(
                                            event: React.ChangeEvent<
                                                | HTMLInputElement
                                                | HTMLTextAreaElement
                                            >,
                                        ) => {
                                            field.onChange(
                                                String(event.target.value),
                                            );
                                            const totalNominal =
                                                Number(
                                                    formatHarga(
                                                        String(watchJumlahStok),
                                                    ),
                                                ) *
                                                Number(
                                                    formatHarga(
                                                        String(
                                                            event.target.value,
                                                        ),
                                                    ),
                                                );
                                            setValue(
                                                `details.${idx}.total`,
                                                totalNominal,
                                            );
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="flex-start"
                    columnSpacing={1}
                    marginTop={1}
                >
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography fontWeight={500}>Total:</Typography>
                        <Typography component="div">
                            {toRupiahDesimal(Number(watchTotalHarga))}
                        </Typography>
                    </Grid>
                    {/* {isPO ? null : (
                        <Grid item xs={12} sm={6} md={3}>
                            <Typography fontWeight={500}>Bayar AP:</Typography>
                            <Controller
                                name={`detail.${idx}.bayarAP`}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <NumberFormat
                                        customInput={TextField}
                                        placeholder="0"
                                        // margin="dense"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        error={
                                            !!fieldState?.error ||
                                            Number(
                                                formatHarga(
                                                    String(field.value),
                                                ),
                                            ) > stokKaliHarga
                                        }
                                        helperText={
                                            fieldState?.error
                                                ? fieldState?.error.message
                                                : Number(
                                                      formatHarga(
                                                          String(field.value),
                                                      ),
                                                  ) > stokKaliHarga
                                                ? "Kelebihan"
                                                : "Isi 0 jika tidak ingin bayar"
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography>Rp.</Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    )} */}
                </Grid>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <Controller
                            name={`details.${idx}.isTanggalExp`}
                            control={control}
                            render={({ field }) => (
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox {...field} />}
                                        label="Centang jika ingin beri kadaluarsa"
                                        componentsProps={{
                                            typography: {
                                                variant: "body2",
                                            },
                                        }}
                                    />
                                </FormGroup>
                            )}
                        />
                    </Grid>
                    {isTanggalExp && (
                        <Grid item>
                            <Controller
                                name={`details.${idx}.tanggalExp`}
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        className="range-filter"
                                        dateFormat="dd/MM/yyyy"
                                        popperPlacement="top-start"
                                        disabledKeyboardNavigation
                                        onFocus={(e) => e.target.blur()}
                                        startDate={field.value}
                                        onChange={(update) => {
                                            field.onChange(update);
                                        }}
                                        minDate={today}
                                        monthsShown={1}
                                        customInput={
                                            <Stack
                                                display="flex"
                                                justifyContent="space-between"
                                                direction="row"
                                                // width="100%"
                                                bgcolor="#FFFF"
                                                height="40px"
                                                sx={{
                                                    border: "1px solid #cbcbcb",
                                                    borderRadius: "4px",
                                                    alignItems: "center",
                                                    padding: 2,
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <Typography>
                                                    {field.value?.toLocaleString(
                                                        "id",
                                                        {
                                                            year: "numeric",
                                                            month: "long",
                                                            day: "numeric",
                                                        },
                                                    )}
                                                </Typography>
                                                <ArrowDropDown
                                                    sx={{
                                                        marginRight: "-9px",
                                                    }}
                                                />
                                            </Stack>
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    )}
                </Grid>
                <Controller
                    name={`details.${idx}.keterangan`}
                    control={control}
                    render={({ field, fieldState }) => (
                        <TextField
                            variant="outlined"
                            placeholder="Keterangan"
                            size="small"
                            id="keterangan"
                            fullWidth
                            multiline
                            rows={3}
                            error={!!fieldState?.error}
                            helperText={
                                fieldState?.error && fieldState?.error.message
                            }
                            sx={{ marginTop: 1 }}
                            {...field}
                        />
                    )}
                />
            </Box>
        </Box>
    );
};

export default BarangTerpilih;
