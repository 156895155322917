import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import PrintStrukA5TableHeader from "./PrintStrukA5TableHeader";
import PrintStrukA5TableRow from "./PrintStrukA5TableRow";
import PrintStrukA5Diskon from "./PrintStrukA5Diskon";
import PrintStrukA5Pajak from "./PrintStrukA5Pajak";
import PrintStrukA5Tunai from "./PrintStrukA5Tunai";
import PrintStrukA5Kembalian from "./PrintStrukA5Kembalian";
import PrintStrukA5Subtotal from "./PrintStrukA5Subtotal";
import PrintStrukA5Total from "./PrintStrukA5Total";
import PrintStrukA5CustomAmount from "./PrintStrukA5CustomAmount";
import PrintStrukA5Ongkir from "./PrintStrukA5Ongkir";
import PrintStrukA5Dpp from "./PrintStrukA5Dpp";

interface ICart {
    id: number;
    namaBarang: string;
    sku?: string | undefined;
    qty: number;
    harga: number;
    jumlahStok?: number | undefined;
}

interface IPrintStrukA5TableProps {
    cart?: ICart[];
    diskon?: number;
    dpp?: number;
    pajak?: number;
    persenPajak?: number;
    tunai?: number;
    kembalian?: number;
    namaMetode?: string | null;
    hargaCustomAmount?: string | null;
    hargaOngkir?: string | null;
    total: number;
}

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 8,
    },
});

const PrintStrukA5Table = ({
    cart,
    diskon,
    dpp,
    pajak,
    persenPajak,
    tunai,
    kembalian,
    namaMetode,
    total,
    hargaCustomAmount,
    hargaOngkir,
}: IPrintStrukA5TableProps) => {
    return (
        <View style={styles.tableContainer}>
            <PrintStrukA5TableHeader />
            <PrintStrukA5TableRow cart={cart} />
            <PrintStrukA5Subtotal cart={cart} />
            {hargaCustomAmount ? (
                <PrintStrukA5CustomAmount
                    hargaCustomAmount={hargaCustomAmount}
                />
            ) : (
                <View></View>
            )}
            {hargaOngkir ? (
                <PrintStrukA5Ongkir hargaOngkir={hargaOngkir} />
            ) : (
                <View></View>
            )}
            {/* {dpp ? <PrintStrukA5Dpp dpp={dpp} /> : <View></View>}
            {pajak ? (
                <PrintStrukA5Pajak pajak={pajak} persenPajak={persenPajak} />
            ) : (
                <View></View>
            )} */}
            {diskon ? <PrintStrukA5Diskon diskon={diskon} /> : <View></View>}
            <PrintStrukA5Total total={total} />
            {tunai ? (
                <PrintStrukA5Tunai tunai={tunai} namaMetode={namaMetode} />
            ) : (
                <View></View>
            )}
            {kembalian ? (
                <PrintStrukA5Kembalian kembalian={kembalian} />
            ) : (
                <View></View>
            )}
        </View>
    );
};

export default PrintStrukA5Table;
