import { useEffect, useMemo, useState } from "react";
import {
    ErrorFieldResponse,
    ModalKonsinyiField,
    ParamsGetCoa,
} from "../../constants/types";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import defaultAxios, { AxiosError } from "axios";
import axios from "../../services/axios";
import * as yup from "yup";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import useCoa from "../../services/queries/useCoa";
import Autocomplete from "@mui/material/Autocomplete";
import { useTransaksi } from "../../context/transaksiContext";

interface IModalKonsinyiProps {
    isOpenModalKonsinyi: boolean;
    closeModalKonsinyi: () => void;
    dataKonsinyi?: ModalKonsinyiField;
}

const schema = yup.object({
    nama: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama terlalu panjang"),
    cp: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama terlalu panjang"),
    alamat: yup
        .string()
        .required("Kolom wajib diisi")
        .max(100, "Alamat terlalu panjang"),
    // noTelepon: yup
    //     .string()
    //     .required("Kolom wajib diisi")
    //     .matches(/^8[1-9][0-9]/, "No. Telepon tidak valid, contoh: 831xxxx")
    //     .min(9, "No. Telepon terlalu pendek")
    //     .max(13, "No. Telepon terlalu panjang"),
    email: yup
        .string()
        .email("Format email salah")
        .required("Kolom wajib diisi"),
    keterangan: yup
        .string()
        .required("Kolom wajib diisi")
        .max(100, "Keterangan terlalu panjang"),
    coa: yup
        .object()
        .shape({
            id: yup.string().required("Kolom wajib diisi"),
            noAkun: yup.string().required("Kolom wajib diisi"),
            namaAkun: yup.string().required("Kolom wajib diisi"),
        })
        .required("Kolom wajib diisi"),
});

const ModalKonsinyi = ({
    isOpenModalKonsinyi,
    closeModalKonsinyi,
    dataKonsinyi,
}: IModalKonsinyiProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const { setFetchingItems } = useTransaksi();
    const initialParamsCoa = {
        size: 300,
        page: 1,
        search: "",
        kategori: "",
    };
    const [paramsCoa, setParamsCoa] = useState<ParamsGetCoa>(initialParamsCoa);
    const { data: coa, isLoading: isLoadingCoa } = useCoa(paramsCoa);
    const mappedCoa = coa?.content
        .filter((c) => c.header !== 1)
        .map((li) => ({
            id: li.id,
            noAkun: li.noAkun,
            namaAkun: li.namaAkun,
        }));
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const initialValues = useMemo(
        () => ({
            nama: "",
            cp: "",
            alamat: "",
            noTelepon: "",
            email: "",
            keterangan: "",
        }),
        [],
    );

    const { handleSubmit, control, reset, setError } =
        useForm<ModalKonsinyiField>({
            resolver: yupResolver(schema),
            defaultValues: initialValues,
        });

    const onSubmit = async (values: ModalKonsinyiField) => {
        console.log(values);
        setIsButtonLoading(true);
        if (dataKonsinyi) {
            try {
                const formData = new FormData();
                formData.append("nama", values?.nama);
                formData.append("cp", values?.cp);
                formData.append("alamat", values?.alamat);
                formData.append("noTelepon", values.noTelepon);
                formData.append("email", values?.email);
                formData.append("keterangan", values?.keterangan);
                formData.append("coaId", String(values?.coa?.id ?? ""));
                formData.append("_method", "put");

                const { data } = await axios.post(
                    `/api/konsinyi/${values.id}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    Swal.fire({
                        title: "Konsinyi telah diubah",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalKonsinyi();
                    reset(initialValues);
                    setFetchingItems();
                }
                setIsButtonLoading(false);
            } catch (error) {
                if (defaultAxios.isAxiosError(error)) {
                    const serverError = error as AxiosError<
                        ErrorFieldResponse | undefined
                    >;
                    if (serverError && serverError?.response) {
                        const fieldError = serverError?.response?.data;
                        if (fieldError?.errors) {
                            Object.keys(fieldError.errors).forEach((key) => {
                                const errorMessage = fieldError.errors[key];
                                setError(key as keyof typeof initialValues, {
                                    type: "manual",
                                    message: errorMessage[0],
                                });
                            });
                        } else {
                            Swal.fire({
                                title: `${
                                    fieldError?.message ?? "Terjadi Kesalahan!"
                                }`,
                                position: "top-end",
                                showConfirmButton: false,
                                icon: "error",
                                toast: true,
                                timer: 3000,
                                timerProgressBar: true,
                                showCloseButton: true,
                                customClass: {
                                    container: "my-swal",
                                },
                            });
                        }
                    }
                }
                console.error(error);
                setIsButtonLoading(false);
            }
        } else {
            try {
                const formData = new FormData();
                formData.append("nama", values?.nama);
                formData.append("cp", values?.cp);
                formData.append("alamat", values?.alamat);
                formData.append("noTelepon", values.noTelepon);
                formData.append("email", values?.email);
                formData.append("keterangan", values?.keterangan);
                formData.append("coaId", String(values?.coa?.id));

                const { data } = await axios.post(`/api/konsinyi`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                if (data.code === 200) {
                    Swal.fire({
                        title: values.nama + " telah ditambahkan",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalKonsinyi();
                    reset(initialValues);
                    setFetchingItems();
                }
                setIsButtonLoading(false);
            } catch (error) {
                if (defaultAxios.isAxiosError(error)) {
                    const serverError = error as AxiosError<
                        ErrorFieldResponse | undefined
                    >;
                    if (serverError && serverError?.response) {
                        const fieldError = serverError?.response?.data;
                        if (fieldError?.errors) {
                            Object.keys(fieldError.errors).forEach((key) => {
                                const errorMessage = fieldError.errors[key];
                                setError(key as keyof typeof initialValues, {
                                    type: "manual",
                                    message: errorMessage[0],
                                });
                            });
                        } else {
                            Swal.fire({
                                title: `${
                                    fieldError?.message ?? "Terjadi Kesalahan!"
                                }`,
                                position: "top-end",
                                showConfirmButton: false,
                                icon: "error",
                                toast: true,
                                timer: 3000,
                                timerProgressBar: true,
                                showCloseButton: true,
                                customClass: {
                                    container: "my-swal",
                                },
                            });
                        }
                    }
                }
                console.error(error);
                setIsButtonLoading(false);
            }
        }
    };

    useEffect(() => {
        if (isOpenModalKonsinyi) {
            if (dataKonsinyi) {
                reset(dataKonsinyi);
            } else {
                reset(initialValues);
            }
        }
    }, [reset, initialValues, isOpenModalKonsinyi, dataKonsinyi]);

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModalKonsinyi}
            onClose={() => {}}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalKonsinyi}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        {dataKonsinyi ? "Ubah Konsinyi" : "Tambah Konsinyi"}
                    </Typography>
                </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: isPhoneScreen ? undefined : 6 }}>
                <Stack spacing={6}>
                    <Grid
                        container
                        alignItems="center"
                        rowSpacing={2}
                        columnSpacing={2}
                    >
                        <Grid item xs={12}>
                            <Controller
                                name="nama"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label="Nama"
                                        fullWidth
                                        variant="outlined"
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="coa"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        disablePortal
                                        disableClearable
                                        loading={isLoadingCoa}
                                        options={mappedCoa ?? []}
                                        getOptionLabel={(option) => {
                                            return `(${option.noAkun}) - ${option.namaAkun}`;
                                        }}
                                        // isOptionEqualToValue={(option, value) =>
                                        //     option.id === value.id
                                        // }
                                        value={field.value}
                                        onInputChange={(e) => {
                                            if (e) {
                                                const element =
                                                    e.target as HTMLInputElement;
                                                setParamsCoa((prev) => ({
                                                    ...prev,
                                                    search: element.value,
                                                }));
                                            } else {
                                                return;
                                            }
                                        }}
                                        onChange={(
                                            _event,
                                            newPelanggan: {
                                                id: number;
                                                noAkun: string;
                                                namaAkun: string;
                                            } | null,
                                        ) => {
                                            field.onChange(newPelanggan);
                                        }}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Pilih COA"
                                                error={!!fieldState?.error}
                                                helperText={
                                                    fieldState?.error &&
                                                    "Pilih satu COA"
                                                }
                                            />
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: "200px",
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label="Email"
                                        fullWidth
                                        variant="outlined"
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="cp"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label="Contact Person"
                                        fullWidth
                                        variant="outlined"
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="noTelepon"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label="No Telepon"
                                        variant="outlined"
                                        fullWidth
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="alamat"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label="Alamat"
                                        fullWidth
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="keterangan"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label="Keterangan"
                                        fullWidth
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <LoadingButton
                        loading={isButtonLoading}
                        loadingPosition="center"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Simpan
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalKonsinyi;
