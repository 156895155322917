import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/Download";
import { useTransaksi } from "../../context/transaksiContext";
import { useAuth } from "../../context/authContext";
import moment from "moment";
import {
    DataFakturKonsinyasi,
    DetailFaktur,
    ParamsGetFakturKonsinyasi,
} from "../../constants/types";
import useFakturKonsinyasi from "../../services/queries/useFakturKonsinyasi";
import qs from "query-string";
import axios from "../../services/axios";
import useUkm from "../../services/queries/useUkm";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import toRupiahDesimal from "../../utils/toRupiahDesimal";
import Visibility from "@mui/icons-material/Visibility";
import Icon from "@mdi/react";
import { mdiCashCheck } from "@mdi/js";
import useMediaQuery from "@mui/material/useMediaQuery";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import id from "date-fns/locale/id";
import "./FakturKonsinyasiPage.css";
import { subtractMonths, subtractYears } from "../../utils/subtractDate";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Search from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import ImportExport from "@mui/icons-material/ImportExport";
import LoadingTabel from "../../components/LoadingTabel/LoadingTabel";
import NoRowsImage from "../../components/NoRowsImage/NoRowsImage";
import Spinner from "../../components/Spinner/Spinner";
import ModalFakturKonsinyasi from "../../components/ModalFakturKonsinyasi/ModalFakturKonsinyasi";
import ModalDetailFakturKonsinyasi from "../../components/ModalDetailFakturKonsinyasi/ModalDetailFakturKonsinyasi";
import ModalConsignmentProcess from "../../components/ModalConsignmentProcess/ModalConsignmentProcess";

registerLocale("id", id);
setDefaultLocale("id");

type DateFilter = [Date | null, Date | null];

const FakturKonsinyasiPage = () => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    let now = moment().format("YYYY/MM/DD");
    const today = new Date();
    const { ukmIdUser } = useAuth();
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const [dateRange, setDateRange] = useState<DateFilter>([
        new Date(),
        new Date(),
    ]);
    const [startDate, endDate] = dateRange;
    const [search, setSearch] = useState<string>("");
    const { isFetchingItems, cancelFetchingItems } = useTransaksi();

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);

    const ukmData = useMemo(() => {
        if (ukmIdUser) {
            return ukm?.content.find((li) => li.id === ukmIdUser[0]);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmIdUser]);

    const initialParamsFakturKonsinyasi = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 1000,
        page: 1,
        po: undefined,
        tanggalAwal: now,
        tanggalAkhir: now,
    };
    const [paramsFakturKonsinyasi, setParamsFakturKonsinyasi] =
        useState<ParamsGetFakturKonsinyasi>(initialParamsFakturKonsinyasi);
    const {
        data: fakturKonsinyasi,
        refetch: refetchFakturKonsinyasi,
        isLoading: isLoadingFakturKonsinyasi,
    } = useFakturKonsinyasi(paramsFakturKonsinyasi);

    const [detailFaktur, setDetailFaktur] = useState<DetailFaktur[]>([]);

    const handleChangePeriode = (event: SelectChangeEvent) => {
        setDateRange([new Date(event.target.value), new Date()]);
        setParamsFakturKonsinyasi((prev) => ({
            ...prev,
            tanggalAwal: String(event.target.value),
            tanggalAkhir: now,
            page: 1,
        }));
        refetchFakturKonsinyasi();
    };

    const handleChangeJangkaWaktu = (
        tanggalAwal: string,
        tanggalAkhir: string,
    ) => {
        setParamsFakturKonsinyasi((prev) => ({
            ...prev,
            tanggalAwal: tanggalAwal,
            tanggalAkhir: tanggalAkhir,
            page: 1,
        }));
        refetchFakturKonsinyasi();
    };

    // const handleOnPaginationFakturKonsinyasi = (page: number) => {
    //     setParamsFakturKonsinyasi((prev) => ({
    //         ...prev,
    //         page: page + 1,
    //     }));
    // };

    // const handleOnSizeChangeFakturKonsinyasi = (size: number) => {
    //     setParamsFakturKonsinyasi((prev) => ({ ...prev, size }));
    // };

    const initialDataFaktur = {
        faktur: "",
        ukm: {
            id: null,
            nama: null,
        },
        konsinyi: {
            id: 0,
            nama: "",
        },
        keterangan: null,
        tanggal: null,
        jatuhTempo: null,
        total: 0,
        jumlah: 0,
    };
    const [dataFaktur, setDataFaktur] =
        useState<DataFakturKonsinyasi>(initialDataFaktur);

    const [isOpenModalFakturKonsinyasi, setIsOpenModalFakturKonsinyasi] =
        useState<boolean>(false);
    const [isOpenModalDetailFaktur, setIsOpenModalDetailFaktur] =
        useState<boolean>(false);
    const [isOpenModalConsignmentProcess, setIsOpenModalConsignmentProcess] =
        useState<boolean>(false);

    const openModalFakturKonsinyasi = () => {
        setIsOpenModalFakturKonsinyasi(true);
    };

    const closeModalFakturKonsinyasi = () => {
        setIsOpenModalFakturKonsinyasi(false);
    };

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleSearch = () => {
        setParamsFakturKonsinyasi((prev) => ({
            ...prev,
            search: search,
        }));
    };

    const openModalDetailFaktur = (
        data: DetailFaktur[],
        dataFaktur: DataFakturKonsinyasi,
    ) => {
        setDetailFaktur(data);
        setDataFaktur(dataFaktur);
        setIsOpenModalDetailFaktur(true);
    };

    const closeModalDetailFaktur = () => {
        setIsOpenModalDetailFaktur(false);
    };

    const openModalConsignmentProcess = (obj: DataFakturKonsinyasi) => {
        setDataFaktur(obj);
        setIsOpenModalConsignmentProcess(true);
    };

    const downloadExcel = async () => {
        setIsLoadingButton(true);
        try {
            const apiUrl = qs.stringifyUrl({
                url: "api/dokumen-konsinyasi",
                query: {
                    ukmId: ukmIdUser && ukmIdUser[0],
                    tanggalAwal: paramsFakturKonsinyasi.tanggalAwal,
                    tanggalAkhir:
                        paramsFakturKonsinyasi.tanggalAkhir === ""
                            ? paramsFakturKonsinyasi.tanggalAwal
                            : paramsFakturKonsinyasi.tanggalAkhir,
                },
            });
            const { data } = await axios.get(apiUrl, {
                responseType: "blob",
            });
            if (data) {
                const file = new Blob([data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                });
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = fileURL;
                link.setAttribute(
                    "download",
                    `Laporan_Faktur_Konsinyasi_${ukmData?.namaToko}_${paramsFakturKonsinyasi.tanggalAwal}-${paramsFakturKonsinyasi.tanggalAkhir}`,
                ); //or any other extension
                document.body.appendChild(link);
                link.click();
                setIsLoadingButton(false);
            }
        } catch (error) {
            console.error(error);
            setIsLoadingButton(false);
        }
    };

    useEffect(() => {
        if (ukmIdUser) {
            setParamsFakturKonsinyasi((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        if (isFetchingItems === true) {
            refetchFakturKonsinyasi();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchFakturKonsinyasi]);

    const columns: GridColDef[] = [
        {
            field: "tanggal",
            headerName: "Tanggal",
            flex: isLaptopScreen ? 0.4 : undefined,
            minWidth: 160,
            renderCell: (params) => {
                return (
                    <Typography variant="body2">
                        {params.value ? moment(params.value).format("LL") : "-"}
                    </Typography>
                );
            },
        },
        {
            field: "faktur",
            headerName: "No. Faktur",
            flex: isLaptopScreen ? 0.8 : undefined,
            minWidth: 160,
        },
        {
            field: "konsinyi",
            headerName: "Konsinyi",
            flex: isLaptopScreen ? 0.8 : undefined,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Typography variant="body2">
                        {params.value.id ? params.value.nama : "-"}
                    </Typography>
                );
            },
        },
        {
            field: "jatuhTempo",
            headerName: "Jatuh Tempo",
            flex: isLaptopScreen ? 0.4 : undefined,
            minWidth: 160,
            renderCell: (params) => {
                return (
                    <Typography variant="body2">
                        {params.value ? moment(params.value).format("LL") : "-"}
                    </Typography>
                );
            },
        },
        {
            field: "total",
            headerName: "Total Konsinyasi",
            align: "right",
            flex: isLaptopScreen ? 0.3 : undefined,
            minWidth: 160,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="subtitle2"
                        fontWeight={400}
                        textAlign="right"
                    >
                        {toRupiahDesimal(Number(params.value))}
                    </Typography>
                );
            },
        },
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            minWidth: 150,
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="Lihat Detail">
                            <span>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        openModalDetailFaktur(
                                            params.row.detail,
                                            params.row,
                                        );
                                    }}
                                    size="small"
                                    color="buttongreen"
                                    disableElevation
                                    sx={{
                                        minWidth: "unset",
                                        padding: "8px",
                                        width: "32px",
                                        height: "32px",
                                    }}
                                >
                                    <Visibility
                                        sx={{ width: "16px", height: "16px" }}
                                    />
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title="Proses Konsinyasi">
                            <span>
                                <Button
                                    disabled={params.row.isProes === 1}
                                    variant="contained"
                                    color="buttonyellow"
                                    disableElevation
                                    size="small"
                                    sx={{
                                        minWidth: "unset",
                                        padding: "8px",
                                        width: "32px",
                                        height: "32px",
                                    }}
                                    onClick={() =>
                                        openModalConsignmentProcess(params.row)
                                    }
                                >
                                    <Icon path={mdiCashCheck} size="16px" />
                                </Button>
                            </span>
                        </Tooltip>
                    </Stack>
                );
            },
        },
    ];

    if (isLoadingFakturKonsinyasi) {
        return (
            <Box
                position="relative"
                height={isLaptopScreen ? "100vh" : "calc(100vh - 56px)"}
            >
                <Spinner />
            </Box>
        );
    }

    return (
        <Box paddingY={2}>
            <Stack
                display="flex"
                justifyContent="space-between"
                direction={{ xs: "column", sm: "row", md: "row" }}
                flexWrap="wrap"
                gap={2}
                marginBottom={2}
            >
                <Typography variant="h6" fontWeight="bold">
                    Faktur Konsinyasi
                </Typography>
                <Stack direction="row" flexWrap="wrap" gap={1}>
                    <Button
                        variant="outlined"
                        href="/daftar-konsinyi"
                        fullWidth={isPhoneScreen}
                        startIcon={<SettingsIcon />}
                    >
                        Atur Konsinyi
                    </Button>
                    <LoadingButton
                        loading={isLoadingButton}
                        loadingPosition="start"
                        variant="outlined"
                        onClick={downloadExcel}
                        fullWidth={isPhoneScreen}
                        startIcon={<DownloadIcon />}
                    >
                        Unduh Excel
                    </LoadingButton>
                    <Button
                        variant="contained"
                        onClick={openModalFakturKonsinyasi}
                        fullWidth={isPhoneScreen}
                        startIcon={<AddIcon />}
                    >
                        Tambah
                    </Button>
                </Stack>
            </Stack>
            <Box
                display="grid"
                gridTemplateColumns={isPhoneScreen ? "1fr" : "1fr 2fr"}
                gap={2}
                width={isPhoneScreen ? "100%" : "60%"}
                marginBottom={3}
            >
                <Select
                    value={
                        paramsFakturKonsinyasi.tanggalAwal === now ||
                        paramsFakturKonsinyasi.tanggalAwal ===
                            moment().subtract(7, "days").format("YYYY-MM-DD") ||
                        paramsFakturKonsinyasi.tanggalAwal ===
                            moment()
                                .subtract(1, "month")
                                .format("YYYY-MM-DD") ||
                        paramsFakturKonsinyasi.tanggalAwal ===
                            moment()
                                .subtract(3, "months")
                                .format("YYYY-MM-DD") ||
                        paramsFakturKonsinyasi.tanggalAwal ===
                            moment().subtract(1, "years").format("YYYY-MM-DD")
                            ? paramsFakturKonsinyasi.tanggalAwal
                            : "Custom"
                    }
                    size="small"
                    onChange={handleChangePeriode}
                    sx={{ backgroundColor: "white" }}
                >
                    <MenuItem value={now}>Hari ini</MenuItem>
                    <MenuItem
                        value={moment()
                            .subtract(7, "days")
                            .format("YYYY-MM-DD")}
                    >
                        1 Minggu Terakhir
                    </MenuItem>
                    <MenuItem
                        value={moment()
                            .subtract(1, "month")
                            .format("YYYY-MM-DD")}
                    >
                        1 Bulan Terakhir
                    </MenuItem>
                    <MenuItem
                        value={moment()
                            .subtract(3, "months")
                            .format("YYYY-MM-DD")}
                    >
                        3 Bulan Terakhir
                    </MenuItem>
                    <MenuItem
                        value={
                            ukmData?.isPro === 1
                                ? moment()
                                      .subtract(1, "years")
                                      .format("YYYY-MM-DD")
                                : "setahun"
                        }
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            color={ukmData?.isPro === 1 ? undefined : "#dddddd"}
                        >
                            1 Tahun Terakhir
                        </Typography>
                        {ukmData?.isPro === 1 ? null : (
                            <Chip label="PRO" size="small" />
                        )}
                    </MenuItem>
                    <MenuItem value={"Custom"} sx={{ display: "none" }}>
                        Custom
                    </MenuItem>
                </Select>
                <Stack
                    direction="column"
                    position="relative"
                    sx={{
                        minWidth: "100%",
                        marginTop: 0,
                    }}
                >
                    <DatePicker
                        className="range-filter"
                        dateFormat="dd/MM/yyyy"
                        disabledKeyboardNavigation
                        // open={isOpenKalender}
                        onFocus={(e) => e.target.blur()}
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                            if (update[1]) {
                                handleChangeJangkaWaktu(
                                    moment(update[0]).format("YYYY/MM/DD"),
                                    moment(update[1]).format("YYYY/MM/DD"),
                                );
                            }
                        }}
                        maxDate={today}
                        minDate={
                            ukmData?.isPro === 1
                                ? subtractYears(1)
                                : subtractMonths(3)
                        }
                        monthsShown={isPhoneScreen ? 1 : 2}
                        customInput={
                            <Stack
                                display="flex"
                                justifyContent="space-between"
                                direction="row"
                                width="100%"
                                bgcolor="#FFFF"
                                height="40px"
                            >
                                <Typography>
                                    {moment(
                                        paramsFakturKonsinyasi.tanggalAwal,
                                    ).format("DD/MM/YYYY")}{" "}
                                    -{" "}
                                    {paramsFakturKonsinyasi.tanggalAkhir &&
                                        moment(
                                            paramsFakturKonsinyasi.tanggalAkhir,
                                        ).format("DD/MM/YYYY")}
                                </Typography>
                                <ArrowDropDown
                                    sx={{
                                        marginRight: -3,
                                    }}
                                />
                            </Stack>
                        }
                    />
                </Stack>
            </Box>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <TextField
                        placeholder="Cari faktur"
                        size="small"
                        fullWidth
                        value={search}
                        onChange={handleChangeSearch}
                        onKeyPress={(e) => {
                            const element = e.target as HTMLInputElement;
                            if (e.key === "Enter") {
                                setParamsFakturKonsinyasi((prev) => ({
                                    ...prev,
                                    search: element.value,
                                }));
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearch}>
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <Box
                marginTop={2}
                sx={{
                    height: 630,
                    width: "100%",
                    borderRadius: 1,
                    "& .headerColumn": {
                        backgroundColor: "#E4EEE8",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                    },
                    "& .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-iconButtonContainer": {
                        visibility: "visible",
                        width: "0 !important",
                    },
                }}
            >
                <DataGrid
                    components={{
                        ColumnUnsortedIcon: () => {
                            return <ImportExport />;
                        },
                        NoRowsOverlay: NoRowsImage,
                        LoadingOverlay: LoadingTabel,
                    }}
                    rows={fakturKonsinyasi?.content ?? []}
                    columns={columns}
                    disableColumnMenu
                    disableSelectionOnClick
                    loading={isLoadingFakturKonsinyasi}
                />
            </Box>
            <ModalFakturKonsinyasi
                isOpenModalFakturKonsinyasi={isOpenModalFakturKonsinyasi}
                closeModalFakturKonsinyasi={closeModalFakturKonsinyasi}
            />
            <ModalDetailFakturKonsinyasi
                isOpenModalDetailFaktur={isOpenModalDetailFaktur}
                closeModalDetailFaktur={closeModalDetailFaktur}
                openModalDetailFaktur={openModalDetailFaktur}
                detailFaktur={detailFaktur}
                fullScreen={fullScreen}
                dataFaktur={dataFaktur}
            />
            <ModalConsignmentProcess
                isOpen={isOpenModalConsignmentProcess}
                onClose={() => {
                    setIsOpenModalConsignmentProcess(false);
                }}
                data={dataFaktur}
            />
        </Box>
    );
};

export default FakturKonsinyasiPage;
