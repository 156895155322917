import React from "react";
import { ModalKonsinyiField, ParamsGetKonsinyi } from "../../constants/types";
import useKonsinyi from "../../services/queries/useKonsinyi";
import { useTransaksi } from "../../context/transaksiContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Edit from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Spinner from "../../components/Spinner/Spinner";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Search from "@mui/icons-material/Search";
import ImportExport from "@mui/icons-material/ImportExport";
import LoadingTabel from "../../components/LoadingTabel/LoadingTabel";
import NoRowsImage from "../../components/NoRowsImage/NoRowsImage";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ModalKonsinyi from "../../components/ModalKonsinyi/ModalKonsinyi";

interface IDaftarKonsinyiPageProps {}

const DaftarKonsinyiPage = (props: IDaftarKonsinyiPageProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { isFetchingItems, cancelFetchingItems } = useTransaksi();
    const [dataKonsinyi, setDataKonsinyi] =
        React.useState<ModalKonsinyiField>();
    const [search, setSearch] = React.useState("");
    const [isOpenModalKonsinyi, setIsOpenModalKonsinyi] = React.useState(false);

    const openModalKonsinyi = (data?: ModalKonsinyiField) => {
        if (data) {
            setDataKonsinyi((prev) => ({
                ...prev,
                ...data,
            }));
        }
        setIsOpenModalKonsinyi(true);
    };

    const closeModalKonsinyi = () => {
        setIsOpenModalKonsinyi(false);
        setDataKonsinyi(undefined);
    };

    const initialParamsKonsinyi = {
        size: 10,
        page: 1,
        search: "",
    };
    const [paramsKonsinyi, setParamsKonsinyi] =
        React.useState<ParamsGetKonsinyi>(initialParamsKonsinyi);

    const {
        data: konsinyi,
        refetch: refetchKonsinyi,
        isLoading: isLoadingKonsinyi,
    } = useKonsinyi(paramsKonsinyi);

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleSearch = () => {
        setParamsKonsinyi((prev) => ({
            ...prev,
            search: search,
        }));
    };

    const handleOnPagination = (page: number) => {
        setParamsKonsinyi((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsKonsinyi((prev) => ({ ...prev, size }));
    };

    React.useEffect(() => {
        if (isFetchingItems === true) {
            refetchKonsinyi();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchKonsinyi]);

    const columns: GridColDef[] = [
        {
            field: "nama",
            headerName: "Nama",
            flex: isLaptopScreen ? 0.5 : undefined,
            minWidth: 150,
        },
        {
            field: "noTelepon",
            headerName: "No. Telp",
            flex: isLaptopScreen ? 0.5 : undefined,
            minWidth: 150,
        },
        {
            field: "alamat",
            headerName: "Alamat",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 250,
        },
        {
            field: "email",
            headerName: "Email",
            flex: isLaptopScreen ? 0.5 : undefined,
            minWidth: 150,
        },
        {
            field: "keterangan",
            headerName: "Keterangan",
            flex: isLaptopScreen ? 0.5 : undefined,
            minWidth: 150,
        },
        {
            field: "aksi",
            headerName: "Aksi",
            align: "center",
            minWidth: 120,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={3}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                openModalKonsinyi({
                                    id: params.row.id,
                                    nama: params.row.nama,
                                    alamat: params.row.alamat,
                                    noTelepon: params.row.noTelepon ?? "",
                                    cp: params.row.cp,
                                    email: params.row.email,
                                    keterangan: params.row.keterangan,
                                    coa: params.row.coa.id
                                        ? {
                                              id: params.row.coa.id,
                                              noAkun: params.row.coa.noAkun,
                                              namaAkun: params.row.coa.nama,
                                          }
                                        : undefined,
                                });
                            }}
                            size="small"
                            color="buttonyellow"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <Edit sx={{ width: "16px", height: "16px" }} />
                        </Button>
                    </Stack>
                );
            },
        },
    ];

    if (isLoadingKonsinyi) {
        return (
            <Box
                position="relative"
                height={isLaptopScreen ? "100vh" : "calc(100vh - 56px)"}
            >
                <Spinner />
            </Box>
        );
    }

    return (
        <Box paddingY={2}>
            <Button
                href="/faktur-konsinyasi"
                startIcon={<ArrowBack />}
                sx={{ textTransform: "none" }}
            >
                Kembali ke Faktur Konsinyasi
            </Button>
            <Stack
                display="flex"
                justifyContent="space-between"
                direction={{ xs: "column", sm: "row", md: "row" }}
                flexWrap="wrap"
                gap={2}
                marginY={2}
            >
                <Typography variant="h6" fontWeight="bold">
                    Daftar Konsinyi
                </Typography>
                <Stack direction="row" flexWrap="wrap" gap={1}>
                    <Button
                        variant="contained"
                        onClick={() => openModalKonsinyi()}
                        fullWidth={isPhoneScreen}
                        startIcon={<AddIcon />}
                    >
                        Tambah
                    </Button>
                </Stack>
            </Stack>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <TextField
                        placeholder="Cari konsinyi"
                        size="small"
                        fullWidth
                        value={search}
                        onChange={handleChangeSearch}
                        onKeyPress={(e) => {
                            const element = e.target as HTMLInputElement;
                            if (e.key === "Enter") {
                                setParamsKonsinyi((prev) => ({
                                    ...prev,
                                    search: element.value,
                                }));
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearch}>
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <Box
                marginTop={2}
                sx={{
                    height: 630,
                    width: "100%",
                    borderRadius: 1,
                    "& .headerColumn": {
                        backgroundColor: "#E4EEE8",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                    },
                    "& .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-iconButtonContainer": {
                        visibility: "visible",
                        width: "0 !important",
                    },
                }}
            >
                <DataGrid
                    pagination
                    rows={konsinyi?.content ?? []}
                    columns={columns}
                    components={{
                        ColumnUnsortedIcon: () => {
                            return <ImportExport />;
                        },
                        NoRowsOverlay: NoRowsImage,
                        LoadingOverlay: LoadingTabel,
                    }}
                    rowsPerPageOptions={[10]}
                    disableColumnMenu
                    disableSelectionOnClick
                    paginationMode="server"
                    onPageSizeChange={handleOnSizeChange}
                    onPageChange={handleOnPagination}
                    rowCount={konsinyi?.totalElements ?? 0}
                    page={paramsKonsinyi.page - 1}
                    pageSize={paramsKonsinyi.size}
                    loading={isLoadingKonsinyi}
                />
            </Box>
            <ModalKonsinyi
                isOpenModalKonsinyi={isOpenModalKonsinyi}
                closeModalKonsinyi={closeModalKonsinyi}
                dataKonsinyi={dataKonsinyi}
            />
        </Box>
    );
};

export default DaftarKonsinyiPage;
