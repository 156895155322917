import React, { useCallback, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import useUser from "../../services/queries/useUser";
import useItem from "../../services/queries/useItem";
import toRibuan from "../../utils/toRibuan";
import defaultAxios, { AxiosError } from "axios";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import {
    Box,
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography,
    DialogContent,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
    DaftarItemListInvoice,
    InputPembayaranBody,
    InputPembayaranResponse,
    ParamsGetItem,
} from "../../constants/types";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import "moment/locale/id";
import toRupiahDesimal from "../../utils/toRupiahDesimal";
import toRibuanDesimal from "../../utils/toRibuanDesimal";

moment.locales();
moment.locale("id");

interface IModalRevertInvoiceProps {
    refetchLaporan: () => void;
    refetchListInvoice: () => void;
    refetchProdukTerjual: () => void;
    refetchMutasiStok: () => void;
    refetchGrafikPenjualan: () => void;
    diskon?: number;
    totalHarga: number;
    totalTagihan: number;
    uangDibayar: number;
    kembalian: number;
    item?: DaftarItemListInvoice[];
    statusItem: "idle" | "error" | "loading" | "success";
    invoiceId: number;
    customer: {
        id: number | null;
        nama: string | null;
    };
    closeModalRevertInvoice: () => void;
    isOpenModalRevertInvoice: boolean;
    ongkir: string | null;
    ketOngkir: string | null;
    customAmount: string | null;
    ketCustomAmount: string | null;
    metodeId: number;
}

export const ModalRevertInvoice = (props: IModalRevertInvoiceProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    let now = moment().format("DD MMM YYYY HH:mm");

    const { ukmIdUser } = useAuth();
    const { data: user } = useUser();

    const initialParams = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 500,
        page: 1,
        search: "",
        kategori: "",
    };

    const [paramsItem, setParamsItem] = useState<ParamsGetItem>(initialParams);
    const [totalHargaSeluruhItem, setTotalHargaSeluruhItem] = useState(0);
    const { refetch: refetchItem } = useItem(paramsItem);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const {
        handleReset,
        isPrintStruk,
        resetPrint,
        setFetchingItems,
        handlingDonePembayaran,
        // diskon,
        // grandTotal,
        // bayar,
        // kembalian,
    } = useTransaksi();

    const current = new Date();
    current.getSeconds();

    const noInvoice = useMemo(
        () => `U-${moment().format("YYMMDDHHmmss")}`,
        [props.isOpenModalRevertInvoice],
    );

    const handleHitungTotalHargaSeluruhItem = useCallback(() => {
        let total = 0;
        if (props.item) {
            props.item.forEach((list) => {
                total +=
                    list.hargaSatuan *
                        (list.jumlahItem - list.jumlahItem * 2) ?? 0;
            });
            setTotalHargaSeluruhItem(total);
            return;
        }
    }, [props.item]);

    useEffect(() => {
        handleHitungTotalHargaSeluruhItem();
    }, [props.item, handleHitungTotalHargaSeluruhItem]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const inputPembayaran = async () => {
        setIsButtonLoading(true);
        const body = {
            ukmId: ukmIdUser && ukmIdUser[0],
            noInvoice: noInvoice,
            jenisItem:
                props.item &&
                props.item.map((li) => ({
                    itemId: li.idItem,
                    namaItem: li.namaItem,
                    skuItem: li.skuItem,
                    jumlahItem: li.jumlahItem - li.jumlahItem * 2,
                    hargaSatuan: li.hargaSatuan,
                    hargaTotal: li.hargaTotal * -1,
                    kodeFaktur: li.faktur.map((fa) => ({
                        idFaktur: fa.idFaktur,
                        kodeFaktur: null,
                        jumlahItem: fa.jumlah * -1,
                    })),
                })),
            userInput: `${user?.userId}`,
            sales: null,
            totalHarga: totalHargaSeluruhItem,
            diskon: Number(props.diskon) * -1,
            totalPembayaran: props.totalTagihan * -1,
            uangYangDibayar: 0,
            kembalian: 0,
            customer: props.customer.id ? props.customer.id : undefined,
            isReverted: 1,
            invoiceId: props.invoiceId,
            metodeId: props.metodeId ? props.metodeId : 0,
            ongkir: props.ongkir ? Number(props.ongkir) * -1 : undefined,
            ketOngkir: props.ketOngkir ? props.ketOngkir : undefined,
            customAmount: props.customAmount
                ? Number(props.customAmount) * -1
                : undefined,
            ketCustomAmount: props.ketCustomAmount
                ? props.ketCustomAmount
                : undefined,
        } as InputPembayaranBody;

        try {
            const { data } = await axios.post<InputPembayaranResponse>(
                "/api/pembayaran",
                body,
            );
            if (data.code === 200) {
                props.refetchLaporan();
                props.refetchListInvoice();
                props.refetchProdukTerjual();
                props.refetchMutasiStok();
                props.refetchGrafikPenjualan();
                refetchItem();
                props.closeModalRevertInvoice();
                Swal.fire({
                    title: "Pembatalan berhasil!",
                    text: "Pembayaran telah berhasil dibatalkan",
                    icon: "success",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });

                setFetchingItems();
                handleReset();
            }
            setIsButtonLoading(false);
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
        }
    };

    const handleErrorResponse = useCallback(
        (error) => {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<any>;
                if (serverError && serverError.response) {
                    props.closeModalRevertInvoice();
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.data}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                } else {
                    props.closeModalRevertInvoice();
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `Telah Terjadi Kesalahan`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            }
        },
        [props],
    );

    useEffect(() => {
        if (ukmIdUser) {
            setParamsItem((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        if (isPrintStruk) {
            inputPembayaran();
            resetPrint();
        }
    }, [inputPembayaran, isPrintStruk, resetPrint]);

    // if (status === "loading") {
    //     return (
    //         <Box position="relative">
    //             <Spinner />
    //         </Box>
    //     );
    // }

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            fullScreen={fullScreen}
            open={
                props.isOpenModalRevertInvoice && props.statusItem === "success"
            }
            onClose={props.closeModalRevertInvoice}
            scroll="paper"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={props.closeModalRevertInvoice}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Batalkan Pembayaran
                    </Typography>
                </Stack>
            </DialogTitle>
            <Stack
                paddingLeft={3}
                paddingRight={4}
                paddingY={2}
                borderBottom="1px solid rgba(0, 0, 0, 0.12)"
                spacing={2}
            >
                <Grid container>
                    <Grid item xs={5} sm={3} md={3}>
                        <Typography variant="subtitle2" color="#A1A1AA">
                            Tanggal
                        </Typography>
                        <Typography variant="subtitle2" color="black">
                            {now}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" color="#A1A1AA">
                            Nama Kasir
                        </Typography>
                        <Typography variant="subtitle2" color="black">
                            {user?.namaLengkap}
                        </Typography>
                    </Grid>
                </Grid>
            </Stack>
            <DialogContent sx={{ maxHeight: "40vh", paddingBottom: 2 }}>
                <Stack direction="column" spacing={1}>
                    {props.item ? (
                        props.item.map((rows, idx) => (
                            <Box key={String(idx)}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                >
                                    <Box width="50%">
                                        <Box>
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight={600}
                                                color="black"
                                            >
                                                {rows.namaItem}
                                            </Typography>
                                            <Typography>
                                                Rp. {toRibuan(rows.hargaSatuan)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex">
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={600}
                                            color="black"
                                        >
                                            {rows.jumlahItem -
                                                rows.jumlahItem * 2}
                                            x
                                        </Typography>
                                    </Box>
                                    <Stack
                                        direction="row"
                                        width="30%"
                                        display="flex"
                                        justifyContent="flex-end"
                                    >
                                        <Typography
                                            display="flex"
                                            variant="subtitle1"
                                            fontWeight={600}
                                            color="black"
                                            textAlign="end"
                                        >
                                            {toRupiahDesimal(
                                                rows.hargaSatuan *
                                                    (rows.jumlahItem -
                                                        rows.jumlahItem * 2),
                                            )}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                        ))
                    ) : (
                        <Typography variant="h6">
                            Barang tidak ditemukan
                        </Typography>
                    )}
                    {props.customAmount && (
                        <Box>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Box width="50%">
                                    <Box>
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={600}
                                            color="black"
                                        >
                                            Custom Amount
                                        </Typography>
                                        <Typography>
                                            {props.ketCustomAmount}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Stack
                                    direction="row"
                                    width="30%"
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <Typography
                                        display="flex"
                                        variant="subtitle1"
                                        fontWeight={600}
                                        color="black"
                                        textAlign="end"
                                    >
                                        {toRupiahDesimal(
                                            Number(props.customAmount) * -1,
                                        )}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    )}
                    {props.ongkir && (
                        <Box>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Box width="50%">
                                    <Box>
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={600}
                                            color="black"
                                        >
                                            Ongkos Kirim
                                        </Typography>
                                        <Typography>
                                            {props.ketOngkir}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Stack
                                    direction="row"
                                    width="30%"
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <Typography
                                        display="flex"
                                        variant="subtitle1"
                                        fontWeight={600}
                                        color="black"
                                        textAlign="end"
                                    >
                                        {toRupiahDesimal(
                                            Number(props.ongkir) * -1,
                                        )}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    )}
                </Stack>
                <Box
                    marginTop={2}
                    padding={2}
                    border={1}
                    borderColor="#d4d4d4"
                    borderRadius={2}
                >
                    <Typography
                        marginBottom={1}
                        variant="subtitle1"
                        color="#000000"
                        fontWeight="bold"
                    >
                        Ringkasan Pembayaran
                    </Typography>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <Typography variant="subtitle1" color="#404040">
                                Subtotal Harga
                            </Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                paddingX={1}
                            >
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                >
                                    Rp.
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                >
                                    {toRibuanDesimal(
                                        String(props.totalHarga * -1),
                                    )}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    {props.customAmount && (
                        <Grid
                            container
                            justifyContent="space-between"
                            marginTop={1}
                        >
                            <Grid item xs={6} sm={3} md={4} lg={4}>
                                <Typography variant="subtitle1" color="#404040">
                                    Custom Amount
                                </Typography>
                            </Grid>
                            <Grid item lg={3} xs={5}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    paddingX={1}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        Rp.
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        {toRibuanDesimal(
                                            String(
                                                Number(props.customAmount) * -1,
                                            ),
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                    {props.ongkir && (
                        <Grid
                            container
                            justifyContent="space-between"
                            marginTop={1}
                        >
                            <Grid item xs={6} sm={3} md={4} lg={4}>
                                <Typography variant="subtitle1" color="#404040">
                                    Ongkos Kirim
                                </Typography>
                            </Grid>
                            <Grid item lg={3} xs={5}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    paddingX={1}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        Rp.
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        {toRibuanDesimal(
                                            String(Number(props.ongkir) * -1),
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                    <Grid
                        container
                        marginTop={1}
                        justifyContent="space-between"
                    >
                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <Typography variant="subtitle1" color="#404040">
                                Diskon
                            </Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                bgcolor="#FFF5EC"
                                paddingX={1}
                            >
                                <Typography
                                    variant="subtitle1"
                                    color="#FB923C"
                                    fontWeight="bold"
                                >
                                    Rp.
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="#FB923C"
                                    fontWeight="bold"
                                >
                                    {toRibuanDesimal(
                                        String(Number(props.diskon) * -1 ?? 0),
                                    )}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                        marginTop={1}
                        justifyContent="space-between"
                    >
                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <Typography variant="subtitle1" color="#404040">
                                Total Tagihan
                            </Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                bgcolor="#E8F6ED"
                                paddingX={1}
                            >
                                <Typography
                                    variant="subtitle1"
                                    color="#45A779"
                                    fontWeight="bold"
                                >
                                    Rp.
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="#45A779"
                                    fontWeight="bold"
                                >
                                    {toRibuan(props.totalTagihan * -1)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <Grid
                container
                justifyContent="flex-end"
                sx={{ borderTop: "1px solid #d4d4d4" }}
            >
                <Grid item xs={12}>
                    <Box marginTop={3} marginBottom={4} marginX={3}>
                        <Stack
                            direction="column"
                            display="flex"
                            justifyContent="space-between"
                            marginBottom={1}
                            spacing={1}
                        >
                            <Stack
                                direction="row"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                >
                                    Total
                                </Typography>
                                <Box
                                    bgcolor="#E8F6ED"
                                    borderRadius={1}
                                    paddingX={2}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        color="#45A779"
                                    >
                                        Rp. {toRibuan(props.totalTagihan * -1)}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Stack>
                        <LoadingButton
                            loading={isButtonLoading}
                            loadingPosition="start"
                            startIcon={<DeleteIcon />}
                            variant="contained"
                            fullWidth
                            size="large"
                            color="error"
                            onClick={() => {
                                inputPembayaran();
                                handlingDonePembayaran();
                            }}
                        >
                            Batalkan Pembayaran
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ModalRevertInvoice;
